import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import service from "../../../../../utils/service";
import _ from "lodash"
import { Nav } from 'reactstrap';
import { NavLink } from "react-router-dom";
import allPaths from "../../../../../route/allPaths";
import WatchlistButton from "../../../../Party/common/WatchlistButton";
import Loader from "react-loaders";

const RecommandedPage = (props) => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiCall()
  }, [])

  const apiCall = async () => {
    let URL = "/v3/recommendations/parties"
    let res = await service("GET", URL);
    if (res) {
      setLoading(false)
      setdata(_.get(res, "data", []))
    }
  }

  return (
    <div >
      <div className="fs-18 fw-600 mb-2 fb fc-blue">Recommended for you</div>
      {
        loading ?
          <div className="text-center m-2">
            <Loader type="line-scale" />
          </div>
          :
          (_.isArray(data) && data.length > 0 ? (data.map((item, key) => {
            return (
              <ListGroup flush key={key}>
                <ListGroupItem>
                  <div className="d-flex justify-content-between">
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <Nav>
                            <NavLink to={`${allPaths.PARTY_ROOT_ROUTE}/${item.party_id}`}>
                              {item.party_name}
                            </NavLink>
                          </Nav>
                          <div className="widget-subheading fs-14 fw-500">
                            {_.capitalize(item.model_type).split("_").join(" ")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <WatchlistButton
                        party_id={item.party_id}
                        partyname={item.party_name}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            );
          })) : (<div>No data found</div>))
      }
    </div>
  );
}



export default RecommandedPage;
