import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import _ from 'lodash'
import { bindActionCreators } from "redux";

import allPaths from '../../../../../route/allPaths';
import { setSideBarItems, setFabItem } from '../../../../../reducers/ThemeOptions';
import { getRedirectURL, removeRedirectURL } from '../../../../../utils/getToken';
import SearchForm from '../../../../../components/search_form';

const SearchBar = (props) => {

  useEffect(() => {
    setDefaultFabAndSideBar()
  }, [])

  const setDefaultFabAndSideBar = () => {
    const { setFabItem, setSideBarItems } = props;
    setFabItem(false);
    setSideBarItems([]);
    let url = getRedirectURL();
    if (url) {
      removeRedirectURL();
      pushURL(url);
    }
  };

  const pushURL = (url) => {
    const { history } = props;
    history && history.push(url);
  };


  const selectOption = (party_id) => {
    if (party_id) {
      let URL = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}`;
      pushURL(URL);
    }
  };

  const submitInput = (searchText) => {
    if (searchText && _.isString(searchText) && searchText.length >= 3) {
      let URL = `${allPaths.SEARCH_ROUTE}?q=${searchText}`;
      pushURL(URL);
    }
  };

  return (
    <div>
      <div className="fs-18 fw-600 fc-blue mb-2">Type below to search for a business</div>
      <SearchForm
        searchBoxHeight={64}
        selectOption={selectOption}
        submitInput={submitInput}
      />
    </div>

  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSideBarItems,
      setFabItem,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(SearchBar))
