import React, { useEffect, useState } from "react";
import { FormGroup, Button } from "reactstrap";
import Select from "react-select-new";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userApi } from '../../../../../actions/myprofile/action';
import { Mixpanel } from "../../../../../utils/mixpanel";
import service from "../../../../../utils/service";

const IndustryDropBoxNew = (props) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  let user_id = _.get(props, "profileData.user_id", "");
  let industries = _.get(props, "profileData.industries", []);

  useEffect(() => {
    getIndustries();
  }, []);

  const getIndustries = async () => {
    let res = await service("get", "/v1/admin/industries");
    let data = _.get(res, "data");
    if (data && _.isArray(data)) {
      let arr = data.map((a) => {
        return { label: a.name, value: a.name, industry_id: a.industry_id };
      });
      setOptions(arr);
    }
  };

  useEffect(() => {
    if (options && _.isArray(options) && options.length > 0 && industries) {
      getUserData();
    }
  }, [options, industries]);

  const getUserData = async () => {
    let set_arr = [];
    if (options && _.isArray(options)) {
      options.forEach((a) => {
        if (industries.includes(a.industry_id)) {
          set_arr.push(a);
        }
      });
    }
    setSelectedOption(set_arr);
  };

  const handleChange = (value) => {
    let ids = [];
    let industriesList = [];

    if (value && _.isArray(value)) {
      value.forEach((a) => {
        ids.push(a.industry_id);
        industriesList.push(a.label);
      });
    }
    saveIndustriesAPI(ids, industriesList);
  };

  const saveIndustriesAPI = async (ids, industriesList) => {
    let data = {
      user: {
        industries: ids,
      },
    };
    let res = await service("patch", `/v1/admin/users/${user_id}`, { data });
    if (_.get(res, "success")) {
      Mixpanel.track("industries_of_interest", {
        industry_list: industriesList,
      });
      props.userApi(user_id)
    }
  };

  const clearAll = () => {
    handleChange([])
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="fs-14 fc-blue mb-3">
          <div>
            <div>
              <div className="fs-18 fw-600 fc-blue">
                Select Interested Industries
              </div>
            </div>
            <div className="fs-12 fw-400 fc-black ">
              Discover new opportunities with our AI powered matching.
            </div>
          </div>
        </div>
        {selectedOption && selectedOption.length > 0 && (
          <Button className="mb-3  fs-14" color="link">
            Count
            <span className="badge text-bg-pill text-bg-primary">
              {selectedOption.length}
            </span>
          </Button>
        )}
      </div>
      <FormGroup>
        <Select
          closeMenuOnSelect={false}
          isMulti options={options}
          onChange={handleChange}
          value={selectedOption}
          onClearIndicatorMouseDown={clearAll}
        />
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileData: _.get(state, "my_profile.profileData"),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userApi
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryDropBoxNew);
