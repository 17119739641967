import React, { useEffect, useState } from "react";
import _ from "lodash";
import { withRouter, useLocation } from "react-router-dom";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";

import allPaths from "../../../../../route/allPaths";
import service from "../../../../../utils/service";
import CreateCase from "../../../../../widgets/create-case";

const RecentCases = (props) => {
  const { history } = props;

  const [info, setInfo] = useState({ status: "not-triggered", result: [] });
  const [case_count, setCase_count] = useState("");
  const organization_id = _.get(props, "my_org.organization_id");

  useEffect(() => {
    if (organization_id) {
      trigger_Cases_API();
    }
  }, [organization_id]);

  const trigger_Cases_API = async () => {
    let API_URL = "v1/cases";
    let params = {
      organization_id: organization_id,
      per_page: 3,
    };
    let res = await service("get", API_URL);
    setCase_count(_.get(res, "count", ""));
    let cases = _.get(res, "cases", []);
    if (_.isArray(cases)) {
      setInfo({
        status: "done",
        result: cases,
      });
    } else {
      setInfo({
        status: "done",
        result: [],
      });
    }
  };

  const get_header_ui = () => {
    let arr = ["Business Name", "Alert Summary", "Created", ""];
    return (
      <thead>
        <tr>
          {arr.map((a, index) => (
            <th key={index} className="text-bold">
              {a}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const navigatePortfolios = (id) => {
    history.push(`${allPaths.PARTY_ROOT_ROUTE}/${id}`);
  };

  const navigate_to_CMM = (id) => {
    history.push(`${allPaths.CMM_ROUTE}/${id}`);
  };

  const navigate_to_Cases = () => {
    history.push(`${allPaths.CMM_ROUTE}`);
  };

  const get_cmm_button_ui = (data) => {
    if (data.uid) {
      return (
        <button
          className="btn btn-primary"
          onClick={() => navigate_to_CMM(data.uid)}
          style={{ width: "120px" }}
        >
          <i className="fa fa-eye me-2" />
          View Case
        </button>
      );
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getOffcancas = (id) => {
    queryParams.set("offCanvas", id);
    history.push(`${location.pathname}?${queryParams.toString()}`);
  };
  const get_body_ui = () => {
    let data = _.get(info, "result", []);
    let ui_arr = [];
    if (_.isArray(data)) {
      data.forEach((item, index) => {
        let party_id = _.get(item, "party_id");
        if (index < 3) {
          ui_arr.push(
            <tr key={index}>
              <td className="td-width">
                {party_id ? (
                  <a
                    href="#"
                    className="cursor-p"
                    onClick={() => navigatePortfolios(_.get(item, "party_id"))}
                  >
                    {item.partyName}
                  </a>
                ) : (
                  <div>{item.partyName}</div>
                )}
              </td>
              <td className="td-width">{item.subject}</td>
              <td>{moment(item.created_at).format("DD MMM YYYY")}</td>
              <td>{get_cmm_button_ui(item)}</td>
              {item.alert ? (
                <td>
                  <button
                    className="btn btn-primary"
                    style={{ width: "120px" }}
                    onClick={() => getOffcancas(item.alert_id)}
                  >
                    <i className="fa fa-eye me-2" />
                    View Alert
                  </button>
                </td>
              ) : (
                ""
              )}
            </tr>
          );
        }
      });
      if (data.length === 0) {
        ui_arr.push(
          <tr>
            <td colSpan={10}>
              <div className="d-flex align-items-center justify-content-center flex-column">
                <div className="fs-12 text-center">
                  Looks like you don't have any cases.
                  <br />
                  Start by creating one now.
                </div>
                <div className="mt-2">
                  <CreateCase cb={trigger_Cases_API} />
                </div>
              </div>
            </td>
          </tr>
        );
      }
    }
    return <tbody>{ui_arr}</tbody>;
  };

  const getTableUI = () => {
    let data = _.get(info, "result", []);
    let is_data_available = _.isArray(data) && data.length > 0 ? true : false;
    return (
      <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
        {is_data_available && get_header_ui()}
        {get_body_ui()}
      </table>
    );
  };

  let is_done = _.get(info, "status") === "done" ? true : false;

  return (
    <div className="card h-100 mb-3">
      <div className="card-header">Most Recent Cases</div>
      <div className="card-body">
        {is_done ? (
          <div className="table-reponsive overflow-auto">{getTableUI()}</div>
        ) : (
          <Skeleton count={3} />
        )}
      </div>
      <div className="card-footer p-1 pr-2 d-flex justify-content-end">
        <button className="btn btn-link p-0 fs-10" onClick={navigate_to_Cases}>
          View All: {case_count}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  my_org: _.get(state, "my_profile.my_org"),
});

export default connect(mapStateToProps)(withRouter(RecentCases));
