import React, {  useEffect } from "react";
import allPaths from "../../../../../route/allPaths";
import { NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getWatchlistParties } from "../../../../../actions/dashboard/action";
import _ from "lodash";
import { bindActionCreators } from "redux";
import WatchListblur from "./WatchListblur";
import WatchlistButton from "../../../../Party/common/WatchlistButton";

function Watchlists(props) {
  let history = useHistory();

  useEffect(() => {
    callApi();
  },[]);

  const callApi = () => {
    const { list_id, getWatchlistParties } = props;
    let params = {
      page: 1,
      per_page: 6,
    };
    if (list_id) {
      getWatchlistParties(list_id, params);
    }
  };

  const watchlistData=[
    {
      company: "Crediwatch Information Analytics Private Limited",
      users: "120",
    },
    {
      company: "Reliance Industries Limited",
      users: "95",
    },
    {
      company: "Infosys Limited",
      users: "89",
    },
    {
      company: "Reliance petroleum",
      users: "95",
    },
    {
      company: "Castrol India",
      users: "89",
    },
  ]

  const handlePush = () => {
    history.push(allPaths.WATCHLIST);
  };

  return (
    <div >
      <div className="d-flex mb-2 justify-content-between flex-md-column flex-xl-row">
        <div className="d-flex">
          <div className="icon-22 me-2 ">
            <img
              src={`/cdn/assets/icons/product/watchlist_icon.svg`}
              alt=""
            />
          </div>
          <div>
            <div>
              <div className="fs-18 fw-600 fc-blue">Watchlists</div>
            </div>
            <div className="fs-12 fw-400 fc-black ">
              Access your list of watchlisted companies
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end justify-content-md-center justify-content-xl-end">
          <div>
            <button className="cw-btn cw-btn-link" onClick={handlePush}>
              VIEW MORE
            </button>
          </div>
        </div>
      </div>
      {_.isEmpty(props.data) ? (
        <WatchListblur>
          <div className="row p-2">
            {watchlistData.map((item, key) => {
              return (
                <div className="col-md-12 p-2">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading fs-12">
                          {item.company}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </WatchListblur>
      ) : (
        <div className="row p-2">
          {_.isArray(props.data) &&
            props.data.map((item, key) => {
              return (
                <div className="col-md-12 p-2">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading fs-16">
                          <NavLink
                            to={`${allPaths.PARTY_ROOT_ROUTE}/${item.party_id}`}
                          >
                            {item.name}
                          </NavLink>
                        </div>
                      </div>
                      <div className="widget-content-right">
                        <WatchlistButton
                          party_id={item.party_id}
                          partyname={item.name}
                          cb={callApi}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    list_id: _.get(state, "my_profile.my_watclist.result.list_id"),
    data: _.get(state, "dashboard.watchlist_parties.result.data"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getWatchlistParties,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlists);
