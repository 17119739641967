import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import SanitizedHTML from "react-sanitized-html";
import _ from "lodash";
import allPaths from "../../../route/allPaths";
import Activity from "../../../pages/Case-Management/case-details/Activity";
import service from "../../../utils/service";
import Skeleton from "react-loading-skeleton";

const ActivityList = React.memo(({ CommentsData }) => {
  return (
    <div className="height-300 border-top">
      {_.isArray(CommentsData) &&
        CommentsData.slice().map((item) => (
            <div className="mt-3" key={item.id}>
              <Activity
                name={item?.owner?.fullname || "No Name"}
                date={
                  item?.date
                    ? moment
                        .utc(item.date, "YYYY-MM-DD HH:mm:ss.SSSSSS")
                        .tz("Asia/Kolkata")
                        .format("DD MMM YYYY, hh:mm a")
                    : "---"
                }
                description={item?.description || ""}
              />
            </div>
          ))}
    </div>
  );
});

const OffCanvasViewCase = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading
  const [caseDetails, setcaseDetails] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const offCanvas = queryParams.get("offCanvas");
  const case_id = queryParams.get("case_id");

  const handleCloseOffcanvas = () => {
    queryParams.delete("offCanvas");
    queryParams.delete("case_id");
    history.push({ search: queryParams.toString() });
  };

  useEffect(() => {
    if (case_id) {
      call_case_details_API();
    }
  }, [case_id]);

  const call_case_details_API = async () => {
    setLoading(true); // Start loading
    try {
      const API_URL = `v1/cases/${case_id}`;
      const res = await service("get", API_URL);
      const data = _.get(res, "ticket");
      if (data) {
        setcaseDetails([data]);
      }
    } catch (error) {
      console.error("Error fetching case details:", error);
      // Handle error appropriately, e.g., showing an error message to the user
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    setShow(!!offCanvas);
  }, [offCanvas]);

  const navigatePartyCases = (id) => {
    history.push(`${allPaths.PARTY_ROOT_ROUTE}/${id}/cases`);
  };

  const navigateToCaseDetails = (id) => {
    history.push(`${allPaths.CMM_ROUTE}/${id}`);
  };

  return (
    <>
      {show && (
        <div
          className={`offcanvas offcanvas-end show`}
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header canvas-header-bg">
            <h5 className="offcanvas-title" id="offcanvasRightLabel">
              <span className="text-primary">
                 Case: {case_id}
                <i
                  className="fa fa-external-link ms-2 text-primary cursor-p"
                  onClick={() => navigateToCaseDetails(case_id)}
                ></i>
              </span>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleCloseOffcanvas}
            ></button>
          </div>

          <div className="offcanvas-body">
            {loading ? (
              <Skeleton count={5} />
            ) : (
              caseDetails &&
              caseDetails.map((item) => {
                const case_id = _.get(item, "case_id");
                const subject = _.get(item, "subject");
                const partyName = _.get(item, "partyName");
                const party_id = _.get(item, "party_id");
                const fullname = _.get(item, "case_assignee", "NA");
                const case_type = _.get(item, "case_type");
                const status = _.get(item, "case_status");
                const created_at = _.get(item, "created_at", "---");
                const history = _.get(item, "history");
                const case_summary = _.get(item, "case_summary");

                return (
                  <div className="mobile-only-scroll" key={case_id}>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Company</div>
                        <div className="text-primary fs-16">
                          {partyName}
                          <i
                            className="fa fa-external-link ms-2 text-primary cursor-p"
                            onClick={() => navigatePartyCases(party_id)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Subject</div>
                        <div className="text-muted fs-16">{subject}</div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Description</div>
                        <div className="text-muted fs-16">
                          <SanitizedHTML html={case_summary} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Case Status</div>
                        <div className="text-muted fs-16">{status}</div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Case Assignee</div>
                        <div className="text-muted fs-16">{fullname}</div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Case Type</div>
                        <div className="text-muted fs-16"> {case_type}</div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Creation Date</div>
                        <div className="text-muted fs-16">
                          {moment
                            .utc(created_at, "YYYY-MM-DD HH:mm:ss.SSSSSS")
                            .tz("Asia/Kolkata")
                            .format("DD MMM YYYY, hh:mm a")}
                        </div>
                      </div>
                    </div>
                    {history.length > 0 && (
                      <div className="">
                        <div className="p-2">
                          <div className="fw-semibold fs-18">History</div>

                          {history && <ActivityList CommentsData={history} />}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OffCanvasViewCase;
