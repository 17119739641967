import React from 'react'
import { useHistory } from "react-router-dom"
import allPaths from '../../route/allPaths';
import './myAccount.css'

const MyAccount = () => {
  const history = useHistory();

  const navigateToAccountDetails = () => {
    history.push(allPaths.MY_ACCOUNT);
  }

  return (
    <button
    className='mt-2 d-flex align-items-center myaccount-button'
    onClick={navigateToAccountDetails}
  >
    <span className="myaccount-button-name">
      My Account
    </span>
  </button>
  
    
  )
}

export default MyAccount