import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import allPaths from '../../route/allPaths';
import { setToken } from '../../utils/getToken';

const AmplifyLogin = (props) => {
  let history = _.get(props, "history");
  const queryParams = new URLSearchParams(_.get(history, "location.search"));
  let token = queryParams.get('token');
  let business_profile = queryParams.get("business_profile");

  useEffect(() => {
    if(token && token !== '') {
      setToken(`Bearer ${token}`);
      history.push(allPaths.HOME_ROUTE)
    }
  }, [token]);

  useEffect(() => {
    if(business_profile) {
      localStorage.setItem('business_profile', business_profile)
    }
  }, [business_profile])
  return (
    <div>Waiting for amplify</div>
  )
}

export default withRouter(AmplifyLogin)