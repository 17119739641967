import React from "react";
import _ from "lodash";
import SanitizedHTML from "react-sanitized-html";

class NewsMediaAlert extends React.Component {
  getAlertUI = () => {
    const { details } = this.props;
    let UICom = "";
    if (details) {
      if (details.length === 1) {
        let detail = details[0];
        UICom = (
          <div className="card">
            <div className="card-body">
              <h5>
                <a href={detail.url ? detail.url : "---"} target="_blank">
                  {detail.title ? detail.title : "---"}
                </a>
              </h5>
              {detail.text &&<p>{detail.text}</p>}
              <p className="mb-0">
                {detail.fragment && detail.fragment.length > 0
                  ? _.map(detail.fragment, (d, k) => {
                      return <SanitizedHTML html={d} />;
                    })
                  : "---"}
              </p>
            </div>
          </div>
        );
      }
    }
    return UICom;
  };

  render() {
    return <>{this.getAlertUI()}</>;
  }
}

export default NewsMediaAlert;
