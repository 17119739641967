import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "lodash";
import service from "../../../utils/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Mixpanel } from "../../../utils/mixpanel";

class WatchlistButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addtoWatchlist: "",
      isWatchlisted: {
        status: "not-triggered",
        result: false,
      },
    };
  }

  componentDidMount() {
    this.isWatchlistedAPI();
  }

  isWatchlistedAPI = () => {
    const { party_id } = this.props;
    this.setState(
      {
        isWatchlisted: {
          status: "loading",
          result: false,
        },
      },
      async () => {
        let res = await service(
          "get",
          `/v1/admin/parties/${party_id}/is_watchlisted`
        );
        this.setState({
          isWatchlisted: {
            status: "done",
            result: _.get(res, "meta.total_records", 0) > 0 ? true : false,
          },
        });
      }
    );
  };

  addRemoveWatchlist = (action) => {
    const { cb, list_id, party_id, partyname } = this.props;
    let data = {
      list: {
        action: action,
        list_type: "watchlist",
        parties: [
          {
            party_id: party_id,
            name: partyname,
          },
        ],
      },
    };
    this.setState(
      {
        isWatchlisted: {
          status: "loading",
          result: false,
        },
      },
      async () => {
        let res = await service("patch", `/v1/admin/lists/${list_id}`, {
          data: data,
        });
        if (res) {
          if (action === "add") {
            if(partyname){
              Mixpanel.track("add_to_watchlist", {
                party_name: partyname
              });
            }
            toast.success("Successfully added to your Watchlist");
          } else {
            if(partyname){
              Mixpanel.track("remove_from_watchlist", {
                party_name: partyname
              });
            }
            toast.error("Party removed from your Watchlist");
          }
          this.isWatchlistedAPI();
          cb && cb();
        }
      }
    );
  };

  getIconUI = (disbleIconFunc) => {
    const { isWatchlisted } = this.state;
    return (
      <>
        {isWatchlisted.status === "done" && isWatchlisted.result ? (
          <div
            onClick={() => !disbleIconFunc && !this.props.isButtonBased && this.addRemoveWatchlist("remove")}
          >
            <img
              src="https://cdn.crediwatch.com/assets/icons/product/un_watchlist.svg"
              alt=""
              width={this.props.isButtonBased && "14px"}
            />
          </div>
        ) : (
          <div
            onClick={() => !disbleIconFunc && !this.props.isButtonBased && this.addRemoveWatchlist("add")}
            className=" "
          >
            <img
              src="https://cdn.crediwatch.com/assets/icons/product/watchlist_icon.svg"
              alt=""
              width={this.props.isButtonBased && "14px"}
            />
          </div>
        )}
      </>
    );
  };

  buttonClick = () => {
    const { isWatchlisted } = this.state;

    if (isWatchlisted.status === "done") {
      if (isWatchlisted.result) {
        this.addRemoveWatchlist("remove");
      } else {
        this.addRemoveWatchlist("add");
      }
    }
  };

  render() {
    return (
        <div className="cursor-pointer">
          {this.props.isButtonBased ? (
            <button
              className="btn-transition btn btn-outline-primary d-flex align-items-center "
              onClick={() => this.buttonClick()}
              disabled={_.get(this.state, "isWatchlisted.status") !== "done"}
            >
              {_.get(this.state, "isWatchlisted.status") !== "done" ? (
                <span>Loading...</span>
              ) : (
                <>
                  <span className="me-2 fs-14 d-md-inline d-none">Watchlist</span>
                  <>{this.getIconUI()}</>
                </>
              )}
            </button>
          ) : (
            this.getIconUI()
          )}
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    list_id: _.get(state.my_profile.my_watclist, "result.list_id"),
  };
}

export default connect(mapStateToProps, null)(WatchlistButton);

