import React from "react";
import _ from "lodash";
import moment from "moment";
import {
  KeyPairAlert,
  NotConfigured,
  MediaAlert,
  SignificantMovement,
  CaseFiledAlert,
  ChargeAlert,
  FinancialStatementAlert,
  FinancialStatementTrend,
  OtherFinesAlert,
  ExternalRatingDowngrade,
  WeekZscore,
  PoliticalExposure,
  MultiDetailTable,
  StockAudit,
  SingleKeyTable,
  BranchTooFarAlert,
  NewsMediaAlert,
  AlertTable,
} from "./Alert";
import EpfoAlert from "./Alert/EpfoAlert";
import HelpButtonUI from "./HelpButtonUI";
import DefaultAlertDetails from "./Alert/defaultAlertDetails";
import FormatTwo from "./NewAlertTables/formatTwo";
import FormatThree from "./NewAlertTables/formatThree";
import FormatSeven from "./NewAlertTables/formatSeven";
import FormatSix from "./NewAlertTables/formatSix";

const AlertDetails = ({ alert }) => {
  const objectCodeSignalLoop = (codes) => {
    let signalArr = [
      10120, 10143, 10146, 10150, 10151, 10152, 10154, 10155, 10157, 10159,
      10161, 10166, 10168, 10169, 10170, 10172, 10174, 10176, 10210, 10226,
      10127, 10230, 10206, 10132, 10434, 10311, 10433, 20022, 10425, 10232,
      10128, 10194, 10195, 10229, 10227, 10502, 10512,10221,10501,10193,10181
    ];
    let checkBool = false;
    for (let i = 0; i < codes.length; ) {
      if (signalArr.includes(codes[i])) {
        checkBool = true;
        i = codes.length;
      } else {
        i++;
      }
    }
    return checkBool;
  };

  const getAlertComponentUI = (alert) => {
    let {
      alert_detail,
      alert_signal,
      alert_summary,
      alert_category,
      signal_code,
    } = alert;

    const signal_codes = [parseInt(signal_code, 10)];

    let alertText = alert_signal;
    if (_.indexOf(signal_codes, 20113) > -1) {
      let keyPairs = [
        {
          key: "account_number",
        },
        {
          key: "currency_amount",
          type: "money",
          rightAlign: true,
        },
        {
          key: "tran_date",
        },
        {
          key: "transaction_description",
        },
      ];

      return (
        <SingleKeyTable
          details={alert_detail}
          text={alertText}
          keyPairs={keyPairs}
        />
      );
    } else if (
      alert_category.toLocaleLowerCase() === "private" ||
      objectCodeSignalLoop(signal_codes) ||
      alert.is_custom
    ) {
      let keyPairs = [];
      if (alert_detail) {
        if (!_.isArray(alert_detail) && typeof alert_detail === "object") {
          alert_detail = [alert_detail];
        }
        let objectKey = [];
        if (_.isArray(alert_detail) && alert_detail[0]) {
          objectKey = Object.keys(alert_detail[0]);

          objectKey.forEach((item) => {
            if (typeof alert_detail[0][item] !== "object") {
              keyPairs.push({ key: item });
            }
          });
        }
      }

      return (
        <SingleKeyTable
          details={alert_detail}
          text={alertText}
          keyPairs={keyPairs}
        />
      );
    } else {
      if (
        _.isArray(signal_codes) &&
        signal_codes.length > 0 &&
        _.indexOf(signal_codes, 0) < 0
      ) {
        if (_.indexOf(signal_codes, 10118) > -1) {
          return <KeyPairAlert details={alert_detail} text={10118} />;
        } else if (_.indexOf(signal_codes, 10167) > -1) {
          return <KeyPairAlert details={alert_detail} text={10167} />;
        } else if (_.indexOf(signal_codes, 10139) > -1) {
          return <PoliticalExposure details={alert_detail} text={10139} />;
        } else if (_.indexOf(signal_codes, 10117) > -1) {
          return <KeyPairAlert details={alert_detail} text={10117} />;
        } else if (_.indexOf(signal_codes, 10119) > -1) {
          return <KeyPairAlert details={alert_detail} text={10119} />;
        } else if (_.indexOf(signal_codes, 10222) > -1) {
          return <KeyPairAlert details={alert_detail} text={10222} />;
        } else if (_.indexOf(signal_codes, 10197) > -1) {
          return <KeyPairAlert details={alert_detail} text={10197} />;
        } else if (_.indexOf(signal_codes, 10137) > -1) {
          return <KeyPairAlert details={alert_detail} text={10137} />;
        } else if (_.indexOf(signal_codes, 10193) > -1) {
          return (
            <FinancialStatementAlert details={alert_detail} text={"dinance"} />
          );
        } else if (_.indexOf(signal_codes, 20045) > -1) {
          return <KeyPairAlert details={alert_detail} text={20045} />;
        } else if (_.indexOf(signal_codes, 222225) > -1) {
          return <KeyPairAlert details={alert_detail} text={222225} />;
        } else if (_.indexOf(signal_codes, 10102) > -1) {
          return <CaseFiledAlert details={alert_detail} text={10102} />;
        } else if (
          _.indexOf(signal_codes, 10123) > -1 ||
          _.indexOf(signal_codes, 10124) > -1 ||
          _.indexOf(signal_codes, 10133) > -1 ||
          _.indexOf(signal_codes, 10200) > -1
        ) {
          return <CaseFiledAlert details={alert_detail} text={10123} />;
        } else if (_.indexOf(signal_codes, 10142) > -1) {
          return <CaseFiledAlert details={alert_detail} text={10142} />;
        } else if (_.indexOf(signal_codes, 10173) > -1) {
          return <KeyPairAlert details={alert_detail} text={10173} />;
        } else if (_.indexOf(signal_codes, 10113) > -1) {
          return <KeyPairAlert details={alert_detail} text={10113} />;
        } else if (_.indexOf(signal_codes, 10162) > -1) {
          return <KeyPairAlert details={alert_detail} text={10162} />;
        } else if (_.indexOf(signal_codes, 10185) > -1) {
          return <KeyPairAlert details={alert_detail} text={10185} />;
        } else if (_.indexOf(signal_codes, 10106) > -1) {
          return <KeyPairAlert details={alert_detail} text={10106} />;
        } else if (_.indexOf(signal_codes, 10178) > -1) {
          return <KeyPairAlert details={alert_detail} text={10178} />;
        } else if (_.indexOf(signal_codes, 10179) > -1) {
          return <KeyPairAlert details={alert_detail} text={10179} />;
        } else if (_.indexOf(signal_codes, 10198) > -1) {
          return <KeyPairAlert details={alert_detail} text={10198} />;
        } else if (_.indexOf(signal_codes, 10175) > -1) {
          return <KeyPairAlert details={alert_detail} text={10175} />;
        } else if (_.indexOf(signal_codes, 10145) > -1) {
          return <KeyPairAlert details={alert_detail} text={10145} />;
        } else if (_.indexOf(signal_codes, 10107) > -1) {
          return <KeyPairAlert details={alert_detail} text={10107} />;
        } else if (_.indexOf(signal_codes, 10187) > -1) {
          return <KeyPairAlert details={alert_detail} text={10187} />;
        } else if (_.indexOf(signal_codes, 10503) > -1) {
          return <KeyPairAlert details={alert_detail} text={10503} />;
        } else if (_.indexOf(signal_codes, 10136) > -1) {
          return <KeyPairAlert details={alert_detail} text={10136} />;
        } else if (_.indexOf(signal_codes, 10110) > -1) {
          return <KeyPairAlert details={alert_detail} text={10110} />;
        } else if (_.indexOf(signal_codes, 10208) > -1) {
          return <MultiDetailTable details={alert_detail} text={10208} />;
        } else if (_.indexOf(signal_codes, 10189) > -1) {
          return <MultiDetailTable details={alert_detail} text={10189} />;
        } else if (_.indexOf(signal_codes, 10122) > -1) {
          return <KeyPairAlert details={alert_detail} text={10122} />;
        } else if (_.indexOf(signal_codes, 10191) > -1) {
          return <KeyPairAlert details={alert_detail} text={10191} />;
        } else if (
          _.indexOf(signal_codes, 10192) > -1 ||
          _.indexOf(signal_codes, 10109) > -1
        ) {
          return <MultiDetailTable details={alert_detail} text={10192} />;
        } else if (_.indexOf(signal_codes, 10228) > -1) {
          return <KeyPairAlert details={alert_detail} text={10228} />;
        } else if (_.indexOf(signal_codes, 10101) > -1) {
          return <MultiDetailTable details={alert_detail} text={10101} />;
        } else if (_.indexOf(signal_codes, 10218) > -1) {
          const details = [_.head(_.flatMapDeep(alert_detail))];
          return <KeyPairAlert details={details} text={10218} />;
        } else if (_.indexOf(signal_codes, 10190) > -1) {
          return <MultiDetailTable details={alert_detail} text={10190} />;
        } else if (_.indexOf(signal_codes, 10149) > -1) {
          return <KeyPairAlert details={alert_detail} text={10149} />;
        } else if (_.indexOf(signal_codes, 20011) > -1) {
          return <KeyPairAlert details={alert_detail} text={20011} />;
        } else if (_.indexOf(signal_codes, 10153) > -1) {
          return <KeyPairAlert details={alert_detail} text={10153} />;
        } else if (_.indexOf(signal_codes, 20006) > -1) {
          return <OtherFinesAlert details={alert_detail} text={20006} />;
        } else if (_.indexOf(signal_codes, 222222) > -1) {
          return <KeyPairAlert details={alert_detail} text={222222} />;
        } else if (_.indexOf(signal_codes, 222223) > -1) {
          return <KeyPairAlert details={alert_detail} text={222223} />;
        } else if (_.indexOf(signal_codes, 10186) > -1) {
          return <KeyPairAlert details={alert_detail} text={10186} />;
        } else if (_.indexOf(signal_codes, 10135) > -1) {
          return <OtherFinesAlert details={alert_detail} text={10135} />;
        } else if (_.indexOf(signal_codes, 10111) > -1) {
          return <KeyPairAlert details={alert_detail} text={10111} />;
        } else if (_.indexOf(signal_codes, 10202) > -1) {
          return <KeyPairAlert details={alert_detail} text={10201} />;
        } else if (_.indexOf(signal_codes, 10125) > -1) {
          return <KeyPairAlert details={alert_detail} text={10125} />;
        } else if (_.indexOf(signal_codes, 10108) > -1) {
          return <KeyPairAlert details={alert_detail} text={10108} />;
        } else if (_.indexOf(signal_codes, 10114) > -1) {
          return <KeyPairAlert details={alert_detail} text={10114} />;
        } else if (_.indexOf(signal_codes, 222231) > -1) {
          return <KeyPairAlert details={alert_detail} text={222231} />;
        } else if (_.indexOf(signal_codes, 222226) > -1) {
          return <KeyPairAlert details={alert_detail} text={222226} />;
        } else if (_.indexOf(signal_codes, 20037) > -1) {
          return <KeyPairAlert details={alert_detail} text={20037} />;
        } else if (_.indexOf(signal_codes, 222229) > -1) {
          return <KeyPairAlert details={alert_detail} text={222229} />;
        } else if (_.indexOf(signal_codes, 20030) > -1) {
          return <MultiDetailTable details={alert_detail} text={20030} />;
        } else if (_.indexOf(signal_codes, 20002) > -1) {
          return <MultiDetailTable details={alert_detail} text={20002} />;
        } else if (_.indexOf(signal_codes, 20026) > -1) {
          return <KeyPairAlert details={alert_detail} text={20026} />;
        } else if (_.indexOf(signal_codes, 20015) > -1) {
          return <MultiDetailTable details={alert_detail} text={20015} />;
        } else if (_.indexOf(signal_codes, 10212) > -1) {
          return <KeyPairAlert details={alert_detail} text={10212} />;
          // }
          // else if (_.indexOf(signal_codes, 10181) > -1) {
          //   return <KeyPairAlert details={alert_detail} text={10181} />;
        } else if (_.indexOf(signal_codes, 10141) > -1) {
          return <CaseFiledAlert details={alert_detail} text={10141} />;
        } else if (_.indexOf(signal_codes, 20016) > -1) {
          return <BranchTooFarAlert details={alert_detail} text={20016} />;
        } else if (_.indexOf(signal_codes, 229091) > -1) {
          return <KeyPairAlert details={alert_detail} text={229091} />;
        } else if (_.indexOf(signal_codes, 10144) > -1) {
          return <KeyPairAlert details={alert_detail} text={10144} />;
        } else if (_.indexOf(signal_codes, 20034) > -1) {
          return <KeyPairAlert details={alert_detail} text={20034} />;
        } else if (_.indexOf(signal_codes, 11111) > -1) {
          return <KeyPairAlert details={alert_detail} text={11111} />;
        } else if (_.indexOf(signal_codes, 10104) > -1) {
          return <MediaAlert details={alert_detail} text={10104} />;
        } else if (_.indexOf(signal_codes, 10105) > -1) {
          return <CaseFiledAlert details={alert_detail} text={10105} />;
        } else if (_.indexOf(signal_codes, 10225) > -1) {
          return <KeyPairAlert details={alert_detail} text={10225} />;
        } else if (_.indexOf(signal_codes, 10217) > -1) {
          return <KeyPairAlert details={alert_detail} text={10217} />;
        } else if (_.indexOf(signal_codes, 10223) > -1) {
          return <CaseFiledAlert details={alert_detail} text={10223} />;
        } else if (_.indexOf(signal_codes, 10221) > -1) {
          return (
            <FinancialStatementAlert details={alert_detail} text={10221} />
          );
        } else if (_.indexOf(signal_codes, 10134) > -1) {
          return <NewsMediaAlert details={alert_detail} text={10134} />;
        } else if (
          _.indexOf(signal_codes, 10203) > -1 ||
          _.indexOf(signal_codes, 10392) > -1
        ) {
          return <NewsMediaAlert details={alert_detail} text={10203} />;
        } else if (_.indexOf(signal_codes, 20032) > -1) {
          let keyPairs = [
            {
              key: "account_number",
            },
            {
              key: "chq_amount",
            },
            {
              key: "customer_number",
            },
            {
              key: "max_chq_amount",
            },
            {
              key: "month",
            },
            {
              key: "no_of_chq",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10216) > -1) {
          let keyArr = ["year", "value"];
          let headerArr = [
            {
              name: "Year",
            },
            {
              name: "Value",
            },
          ];
          return (
            <FinancialStatementTrend
              details={alert_detail}
              text={alertText}
              keyArr={keyArr}
              headerArr={headerArr}
            />
          );
        } else if (_.indexOf(signal_codes, 10224) > -1) {
          let keyArr = ["_ref", "count", "paid"];
          let headerArr = [
            {
              name: "Wage Month",
            },
            {
              name: "Employees Count",
            },
            {
              name: "Amount Paid",
            },
          ];
          return (
            <EpfoAlert
              details={alert_detail}
              text={alertText}
              keyArr={keyArr}
              headerArr={headerArr}
            />
          );
        } else if (_.indexOf(signal_codes, 10181) > -1) {
          let keyPairs = [];
          if (alert_detail) {
            let key = {};
            alert_detail.forEach((detail) => {
              let detailKey = Object.keys(detail);
              detailKey.forEach((a) => {
                if (!key[a]) {
                  key[a] = "added";
                }
              });
            });
            let keyArr = Object.keys(key);
            keyArr.forEach((item) => {
              if (item === "_ref") {
                keyPairs.push({ key: "_ref" });
              } else {
                keyPairs.push({ key: item, type: "html" });
              }
            });
          }
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10233) > -1) {
          let keyPairs = [];
          if (alert_detail) {
            let key = {};
            alert_detail.forEach((detail) => {
              let detailKey = Object.keys(detail);
              detailKey.forEach((a) => {
                if (!key[a]) {
                  key[a] = "added";
                }
              });
            });
            let keyArr = Object.keys(key);
            keyArr.forEach((item) => {
              if (item === "_ref" || item === "title" || item === "text") {
                keyPairs.push({ key: item });
              } else if (item === "url" || item === "document_path") {
                keyPairs.push({ key: item, type: "link" });
              } else {
                keyPairs.push({ key: item, type: "html" });
              }
            });
          }
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10219) > -1) {
          let keyPairs = [
            {
              key: "profit_or_loss",
            },
            {
              key: "profit_after_tax",
            },
            {
              key: "interest_expense",
            },
            {
              key: "tax_expense",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10209) > -1) {
          let keyPairs = [
            {
              key: "cash_and_cash_equivalents",
            },
            {
              key: "current_liabilities",
            },
            {
              key: "ratio",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10220) > -1) {
          let keyPairs = [
            {
              key: "fy_year",
            },
            {
              key: "transtions_count",
            },
            {
              key: "related_parties",
            },
            {
              key: "sum_of_amount",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10115) > -1) {
          let keyPairs = [
            {
              key: "document_type",
            },
            {
              key: "month",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10126) > -1) {
          let keyPairs = [
            {
              key: "date",
            },
            {
              key: "label",
            },
            {
              key: "competent_authority",
            },
          ];

          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10129) > -1) {
          let keyPairs = [
            {
              name: "Company Name",
            },
          ];
          let bodyData = [];
          if (alert_detail) {
            alert_detail.forEach((item) => {
              if (item !== "is_delayed") {
                bodyData.push([item]);
              }
            });
          }
          const extraOptions = {
            title: "Regulatory Concerns",
          };
          return (
            <AlertTable
              headerData={keyPairs}
              bodyData={bodyData}
              extraOptions={extraOptions}
              footerData={[]}
            />
          );
        } else if (
          _.indexOf(signal_codes, 10148) > -1 ||
          // _.indexOf(signal_codes, 10199) > -1 ||
          _.indexOf(signal_codes, 10147) > -1 ||
          _.indexOf(signal_codes, 10156) > -1 ||
          _.indexOf(signal_codes, 10158) > -1 ||
          _.indexOf(signal_codes, 10165) > -1 ||
          _.indexOf(signal_codes, 10163) > -1 ||
          _.indexOf(signal_codes, 10164) > -1 ||
          _.indexOf(signal_codes, 10165) > -1 ||
          _.indexOf(signal_codes, 10171) > -1 ||
          _.indexOf(signal_codes, 10177) > -1
        ) {
          let keyPairs = [
            {
              key: "date",
            },
            {
              key: "label",
            },
            {
              key: "competent_authority",
            },
            {
              key: "regulatory_charges",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 20017) > -1) {
          let keyPairs = [
            {
              key: "financial_year",
            },
            {
              key: "contingent_liabilities_value",
            },
            {
              key: "profit_or_loss_value",
            },
            {
              key: "percentage",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (
          _.indexOf(signal_codes, 10112) > -1 ||
          _.indexOf(signal_codes, 10184) > -1
        ) {
          let keyPairs = [
            {
              key: "previous_accounting_period",
            },
            {
              key: "current_accouning_period",
            },
            {
              key: "date_of_action",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10196) > -1) {
          let keyPairs = [
            {
              key: "charges_amount",
            },
            {
              key: "long_term_borrowings",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10205) > -1) {
          let keyPairs = [
            {
              key: "date",
            },
            {
              key: "_model_type",
            },
            {
              key: "instrument_type",
            },
            {
              key: "current_rating",
            },
            {
              key: "previous_rating",
            },
          ];
          return (
            <SingleKeyTable
              details={alert_detail}
              text={alertText}
              keyPairs={keyPairs}
            />
          );
        } else if (_.indexOf(signal_codes, 10430) > -1) {
          return <MultiDetailTable details={alert_detail} text={10430} />;
        } else {
          return <DefaultAlertDetails details={alert_detail} />;
        }
      } else if (_.isString(alertText) && !_.isUndefined(alertText)) {
        let keyPairs = [];
        switch (alertText) {
          case "change_in_ews_score":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "financial_not_uploaded_in_mca":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "negative_media_sentiment":
            return <MediaAlert details={alert_detail} text={alertText} />;

          case "significant_movements_in_inventory":
            return (
              <SignificantMovement details={alert_detail} text={alertText} />
            );

          case "significant_movements_in_receivables":
            return (
              <SignificantMovement details={alert_detail} text={alertText} />
            );

          case "gst_payment_non_compliance":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          //  Edit Start

          case "probable_case_filed_against_director":
            return <CaseFiledAlert details={alert_detail} text={alertText} />;

          case "proceeding_nclt":
            return <CaseFiledAlert details={alert_detail} text={alertText} />;

          case "critical_issues_highlighted_in_the_stock_audit_report":
            return <StockAudit details={alert_detail} text={alertText} />;

          case "charge_creation_modification":
            return (
              <ChargeAlert
                details={alert_detail}
                text={alertText}
                summary={alert_summary}
              />
            );

          case "disproportionate_increase_in_other_current_assets":
            return (
              <FinancialStatementAlert
                details={alert_detail}
                text={alertText}
              />
            );

          case "declining_tnw_trend":
            return (
              <FinancialStatementTrend
                details={alert_detail}
                text={alertText}
              />
            );

          case "default_payment_sundry_debtors_other_statutory_bodies":
            return <OtherFinesAlert details={alert_detail} text={alertText} />;

          case "high_fines_imposed":
            return <OtherFinesAlert details={alert_detail} text={alertText} />;

          case "frequent_change_in_auditors":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "declining_stock_prices":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "current_ratio_less_than_one":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "increase_promoter_pledges":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "epfo_filing_non_compliance":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "reduction_in_stake_promoter":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "resignation_of_director":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "change_in_director":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "frequent_change_in_directors":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "external_rating_downgrade":
            return (
              <ExternalRatingDowngrade
                details={alert_detail}
                text={alertText}
              />
            );

          case "weak_or_declining_zscore":
            return <WeekZscore details={alert_detail} text={alertText} />;

          case "high_number_of_cheque_returns_in_a_day":
            keyPairs = [
              {
                key: "account_number",
              },
              {
                key: "chq_amount",
              },
              {
                key: "customer_number",
              },
              {
                key: "max_chq_amount",
              },
              {
                key: "month",
              },
              {
                key: "no_of_chq",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "bouncing_of_the_high_value_cheques":
            keyPairs = [
              {
                key: "account_no",
              },
              {
                key: "cif_id",
              },
              {
                key: "entry_user_id",
              },
              {
                key: "tran_amount",
              },
              {
                key: "tran_id",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "frequent_invocation_of_bgs":
            keyPairs = [
              {
                key: "account_no",
              },
              {
                key: "cif_id",
              },
              {
                key: "entry_user_id",
              },
              {
                key: "tran_amount",
              },
              {
                key: "tran_id",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "high_value_rtgs_payment_to_unrelated_parties":
            keyPairs = [
              {
                key: "account_no",
              },
              {
                key: "cif_id",
              },
              {
                key: "entry_user_id",
              },
              {
                key: "tran_amount",
              },
              {
                key: "tran_id",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "concealment_of_vital_documents":
            keyPairs = [
              {
                key: "audit_observation",
              },
              {
                key: "auditor_name",
              },
              {
                key: "name_of_report",
              },
              {
                key: "observation_category",
              },
              {
                key: "observation_category_process_category",
              },
              {
                key: "period",
              },
              {
                key: "report_issue_date",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "no_of_credit_transactions_low":
            keyPairs = [
              {
                key: "date",
              },
              {
                key: "last_trans_date",
              },
              {
                key: "total_chq",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "high_proportion_of_cheque_return_ews":
            keyPairs = [
              {
                key: "date",
              },
              {
                key: "num_of_chq_return",
              },
              {
                key: "num_of_chq_trans",
              },
              {
                key: "percentage",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "deterioration_in_the_dpd_behaviour":
            keyPairs = [
              {
                key: "account_number",
              },
              {
                key: "customer_number",
              },
              {
                key: "month",
              },
              {
                key: "previous_status",
              },
              {
                key: "status",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "delay_observed_in_payment_of_outstanding_dues":
            keyPairs = [
              {
                key: "date",
              },
              {
                key: "num_of_accounts_in_outstanding",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "rmd_alert":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "account_risk":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "frequent_ad_hoc_sanctions_of_loan_facilities":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "exceeding_regularization":
            keyPairs = [
              {
                key: "account_number",
              },
              {
                key: "balance_outstanding",
              },
              {
                key: "customer_number",
              },
              {
                key: "interest_arrear_amt",
              },
              {
                key: "interest_arrear_days",
              },
              {
                key: "limit",
              },
              {
                key: "month",
              },
              {
                key: "principal_arr_days",
              },
              {
                key: "principal_arrear_amt",
              },
              {
                key: "product_code",
              },
              {
                key: "product_desc",
              },
              {
                key: "reason",
              },
              {
                key: "status",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "rating_overdue":
            keyPairs = [
              {
                key: "customer_number",
              },
              {
                key: "due_date_for_rating",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "significant_movements_in_inventory_receivables_payables_dp":
            keyPairs = [
              {
                key: "inventories",
              },
              {
                key: "month",
              },
              {
                key: "previous_inventories",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "covenant_breaches":
            keyPairs = [
              {
                key: "cif_id",
              },
              {
                key: "covenant_desc",
              },
              {
                key: "days_past_due",
              },
              {
                key: "dpd_bucket",
              },
              {
                key: "due_date",
              },
              {
                key: "exception_status",
              },
              {
                key: "type_of_covenant",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "non_routing_of_cash_flows":
            keyPairs = [
              {
                key: "account_no",
              },
              {
                key: "amount",
              },
              {
                key: "month",
              },
              {
                key: "previous_amount",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "utilisation":
            keyPairs = [
              {
                key: "account_no",
              },
              {
                key: "advances",
              },
              {
                key: "balance_date",
              },
              {
                key: "currency_code",
              },
              {
                key: "moving_average",
              },
              {
                key: "principal_outstanding",
              },
              {
                key: "sanction_limit",
              },
              {
                key: "utilization",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          case "decline_in_revenue_or_profitability":
            keyPairs = [
              {
                key: "change_in_profitablity",
              },
              {
                key: "change_in_revenue",
              },
              {
                key: "latest_profitability",
              },
              {
                key: "latest_reporting_date",
              },
              {
                key: "latest_revenue",
              },
              {
                key: "previous_profitability",
              },
              {
                key: "previous_reporting_date",
              },
              {
                key: "previous_revenue",
              },
            ];
            return (
              <SingleKeyTable
                details={alert_detail}
                text={alertText}
                keyPairs={keyPairs}
              />
            );

          //  Edit End

          case "KMP/ Promoters found to be politically connected":
            return (
              <PoliticalExposure details={alert_detail} text={alertText} />
            );

          case "build_up_finished_good_inventory":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "declining_ebidta_margins":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "increasing_total_debt_per_fixed_asset":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "increasing_trend_total_debt":
            return <MultiDetailTable details={alert_detail} text={alertText} />;

          case "high_value_outward_check_bounce_transactions":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "profitability_decreased":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "high_frequency_outward_check_bounce_transactions":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          case "proceeding_drt_cases":
            return <KeyPairAlert details={alert_detail} text={alertText} />;

          default:
            return <DefaultAlertDetails details={alert_detail} />;
          // return  <NotConfigured />;
        }
      }
    }
  };
  const PrivateTable = (alert) => {
    let alertText = alert.alert_signal;
    let alert_detail = alert.alert_detail;
    let keyPairs = [];
    if (alert_detail) {
      if (!_.isArray(alert_detail) && typeof alert_detail === "object") {
        alert_detail = [alert_detail];
      }
      let objectKey = [];
      if (_.isArray(alert_detail) && alert_detail[0]) {
        objectKey = Object.keys(alert_detail[0]);

        objectKey.forEach((item) => {
          if (typeof alert_detail[0][item] !== "object") {
            keyPairs.push({ key: item });
          }
        });
      }
    }

    return (
      <SingleKeyTable
        details={alert_detail}
        text={alertText}
        keyPairs={keyPairs}
      />
    );
  };
  const NewAlertComponent = (alert) => {
    const { signal_format } = alert;
    switch (signal_format) {
      case 1:
        return PrivateTable(alert);
      case 2:
        return <FormatTwo details={alert.alert_detail} />;
      case 3:
        return <FormatThree details={alert.alert_detail} />;
      case 7:
        return <FormatSeven details={alert.alert_detail} />;
      case 6:
        return <FormatSix details={alert.alert_detail} />;

      default:
        return <DefaultAlertDetails details={alert.alert_detail} />;
    }
  };

  let alert_fomat_code = _.get(alert, "signal_format");
  return (
    <>
      {alert && alert.alert_detail ? (
        <div className="row">
          <div className="col-md-12" style={{ fontSize: "0.75rem" }}>
            {(() => {
              let alertComponent = <></>;
              try {
                alertComponent = (
                  <div>
                    <div className="d-flex">
                      <div className="p-2">{`Date Of Event: ${moment(alert.date_of_event).format("DD-MM-YYYY")}`}</div>
                      {alert.alert_detail[0] &&
                        alert.alert_detail[0].thresholds && <HelpButtonUI />}
                    </div>

                    {alert_fomat_code && alert_fomat_code
                      ? NewAlertComponent(alert)
                      : getAlertComponentUI(alert)}
                  </div>
                );
              } catch (err) {
                alertComponent = <NotConfigured text={"Type Error"} />;
              }
              return alertComponent;
            })()}
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <h6>Not A Valid Alert!</h6>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertDetails;
