import React from "react";

import ModalPopup from "./ModalPopup";
import downloadByOctetStream from './DownloadReportFunction'

function ReportTypes(props) {

  const { reportname, reportId, logo, subTitle, imgCom } = props;

  return (
    <div className="d-md-flex d-block justify-content-between align-items-center">
      <div className="d-sm-none">
        <img src={imgCom} alt="Avatar" />
      </div>

      <div className="ml-2 col-md-6">
        <div className="mb-3 d-none d-sm-block">
          <img src={logo} alt="Avatar" />
        </div>
        <h5 className="menu-header-title mt-2">{reportname}</h5>
        <p className="mt-3">{subTitle}</p>
        <div className="mt-4">
          <div className="d-flex align-items-center flex-wrap">
            <div className="d-inline-block me-2 mb-2">
              <ModalPopup reportname={reportname} />
            </div>
            {reportname === "C-Docs Report" ? (
              ""
            ) : (
              <div className="d-inline-block mb-2">
                <button className="cw-btn cw-btn-link" onClick={() =>
                  downloadByOctetStream(
                    "Dashboard",
                    reportId
                  )
                }
                >
                  Sample Report
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-none d-sm-inline col-md-6">
        <div className="d-flex justify-content-center">
          <img src={imgCom} alt="Avatar" />
        </div>
      </div>
    </div>
  )
}

export default ReportTypes
