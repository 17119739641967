import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AlertCardSkeleton = () => {
  return (
    <div className="case-container p-3 rounded-3" style={{ border: '1px solid #ddd', borderRadius: '8px' }}>
      {/* first row desktop*/}
      <div className="d-flex flex-row justify-content-between w-100 gap-2">
        <div className="d-flex flex-row  align-items-center gap-1">
          <div className="d-flex flex-row align-items-center me-2 gap-1">
            <Skeleton width={80} height={30} />
            <Skeleton width={60} height={20} />
          </div>
          <div className="d-none d-md-block text-primary text-wrap ml-2 fs-14">
            <Skeleton width={200} />
          </div>
        </div>
        {/* right alignment */}
        <div className="d-flex flex-row justify-content-end">
          <div className="d-flex flex-row gap-1">
            <div className="d-none d-md-block">
              <div className="d-flex align-items-center">
                <Skeleton width={20} height={20} circle={true} className="me-2" />
                <Skeleton width={150} />
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* Skeleton for Low label */}
              <Skeleton width={50} height={20} />
            </div>
          </div>
        </div>
      </div>
      {/* second row mobile */}
      <div className="d-block d-md-none text-primary text-wrap ml-2 fs-14 mt-2 ms-1">
        <Skeleton width={200} />
      </div>
      {/* second row desktop */}
      <div className="row py-2">
        <div className="fs-14 ms-1"><Skeleton width="100%" /></div>
      </div>
      {/* third row desktop */}
      <div className="d-none d-md-block">
        <div className="d-flex flex-row w-100 gap-2">
          <div className="d-flex flex-row align-items-center gap-1 fs-12">
            <Skeleton width={20} height={20} circle={true} />
            <Skeleton width={100} />
          </div>
          <div className="gap-1"></div>
          <div className="d-flex flex-row align-items-center gap-1 fs-12">
            <Skeleton width={20} height={20} circle={true} />
            <Skeleton width={150} />
          </div>
          {/* right alignment */}
          <div className="d-flex flex-row w-100 justify-content-end">
            <div className="d-flex flex-row gap-1">
              <Skeleton width={20} height={20} circle={true} />
              <Skeleton width={20} height={20} circle={true} />
            </div>
          </div>
        </div>
      </div>
      {/* third row mobile */}
      <div className="d-block d-md-none fs-12">
        <div className="d-flex flex-row align-items-center gap-1 mb-2">
          <Skeleton width={20} height={20} circle={true} className="ms-1" />
          <Skeleton width={100} />
        </div>
      </div>
      {/* fourth row mobile */}
      <div className="d-block d-md-none fs-12">
        <div className="d-flex flex-row align-items-center gap-1 mb-2">
          <Skeleton width={20} height={20} circle={true} />
          <Skeleton width={150} />
        </div>
      </div>
      {/* fifth row mobile */}
      <div className="d-block d-md-none">
        <div className="d-flex flex-row justify-content-between mb-2 gap-1">
          <div className="d-flex align-items-center fs-12">
            <Skeleton width={20} height={20} circle={true} />
            <Skeleton width={150} />
          </div>
          <div className="d-flex flex-row w-100 justify-content-end">
            <div className="d-flex flex-row gap-1">
              <Skeleton width={20} height={20} circle={true} />
              <Skeleton width={20} height={20} circle={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertCardSkeleton;
