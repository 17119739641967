import React from 'react'

export const RunDiligence = () => {
  return (
    <div className=" mb-3 widget-chart p-0 text-left h-100">
      <div className='d-flex flex-md-column flex-lg-row'>
        <div className="icon-wrapper border-light rounded align-self-center">
          <div className="icon-wrapper-bg bg-light align-self-center"></div><i className="fas fa-text-bg-check align-self-center"></i>
        </div>
        <div className="widget-numbers align-self-center text-left text-md-center text-lg-left">Assess Credit Risk</div>
      </div>
      <div className="widget-chart-content">

        <div className="widget-subheading text-center">Make data informed credit decisions by assessing businesses in near real time.</div>
      </div>
    </div>
  )
}

export const HelpMessage = () => {
  return (
    <div>
      <div>
        <div className="fs-18 fw-600 fc-blue">How can I help you today</div>
      </div>
      <div className="fs-12 fw-400 fc-black ">Here are some things you can do to simplify your business</div>
    </div>
  )
}

export const TodoMessage = () => {
  return (
    <div>
      <div>
        <div className="fs-18 fw-600 fc-blue">What would you like to do?</div>
      </div>
      <div className="fs-12 fw-400 fc-black ">Here are some quick actions you can consider</div>
    </div>
  )
}

export const MonitorRisk = () => {
  return (
    <div className=" mb-3 widget-chart p-0 text-left h-100">
      <div className='d-flex flex-md-column flex-lg-row'>
        <div className="icon-wrapper border-light rounded align-self-center">
          <div className="icon-wrapper-bg bg-light align-self-center"></div><i className="fas fa-text-bg-check align-self-center"></i>
        </div>
        <div className="widget-numbers align-self-center text-left text-md-center text-lg-left">Monitor Businesses</div>
      </div>
      <div className="widget-chart-content">

        <div className="widget-subheading text-center">Monitor your counterparty's business vitals to ensure your business is protected from risk.</div>
      </div>
    </div>

  )
}

export const CheckBussinessVerified = () => {
  return (
    <div className=" mb-3 widget-chart p-0 text-left h-100">
      <div className='d-flex flex-md-column flex-lg-row'>
        <div className="icon-wrapper border-light rounded align-self-center">
          <div className="icon-wrapper-bg bg-light align-self-center"></div><i className="fas fa-text-bg-check align-self-center"></i>
        </div>
        <div className="widget-numbers align-self-center text-left text-md-center text-lg-left">Verify a Bussiness</div>
      </div>
      <div className="widget-chart-content">

        <div className="widget-subheading text-center">Verify a Vendor or supplier to ensure you are dealing with a genuine business</div>
      </div>
    </div>
  )
}


