import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Slider } from "react-burgers";

import {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

const AppMobileMenu = ({
  sideBarItems,
  enableMobileMenu,
  setEnableMobileMenu,
  enableMobileMenuSmall,
  setEnableMobileMenuSmall,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const userPaths = ["/admin/users", "/admin/jobs", "/admin/roles"];
  const userSidebar = userPaths.filter((each) => pathname.includes(each));
  const enableSideBar =
    _.isArray(sideBarItems) && sideBarItems.length > 0 ? true : false;

  const toggleMobileSidebar = () => {
    setEnableMobileMenu(!enableMobileMenu);
  };

  const toggleMobileSmall = () => {
    setEnableMobileMenuSmall(!enableMobileMenuSmall);
  };

  return (
    <Fragment>
      <div className="app-header__mobile-menu p-0">
        {enableSideBar && (
          <div
            data-tut="party-sidebar-responsive"
            onClick={toggleMobileSidebar}
          >
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#4F5D7B"
              active={enableMobileMenu}
              onClick={toggleMobileSidebar}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  sideBarItems: state.ThemeOptions.sideBarItems,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);
