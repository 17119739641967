import React, { Component } from "react";

import AlertTable from "./AlertTable";

class SignificantMovement extends Component {
  convertKeytoFiled = key => {
    if (key && typeof key === "string") {
      return key.split("_").join(" ");
    } else {
      return "";
    }
  };

  getBodyData = () => {
    const { details } = this.props;
    let body = [];
    if (details && details.length > 0) {
      details.forEach(ele => {
        let arr = [];
        arr.push(ele.cif_id);
        arr.push(ele.covenant_desc);
        arr.push(ele.days_past_due);
        arr.push(ele.dpd_bucket);
        arr.push(ele.due_date);
        arr.push(ele.exception_status);
        arr.push(ele.type_of_covenant);
        body.push(arr);
      });
    }
    return body;
  };

  getAlertTableUI = () => {
    const { text } = this.props;
    const headerData = [
      {
        name: "CIF ID"
      },
      {
        name: "Covenant Desc"
      },
      {
        name: "Days Past Due"
      },
      {
        name: "Dpd Bucket"
      },
      {
        name: "Due Date"
      },
      {
        name: "Exception Status"
      },
      {
        name: "Type Of Covenant"
      }
    ];
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text
    };

    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <div>{this.getAlertTableUI()}</div>;
  }
}

export default SignificantMovement;
