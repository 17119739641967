import React from "react";
import SanitizedHTML from "react-sanitized-html";

import AlertTable from "./AlertTable";

class AuditReportAlert extends React.Component {
  getAlertTableUI = () => {
    const { text, details } = this.props;
    const headerData = [];
    const bodyData = [
      [
        <div className="text-center">
          <SanitizedHTML html={details[0].blob} />
        </div>
      ]
    ];
    const extraOptions = {
      title: text
    };
    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    const { details } = this.props;
    return (
      <div>
        {details && details[0] && details[0].blob
          ? this.getAlertTableUI()
          : "No alerts"}
      </div>
    );
  }
}

export default AuditReportAlert;
