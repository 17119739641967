import React from "react";
import _ from "lodash";

class FinancialStatementAlert extends React.Component {
  render() {
    let details = this.props.details;
    details = _.flatMapDeep(details);

    details = _.map(details, (data, index) => {
      var result = {};
      _.forEach(data, (d, k) => {
        if (_.isString(d) || _.isNumber(d)) {
          result[k] = d;
        } else if (_.isObject(d)) {
          result["_"] = k;
          let newD = {};
          _.forEach(d, (i, j) => {
            if (_.isString(i) || _.isNumber(i)) {
              newD[j] = i;
            }
          });
          _.merge(result, newD);
        }
      });
      return result;
    });

    const keys = _.map(_.keys(_.head(details)));

    return (
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            {_.map(keys, (key) => {
              return (
                <th key={key}>
                  {_.capitalize(_.join(_.split(key, "_"), " "))}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(details, (data, index) => {
            return (
              <tr key={index}>
                {_.map(keys, (key) => {
                  return (
                    <td key={index + key}>
                      {(data[key] || data[key] === Number(0)) &&
                      (_.isString(data[key]) || _.isNumber(data[key]))
                        ? data[key]
                        : "---"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default FinancialStatementAlert;
