import React, { useState, useEffect } from "react";
import brand_colors from "../../utils/colors";
import service from "../../utils/service";
import Handle403 from "../../components/Handle403";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

const ReactHighcharts = require("react-highcharts");

const CMM_Overview = ({ series }) => {
  const [cmmData, setCMMData] = useState({ status: "loading", res: {} });

  useEffect(() => {
    getCMMAPI();
  }, []);

  const getCMMAPI = async () => {
    let API_URL = "v1/cases/case_count/status_category?type=overall";
    let res = await service("get", API_URL);
    if (res.status === 403) {
      setCMMData({
        status: "403",
        res: data,
      });
    }
    let data = _.get(res, "data");
    let meta = _.get(res, "meta");
    if (data && meta) {
      setCMMData({
        status: "done",
        res: data,
      });
    }
  };

  const getChartColor = (key) => {
    let color = "";
    switch (key) {
      case "WIP":
        return (color = brand_colors.wip);
      case "CLOSED":
        return (color = brand_colors.closed);
      case "NEW":
        return (color = brand_colors.new);
      default:
        return (color = "");
    }
  };

  const convertConfigData = () => {
    let convertedData = [];
    _.isObject(cmmData.res) &&
      Object.keys(cmmData.res).map((key, value) => {
        if (key !== "CLOSED") {
          let obj = {
            name: key,
            y: cmmData.res[key],
            color: getChartColor(key),
          };
          convertedData.push(obj);
          return null;
        }
      });
    return convertedData;
  };

  let data = convertConfigData();

  const config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 250,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y}",
        },
      },
    },
    series: [
      {
        name: "Cases",
        colorByPoint: true,
        data: data,
      },
    ],
  };

  const displayRiskMessage = () => {
    let cmmObjData = cmmData.res;
    if (
      cmmObjData.WIP === 0 &&
      cmmObjData.CLOSED === 0 &&
      cmmObjData.NEW === 0
    ) {
      return (
        <div className="d-flex justify-content-center align-items-center h-50 text-center mt-3 opacity-3">
          Looks like you don't have any CMM overview score
        </div>
      );
    } else {
      return <ReactHighcharts config={config} />;
    }
  };

  const CmmOverview = () => {
    switch (cmmData.status) {
      case "done":
        return displayRiskMessage();
      case "403":
        return <Handle403/>
      default:
        return <Skeleton count={5} />;
    }
  };

  return (
    <div className="card h-100 p-0">
      <div className="card-body p-1 m-0">
        <div className="card-title p-2 m-0">CMM Overview</div>
        {CmmOverview()}
      </div>
    </div>
  );
};

export default CMM_Overview;
