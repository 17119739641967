import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearch } from "@fortawesome/free-solid-svg-icons";
import { FormGroup } from "reactstrap";
import Select from "react-select-new";
import qs from "query-string";

import _ from "lodash";
import StateCity from "./state-city.json";
import { useHistory } from "react-router-dom";
import allPaths from "../../../../../route/allPaths";

const NameSearch = () => {
  const [query, setQuery] = useState("");

  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  const [selectedStateOption, setSelectedStateOption] = useState("");
  const [selectedCityOption, setSelectedCityOption] = useState("");
  let D_select =
    selectedStateOption && _.get(selectedStateOption, "value", null);

  useEffect(() => {
    getAllStates();
  }, []);

  useEffect(() => {
    if (D_select) {
      getSelectedCities();
    }
  }, [D_select]);

  const history = useHistory();

  const handleSearch = () => {
    if (query) {
      history.push(
        `${allPaths.VERIFY_BUSINESS}?query=${query}&state=${selectedStateOption}&city=${selectedCityOption}`
      );
    }
    let defaultRoute = allPaths.VERIFY_BUSINESS;
    let searchObj = {
      query: query,
    };
    if (selectedStateOption.value) {
      searchObj.state = selectedStateOption.value;
    }
    if (selectedCityOption.value) {
      searchObj.city = selectedCityOption.value;
    }
    let searchString = qs.stringify(searchObj);
    history.push({
      pathname: defaultRoute,
      search: searchString,
    });
  };

  const handleQueryEnter = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const getAllStates = () => {
    let arr = _.keys(StateCity);
    let stateOptions = arr.map((element, key) => {
      return {
        value: element,
        label: element,
      };
    });
    setAllStates(stateOptions);
  };

  const getSelectedCities = () => {
    let cities = StateCity[selectedStateOption.value];
    let arr = [];
    cities.forEach((item) => {
      arr.push({
        value: item,
        label: item,
      });
    });
    setAllCities(arr);
  };

  const handleChangeState = (selectedStateOption) => {
    setSelectedStateOption(selectedStateOption);
  };

  const handleChangeCity = (selectedCityOption) => {
    setSelectedCityOption(selectedCityOption);
  };

  return (
    <Fragment>
      <div className="d-flex mb-2">
        <div className="icon-22 me-2 ">
          <img
            src={`/cdn/assets/icons/product/verify_business.svg`}
            alt=""
          />
        </div>
        <div>
          <div>
            <div className="fs-18 fw-600 fc-blue">Verify Businesses</div>
          </div>
          <div className="fs-12 fw-400 fc-black ">
            Quickly verify the legitimacy of any business.
          </div>
        </div>
      </div>

      <FormGroup>
        <input
          type="text"
          name="search_companies"
          className={`form-control`}
          placeholder="Enter name to verify"
          value={query}
          onKeyDown={handleQueryEnter}
          onChange={handleQueryChange}
        />
      </FormGroup>
      <FormGroup>
        <Select
          value={selectedStateOption}
          onChange={handleChangeState}
          isClearable
          options={allStates}
          placeholder="Select State (Optional)"
        />
      </FormGroup>

      <FormGroup>
        <Select
          value={selectedCityOption}
          onChange={handleChangeCity}
          isClearable
          options={allCities}
          placeholder="Select City (Optional)"
        />
      </FormGroup>
      <div className="form-group">
        <button
          type="button"
          className="cw-btn cw-btn-secondary"
          onClick={handleSearch}
        >
          <FontAwesomeIcon icon={faSearch} /> Search
        </button>
      </div>
    </Fragment>
  );
};

export default NameSearch;
