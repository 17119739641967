import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import _ from "lodash";
import moment from "moment";
import { NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Mixpanel } from "../../../../../utils/mixpanel";

import allPaths from "../../../../../route/allPaths";
import OrderReportFromDashboard from "../order_report_dashboard";
import service from "../../../../../utils/service";

function RecentActivites(props) {
  const [data, setData] = useState([]);
  const [orderModal, setOrderModal] = useState(false);

  useEffect(() => {
    getLatestReports(props.data);
  }, [props.data]);

  const getLatestReports = (act_orders) => {
    let recentActivitiesList = [];
    if (act_orders && _.isArray(act_orders)) {
      recentActivitiesList = _.map(act_orders, (order) => {
        const createdAt = _.get(order, "attributes.created_at", null);
        return {
          name: _.get(order, "attributes.party_name", ""),
          party_id: _.get(order, "attributes.party_id"),
          product_id: _.get(order, "id"),
          status: _.get(order, "attributes.status"),
          product_name: _.upperCase(_.get(order, "attributes.report_type", "")),
          report_type: _.get(order, "attributes.report_type", ""),
          created_at: createdAt ? moment(createdAt).fromNow() : "---",
          ...order
        };
      });
    }
    setData(recentActivitiesList);
  };
  const { loader } = props;

  let history = useHistory();

  // const downloadFunc = (report) => {
  //   let type = _.get(report, "report_type", "");
  //   let party_id = _.get(report, "party_id", "");
  //   let url = "";
  //   if (type) {
  //     if (type === "c-docs" || type === "advanced-timeline") {
  //       url = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}/timelines`;
  //     } else {
  //       url = `${allPaths.PDF_ROUTE}?slug=${report.id}`;
  //     }
  //   }
  //   if (url) {
  //     history.push(url);
  //   }
  // };

  const trigger_report_download = async (report) => {
    let DOCUMENTS_API_URL = `v1/report/products/${report.id}/download`;
    let res = await service("get", DOCUMENTS_API_URL);

    if (res) {
      let type = _.get(report, "attributes.report_type", "");
      let party_id = _.get(report, "attributes.party_id", "");
      let url = "";
      if (_.get(res, "meta.storage_type", "") === "EFS") {
        if (type) {
          if (type === "c-docs" || type === "advanced-timeline") {
            url = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}/timelines`;
          } else {
            url = `${allPaths.PDF_ROUTE}?slug=${report.id}`;
          }
        }
      } else if (_.get(res, "meta.storage_type", "") === "S3") {
        window.open(_.get(res, "data", ""));
      }
      if (url) {
        history.push(url);
      }
    }
  };
  const handleMixpanelEvent = () => {
    Mixpanel.track("click_order_history", { "page_name": "Dashboard", "source": "recent_orders" })
    history.push(allPaths.PRODUCTS_ROUTE)
  }

  const reportClassName = (type) => {
    let className = "";
    if (type === "c-360") {
      className = "c-360"
    } else if (type === "c-docs") {
      className = "c-docs"
    }
    else if (type === "c-trust") {
      className = "c-trust"
    } else {
      className = "default-report"
    }
    return className
  }

  return (
    <div>
      <div className="fs-18 mb-2 fc-black fb fw-600">Recent Orders</div>
      <VerticalTimeline
        layout="1-column"
        className=" vertical-time-simple vertical-without-time"
      >
        {loader === "loading" && (
          <div className="d-flex justify-content-between align-items-center">
            Loading
          </div>
        )}
        {loader === "done" && data.length === 0 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div>No record found.</div>
            <div>Order a report now!</div>
            <button className="btn-transition btn btn-outline-primary mt-3" onClick={() => setOrderModal(true)}> Order Now </button>
            <OrderReportFromDashboard show={orderModal} close={() => setOrderModal(false)} />
          </div>
        )}
        {loader === "done" &&
          data.map((item, key) => {
            return (
              <div key={key} className="mb-3">
                <VerticalTimelineElement className="vertical-timeline-item ">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className=" fs-12 text-primary"
                      // className="fs-10 fw-400 text-uppercase fc-black word-spacing "
                      >
                        <NavLink
                          to={`${allPaths.PARTY_ROOT_ROUTE}/${item.party_id}`}
                        >
                          {item.name}
                        </NavLink>
                      </div>
                      <h4 className="timeline-title">
                        {item.product_name}
                      </h4>
                      <p>{`Ordered ${item.created_at}`}</p>
                    </div>
                    <div className="icon-22">

                      {_.toLower(item.status) === "done" ? (
                        <button
                          type="button"
                          disabled
                          className="btn btn-sm report-download-icon-disabled cursor-p"
                        >
                          <img src={`/cdn/assets/icons/product/${reportClassName(item.product_name)}.svg`}
                            onClick={() => trigger_report_download(item)} alt="" />
                        </button>
                      ) :
                        <button
                          type="button"
                          disabled
                          className="btn btn-sm report-download-icon-disabled "
                        >
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                            style={{ fontSize: "25px" }}
                          />
                        </button>
                      }
                    </div>
                  </div>
                </VerticalTimelineElement>
              </div>
            );
          })}
      </VerticalTimeline>
      {loader === "done" && data.length > 0 &&
        <div className="d-flex justify-content-end">
          <div>
            <button className="cw-btn cw-btn-link" onClick={handleMixpanelEvent}>
              VIEW MORE
            </button>
          </div>
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: _.get(state.dashboard.product, "result.data"),
    meta: _.get(state.dashboard.product, "result.meta"),
    loader: _.get(state.dashboard.product, "status"),
  };
}

export default connect(mapStateToProps)(RecentActivites);
