import React, { useEffect, useState } from "react";
import _ from "lodash";
import { withRouter, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import AlertDetails from "../../alert_details";
import service from "../../../utils/service";

const OffCanvasAlert = (props) => {
  const { history } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState();

  const handleCloseOffcanvas = () => {
    queryParams.delete("offCanvas");
    history.push({ search: queryParams.toString() });
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const alertId = queryParams.get("offCanvas");

  useEffect(() => {
    if (alertId) {
      trigger_API();
    }
  }, [alertId]);

  const trigger_API = async () => {
    setLoading(true);
    const API_URL = `v1/admin/alerts/${alertId}`;
    let res = await service("get", API_URL);
    let data = _.get(res, "data[0]");
    if (data) {
      setAlertData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (alertId) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [alertId]);

  const alert_summary = _.get(alertData, "alert_summary", "");
  const alert_category = _.get(alertData, "alert_category", "");
  const signal_version = _.get(alertData, "signal_version", null);
  const case_id = _.get(alertData, "case_id", "");
  const party_name = _.get(alertData, "party_name", "");
  const party_id = _.get(alertData, "party_id", "");
  const created_at =
    _.get(alertData, "date_of_alert", "") || _.get(alertData, "created_at", "");
  const alert_type = _.get(alertData, "alert_type", "");
  const signal_code = _.get(alertData, "signal_code");
  const customer_number = _.get(alertData, "customer_number");
  let current_party_obj = {
    name: party_name,
    party_id: party_id,
  };

  return (
    <div
      className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
      tabIndex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header canvas-header-bg ">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          {alert_summary}
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={handleCloseOffcanvas}
        ></button>
      </div>
      {loading ? (
        <div>
          <Skeleton count={5} />
        </div>
      ) : (
        <div className="offcanvas-body ">
          <div className="mobile-only-scroll">
            <div className="d-flex flex-row">
              <div className="p-2">
                <div className="fw-semibold fs-18">Company</div>
                <div className="text-muted fs-16">{party_name}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="p-2">
                <div className="fw-semibold fs-18">Customer Number:</div>
                <div className="text-muted fs-16">{customer_number}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="p-2">
                <div className="fw-semibold fs-18">Created At</div>
                <div className="text-muted fs-16">{created_at}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="p-2">
                <div className="fw-semibold fs-18">Signal Code</div>
                <div className="text-muted fs-16">{signal_code}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="p-2">
                <div className="fw-semibold fs-18">Category</div>
                <div className="text-muted fs-16">{alert_category}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="p-2">
                <div className="fw-semibold fs-18">Type</div>
                <div className="text-muted fs-16">{alert_type}</div>
              </div>
            </div>
            {signal_version ? (
              <div className="row">
                {JSON.stringify(alertData.alert_detail[0], null, 2)}
                <div></div>
              </div>
            ) : (
              <AlertDetails alert={alertData} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(OffCanvasAlert);
