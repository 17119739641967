import React from "react";
import _ from 'lodash';

import AlertTable from "./AlertTable";

class FinancialStatementAlert extends React.Component {
  convertKeytoFiled = key => {
    if (key && typeof key === "string") {
      let val = key.split("_").join(" ")
      return val;
    } else {
      return "";
    }
  };

  getBodyData = financialData => {
    let bodyData = [];

    financialData && _.isArray(financialData) && financialData.forEach(element => {
      let arr = [];
      let objKey = Object.keys(element);
      objKey.forEach(key => {
        arr.push({
          html: <span style={{ textTransform: "capitalize" }}>
            {this.convertKeytoFiled(key)}
          </span>
        });
        arr.push(element[key].financial_year);
        arr.push(element[key].previous_year_value);
        arr.push(element[key].current_year_value);
        arr.push(parseFloat(element[key].percentage).toFixed(2));
      });
      bodyData.push(arr);
    });

    return bodyData;
  };

  getAlertTableUI = () => {
    const { text, details } = this.props;
    const headerData = [
      {
        name: "Type"
      },
      {
        name: "Financial Year"
      },
      {
        name: "Previous Year Value"
      },
      {
        name: "Current Year Value"
      },
      {
        name: "Percentage %"
      }
    ];
    let uiArr = [];
    if (details && _.isArray(details)) {
      details.forEach(item => {
        let financialData = item;
        const bodyData = this.getBodyData(financialData);
        const extraOptions = {
          title: text
        };
        uiArr.push(
          <AlertTable
            headerData={headerData}
            bodyData={bodyData}
            extraOptions={extraOptions}
            footerData={[]}
          />
        );
      });
    }
    return uiArr;
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default FinancialStatementAlert;
