import React, { useState } from "react";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import _ from "lodash";

const InputField = (props) => {
  const {
    label,
    index,
    value,
    type,
    updateInfo,
    disabled,
    show_form_error,
    is_mandotory,
    custom_e_message,
    key_value,
    abr,
  } = props;



  const onchange_func = (k, e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      let reader = new FileReader();
      let file_obj = {};
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        file_obj.attachment = reader.result;
        file_obj.file_name = _.get(selectedFile, "name", "");
        updateInfo(k, file_obj);
      };
    }

    // if(type === 'file') {
    //   let base_64 = '';
    //   let file_obj = {
    //     file:"",
    //     file_name:""
    //   }
    //   updateInfo(k, )
    // } else {
    //   updateInfo(k, _.get(e, "target.value"))
    // }
  };

  return (
    <div key={index} className="row mt-3">
      <label className=" col-md-5 col-12 col-form-label">
        {`${label} ${is_mandotory ? "*" : ""}`}
      </label>
      <div className="col-md-7 col-12">
        <InputGroup>
          <InputGroupAddon addonType="prepend">{abr}</InputGroupAddon>
          <Input
            type={type}
            className="form-control"
            name={label}
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .csv"
            onChange={(e) => !disabled && onchange_func(key_value, e)}
            placeholder=""
            autoComplete="off"
            required={true}
            disabled={disabled}
          />
        </InputGroup>

        {show_form_error && !value && is_mandotory && (
          <p className="text-danger m-2">{`Please fill the field`}</p>
        )}
        {custom_e_message && (
          <p className="text-danger m-2">{custom_e_message}</p>
        )}
      </div>
    </div>
  );
};

export default InputField;
