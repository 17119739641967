import React from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import getIcons from "../../../../helpers/getIcons";
import allPaths from "../../../../route/allPaths";
import { connect } from "react-redux";


function FeatureList(props) {
const userManualLink = process.env.REACT_APP_USER_MANUAL;
  let myFeatures = {
    "features": [
      {
        feature: "Products",
        route: allPaths.PRODUCTS_ROUTE,
        icon: getIcons({icon: 'products'}),
        url: '/admin/products'
      },
      {
        feature: "Macro Indicators",
        route: allPaths.MACRO_INDICATOR,
        icon: getIcons({icon: 'macro_indicators'}),
        url: '/admin/macro_indicators'
      },
      {
        feature: "Portfolios",
        route: allPaths.PORTFOLIOS_LIST_ROUTE,
        icon: getIcons({icon: 'portfolios'}),
        url: "/admin/portfolios"
      },
      {
        feature: "Cases",
        route: allPaths.CMM_ROUTE,
        icon: getIcons({icon: 'feature_cases'}),
        url: "/cases"
      },
    ],
    "help": [{
      "feature": "Docs",
      "route": allPaths.DOCS_ROUTE,
      "icon": getIcons({icon: 'docs'}),
      "url": "/docs"
    }],

    "manual": [{
      "feature": "User Manual",
      "route": userManualLink,
      "icon": getIcons({icon: 'docs'}),
      "url": ""
    }]
  }

  if (props && props.party_id) {
    myFeatures.features.push(
      {
        feature: 'Business',
        route: allPaths.MY_BIZ_ROUTE,
        icon: getIcons({icon: 'business'}),
      }
    )
  }

  const is_widget_disabled = (url) => {
    let current_policy_method = _.get(props, `policy.${url}.method`, []);
    if (_.isArray(current_policy_method) && current_policy_method.includes("GET")) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className="p-1 ">
      <div className="row">
        <div className="ml-4">FEATURES</div>
      </div>
      <div className="row">
        {myFeatures.features.map((item, index) => {
          let is_disabled = is_widget_disabled(item.url);
          return (
            <div className="col-4" key={index}>
              <Button
                disabled={is_disabled}
                className="btn-icon-vertical btn-square btn-transition border-0 d-flex flex-column d-flex flex-column justify-content-center align-items-center"
                outline
                color="link"
                href={item.route}
              >
                <i className={`${item.icon} btn-icon-wrapper btn-icon-lg mb-3 text-primary mt-3 align-items-center justify-content-center`}></i>
                <span className="text-truncate">{item.feature}</span>
              </Button>
            </div>
          );
        })}
      </div>
      {/* <hr />
      <div className="row">
        <div className="ml-4">ADMIN</div>
      </div> */}
      {/* <div className="row">
        {myFeatures.help.map((item, index) => (
          <div className="col-4" key={index}>
            <Button
              className="btn-icon-vertical btn-square btn-transition border-0 d-flex flex-column d-flex flex-column justify-content-center align-items-center"
              outline
              color="link"
              href={item.route}
            >
              <i className={`${item.icon} btn-icon-wrapper btn-icon-lg mb-3 text-primary mt-3 align-items-center justify-content-center`}></i>
              <span className="text-truncate">{item.feature}</span>
            </Button>
          </div>
        ))}
      </div> */}
      {/* <div className="row">
        {myFeatures.manual.map((item, index) => (
          <div className="col-4" key={index}>
            <Button
              className="btn-icon-vertical btn-square btn-transition border-0 d-flex flex-column d-flex flex-column justify-content-center align-items-center"
              outline
              color="link"
              href={item.route}
              target="_blank"
            >
              <i className={`${item.icon} btn-icon-wrapper btn-icon-lg mb-3 text-primary mt-3 align-items-center justify-content-center`}></i>
              <span className="text-truncate">{item.feature}</span>
            </Button>
          </div>
        ))}
      </div> */}
      
    </div>
  );
}

const mapStateToProps = (state) => ({
  my_org: _.get(state, "my_profile.my_org"),
  policy: _.get(state, "my_profile.profileData.policy"),
  party_id: _.get(state.my_profile.my_org, "party_id"),
});

export default connect(mapStateToProps, null)(FeatureList);