import React from "react";
import _ from "lodash";

class CommonDirectorTable extends React.Component {
  getAlertTableUI = () => {
    let { details } = this.props;
    details = _.head(details);

    return (
      <table className="table table-bordered table-striped">
        <tbody>
          {details && details.charge_ref ? (
            <tr>
              <th>Charge Ref</th>
              <td>
                {()=>_.isString(details.charge_ref) ? details.charge_ref : "---"}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {details && details.current_company ? (
            <tr>
              <th>Current Company</th>
              <td>
                {()=>_.isString(details.current_company)
                  ? details.current_company
                  : "---"}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {details && details.new_company ? (
            <tr>
              <th>New Company</th>
              <td>
                {()=>_.isString(details.new_company) ? details.new_company : "---"}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {details &&
          _.isArray(details.common_directors) &&
          _.size(details.common_directors) > 0 ? (
            _.map(details.common_directors, (director, index) => {
              return index === Number(0) ? (
                <tr key={index}>
                  <th rowSpan={_.size(details.common_directors)}>
                    Common Director(s)
                  </th>
                  <td>{()=>_.isString(director) ? director : "---"}</td>
                </tr>
              ) : (
                <tr key={index}>
                  <td>{()=>_.isString(director) ? director : "---"}</td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default CommonDirectorTable;
