import React from "react";

import AlertTable from "./AlertTable";

class ExternalRatingDowngrade extends React.Component {
  getBodyData = () => {
    const { details } = this.props;
    let bodyData = [];
    details.forEach(obj => {
      let arr = [];
      arr.push(obj.agency);
      arr.push(obj.date);
      arr.push(obj.instrument);
      arr.push(obj.rating);
      arr.push(obj.status);
      bodyData.push(arr);
    });

    return bodyData;
  };

  getAlertTableUI = () => {
    const { text } = this.props;
    const headerData = [
      {
        name: "Agency"
      },
      {
        name: "Date"
      },
      {
        name: "Instrument"
      },
      {
        name: "Rating"
      },
      {
        name: "Status"
      }
    ];

    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text
    };
    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default ExternalRatingDowngrade;
