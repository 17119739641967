import React,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import OffCanvasCreateCase from "./offCanvas-Cases"
import OffCanvasAlert from './OffCanvas-Alert';
import OffCanvasCreateAlert from './offcanvas-create-alert';
import OffCanvasViewCase from './offCanvas-ViewCase';
const OffCanvasRenderer = (props) => {
  const {selected_party,alert,alert_summary,cb,caseDetails} =props

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offCanvas = queryParams.get("offCanvas");
  useEffect(() => {
    if (offCanvas) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [offCanvas]);

  const renderComponent = (offCanvas) => {
    switch (offCanvas) {
      case 'create-case':
        return <OffCanvasCreateCase selected_party={selected_party} alert={alert} alert_summary={alert_summary} cb={cb}/>;
      case 'create-alert':
        return <OffCanvasCreateAlert  cb={cb}/>;
        case 'view-case':
          return <OffCanvasViewCase  caseDetails={caseDetails}/>;
      default:
        return <OffCanvasAlert />;
    }
  };

  return (
    <div>
      {renderComponent(offCanvas)}
    </div>
  );
};

export default OffCanvasRenderer;
