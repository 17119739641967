import React, { useEffect, useState } from 'react'
import { FiCheckCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom'
import { FaSpinner } from "react-icons/fa";
import { connect } from 'react-redux';
import _ from 'lodash';

import allPaths from '../../../route/allPaths';
import service from '../../../utils/service';
import { trigger_current_eqaro_step } from '../../../actions/myprofile/action';

const InEligible = ({ is_dashboard_page, trigger_current_eqaro_step }) => {
  const [upload_btn_status, setUpStatus] = useState('');

  useEffect(() => {
    trigger_API_call();
  }, [])

  const trigger_API_call = async () => {
    let elegibility_API_URL = '/v1/eligibility_check';
    let params = {
      recheck: true
    }
    const res = await service('get', elegibility_API_URL, { params });
    let status = _.get(res, "data.status");
    if(status === 'eligible' && trigger_current_eqaro_step) {
       trigger_current_eqaro_step()
    } 
  }

  const history = useHistory()

  const handlePath = () => {
    setUpStatus('processing');
    if (!is_dashboard_page) {
      history.push(allPaths.HOME_ROUTE)
    } 
  }

  let isProcessing = upload_btn_status === 'processing' ? true : false;

  return (
    <div className='d-flex flex-column justify-content-center align-items-center p-4'>
      <div><FiCheckCircle className='text-success' size={40} /></div>
      <h3 className='text-center'>
        We regret that we are unable to offer you a Guarantee from Eqaro currently. <br />
        You can check back your eligibility after 90 days.
      </h3>
      <div>
        {!is_dashboard_page &&
          <button className='btn cw-btn-primary mt-3' disabled={isProcessing} onClick={() => !isProcessing && handlePath()}>
            {isProcessing ?
              <>
                <FaSpinner className="me-1 loading-icon" />
                processing
              </>
              :
              'Go to dashboard'
            }
          </button>}
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    trigger_current_eqaro_step: () => dispatch(trigger_current_eqaro_step())
  }
}

export default connect(null, mapDispatchToProps)(InEligible);