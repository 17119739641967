import React from "react";

const FormatSeven = ({ details }) => {
  if (!details || !Array.isArray(details) || details.length === 0) {
    return <p></p>;
  }

  const headers = Object.keys(details[0]);

  return (
    <div className="card">
      {headers.map((header) => (
        <div key={header} className="card-title">
          <h3>{header}</h3>
          <table className="mb-2 table table-striped responsive">
            <tbody>
              {details.map((detail, index) => (
                <tr key={index}>
                  {Object.keys(detail[header]).map((key) => (
                    <tr key={key}>
                      <td>{key}</td>
                      {Array.isArray(detail[header][key]) ? (
                        detail[header][key].map((item, i) => <td> {item}</td>)
                      ) : (
                        <td>{detail[header][key]}</td>
                      )}
                    </tr>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default FormatSeven;
