import React from 'react'
import {MdOutlineAddAlert} from 'react-icons/md'

const latest_alerts = [
    {
        name: "MUDRAI MULTI FACILITIES SERVICES PRIVATE LIMITED",
        no_of_alerts: 1,
    },
    {
        name: "ALERT EXPERT MANAGEMENT SERVICES INDIA PRIVATE LIMITED",
        no_of_alerts: 4,
    },
    {
        name: "TYRO AQUA-TECH PRIVATE LIMITED",
        no_of_alerts: 1,
    },
    {
        name: "MUDRAI MULTI FACILITIES SERVICES PRIVATE LIMITED",
        no_of_alerts: 1,
    },
    {
        name: "GODDRES PHARMACEUTICALS PRIVATE LIMITED",
        no_of_alerts: 3,
    },
    {
        name: "Designsense Software Technologies Private Limited",
        no_of_alerts: 2,
    },
    {
        name: "INTERNATIONAL BOOK HOUSE PRIVATE LIMITEDD",
        no_of_alerts: 2,
    },
    {
        name: "PRIYANSHU TELESERVICES PRIVATE LIMITED",
        no_of_alerts: 1,
    },
    {
        name: "TONGUESTUN FOOD NETWORK PRIVATE LIMITED",
        no_of_alerts: 2,
    },
    {
        name: "AJNASYS TECH SOLUTIONS PRIVATE LIMITED",
        no_of_alerts: 6,
    },

]

const RecentAlerts = () => {
    return (
        <>
            <h5 className='fs-18 fw-600 mb-3 fb fc-blue text-truncate'>Latest Alerts</h5>
                {latest_alerts.map((each,idx) => idx < 3 && <div className='d-flex justify-content-between m-0 p-0 mb-3 '>
                <div className='d-flex text-truncate'>
                <MdOutlineAddAlert className='icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg text-primary align-self-center' size={25} />
                   <a href='/' className='text-primary w-50 text-truncate ml-2 align-self-center'>
                        {each.name}
                    </a>
                </div>
                    <div className='text-primary text-no-wrap w-50 text-right align-self-center '>{each.no_of_alerts}</div>
                </div> )}
                {latest_alerts && latest_alerts.length > 3 && <div className='text-right'><a href='' >View More...</a></div>}
    </>

    )
}

export default RecentAlerts
