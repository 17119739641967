import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import CreateCaseModal from "./modal";

const CreateCase = (props) => {
  const [show_modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!show_modal);
  };

  const { cb, alert, label,alert_summary, selected_party,icon,className } = props;

  let current_party_obj = {};

  if(selected_party) {
    current_party_obj = {
      party_name: _.get(selected_party, "name"),
      party_id: _.get(selected_party, "party_id")
    }
  } else if(alert) {
    current_party_obj = {
      party_name: _.get(alert, "company_name"),
      party_id: _.get(alert, "ews_party_id")
    }
  }

  return (
    <>
      {icon?(
        <i className={icon}
        onClick={() => toggle()}
        ></i>
      ):(
        <button
        className={className?className:`btn-icon btn-sm btn-outline btn btn-outline-primary btn-block text-left`}
        onClick={() => toggle()}
      >
        {label || 'Create Case'}
      </button>
      )}
      {show_modal && (
        <CreateCaseModal
          alert_summary={alert_summary}
          cb={cb}
          alert={alert}
          closeModalFnc={toggle}
          className="border-0 "
          selected_party={current_party_obj}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  my_org: _.get(state, "my_profile.my_org"),
});

export default connect(mapStateToProps)(CreateCase);
