import React from "react";

const NotConfigured = () => {
  return (
    <div>
      <h5>New Alert Type! Configuration Not Defined.</h5>
    </div>
  );
};

export default NotConfigured;
