import React, { Fragment } from "react";
import cx from "classnames";
import OptionDropdown from "./Components/OptionDropdown";
import { connect } from "react-redux";
import _ from 'lodash';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { withRouter } from "react-router-dom";
import HeaderLogo from "../AppLogo";

import { setEnableSearchBarOnHeader } from "../../reducers/ThemeOptions";
import SearchBox from "./Components/SearchBox";
import Support from "./Components/Support";
// import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox/UserBox";
import AppMobileMenu from "../AppMobileMenu";
import detect_mobile from "../../utils/detect_mobile";
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";
class Header extends React.Component {
  render() {
    let {
      headerBackgroundColor,
      enableHeaderShadow,
      openInput,
      setEnableSearchBarOnHeader,
      page_config,
      searchBar
    } = this.props;

    let show_search_bar = _.get(page_config, "header.show_search_bar", false);
    let show_support = _.get(page_config, "header.show_support", false);
    let show_feature_icon = _.get(page_config, "header.show_feature_icon", false);

    let checkPath = () => {
      let location = _.get(this.props, "location.pathname");
      switch (true) {
        case location.includes("alert"):
          return 'Alerts';
        case location.includes("parties"):
          return 'Parties';
        case location.includes("portfolios"):
          return 'Portfolios';
        case location.includes("cases"):
          return 'Cases';
        case location.includes("forms"):
          return 'Forms';
        case location.includes("products"):
          return 'Products';
        default:
          return null;
      }
    }
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          }, `${detect_mobile() ? "bg-lightBlue" : ""}`)}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          {/*
           <div className={`row w-100 ${detect_mobile() ? "bg-lightBlue" : ""}`} >
            <div className="col">
              <div className=" d-flex flex-row align-items-center m-3">
                {/* <AppMobileMenu /> */}
          {/* <div className="d-none d-md-block"> {!openInput && <HeaderLogo />}</div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center mt-2">
                <div className="d-block d-md-none ">
                  {searchBar && searchBar ? 
                 <div className="mt-2 w-100 overflow-auto">
                   <SearchBox openInput={searchBar} setEnableSearchBarOnHeader={setEnableSearchBarOnHeader} /> 
                 </div>:
                  (!openInput && <HeaderLogo />
                  )}  
                </div>
              </div>
                </div>*/}


          {!openInput && <HeaderLogo />}
          <div className="d-block d-md-none w-100">
            <div className="d-flex justify-content-end ">
              <div className="heading-mobile-header">{checkPath()}</div>
            </div>
          </div>
          <div className="d-flex justify-content-end w-100 align-items-center">
             <div id="cwi_header_bar__search" className="flex-grow-1 mx-3">
            {show_search_bar && <SearchBox openInput={openInput} setEnableSearchBarOnHeader={setEnableSearchBarOnHeader} />}
          </div>
            <div className="d-none d-md-block">
              {show_support && !openInput && <Support />}
              <div className="m-2"></div>
            </div>
            <div className="d-none d-md-block ">
              {/* {!openInput && (
                <div className="app-header-right d-flex align-items-center h-100">
                  {show_feature_icon &&
                    <div className="d-none d-lg-block me-2">
                      <OptionDropdown />
                    </div>
                  }
                </div>
              )} */}
              <div className="m-2"></div>
            </div>
            <UserBox log_out={this.log_out} />
          </div>

          {/* <div id="cwi_header_bar__search" className="flex-grow-1">
            {show_search_bar && <SearchBox openInput={openInput} setEnableSearchBarOnHeader={setEnableSearchBarOnHeader} />}
          </div> */}
          {/* <div>
            {show_support && !openInput && <Support />}
          </div> */}
          {/* {!openInput && (
            <div className="app-header-right d-flex align-items-center">
              {show_feature_icon &&
                <div className="d-none d-lg-block">
                  <OptionDropdown />
                </div>
              }
              <UserBox log_out={this.log_out} />
            </div>
          )} */}
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  page_config: _.get(state, "my_profile.page_config", {}),
  searchBar: _.get(state, "globalReducer.searchbar")
});

const mapDispatchToProps = (dispatch) => {
  return {
    setEnableSearchBarOnHeader: (value) =>
      dispatch(setEnableSearchBarOnHeader(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
