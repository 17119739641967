import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import OrderReportFromDashboard from '../order_report_dashboard';
import allPaths from '../../../../../route/allPaths';

const QuickActions = (props) => {

  const [show_modal, setModal] = useState('')

  const { history } = props;


  const trigger_action = (obj) => {
    setModal('')
    let action = obj.action
    switch(action) {
      case 'view-portfolios':
        history.push(allPaths.PORTFOLIOS_LIST_ROUTE)
        break;

      case 'view-cases':
        history.push(allPaths.CMM_ROUTE)
        break;

      case 'view-products':
        history.push(allPaths.PRODUCTS_ROUTE)
        break;

      case 'order-a-report':
        setModal('order-a-report');
        break;
      
      default:
        break;

    }
  }

  let arr = [
    {
      text: 'View Portfolios',
      action: 'view-portfolios'
    },
    {
      text: 'View Cases',
      action: 'view-cases'
    },
    {
      text: 'View Products',
      action: 'view-products'
    },
    // {
    //   text: 'Order a Report',
    //   action: 'order-a-report'
    // }
  ]

  const closeModal = () => {
    setModal('')
  }

  return (
    <div className='row'>
      { show_modal === 'order-a-report' &&
        <OrderReportFromDashboard close={closeModal} show={true}/>
      }
      {arr.map((a, index) => (
        <div className='col-md-3 col-6 mb-2' key={index}>
          <div className='card-shadow-primary border card card-body border-primary p-2 cursor-p h-100' onClick={() => trigger_action(a)}>
            <div className='card-title mb-0 text-center'>
              {a.text}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default withRouter(QuickActions);