import React from "react";
import _ from "lodash";

class MediaAlert extends React.Component {
  getAlertUI = () => {
    const { details } = this.props;
    let UICom = "";
    if (details) {
      if (details.length === 1) {
        let detail = details[0];
        let summary = detail.news_summary || detail.summary;
        let link = detail.news_link || detail.link;
        let news_detail = detail.news_detail || detail.detail;
        UICom = (
          <div className="card">
            <div className="card-body">
              <h5>
                <a href={link} target="_blank">
                  {news_detail}
                </a>
              </h5>
              <p className="mb-0">
                {summary && summary.length > 0
                  ? _.map(summary, (d, k) => {
                      return (
                        <span key={k} dangerouslySetInnerHTML={{ __html: d }} />
                      );
                    })
                  : "---"}
              </p>
            </div>
          </div>
        );
      }
    }
    return UICom;
  };

  render() {
    return <>{this.getAlertUI()}</>;
  }
}

export default MediaAlert;
