import React from "react";
import _ from "lodash";

class BranchTooFarAlert extends React.Component {
  getAlertTableUI = () => {
    let { details } = this.props;
    details = _.head(details);

    const account_number = _.head(_.at(details, "account_number"));
    const account_open_date = _.head(_.at(details, "account_open_date"));
    const mailing_address_1 = _.head(_.at(details, "mailing_address_1"));
    const mailing_address_2 = _.head(_.at(details, "mailing_address_2"));
    const mailing_city_name = _.head(_.at(details, "mailing_city_name"));
    const mailing_postal_code = _.head(_.at(details, "mailing_postal_code"));
    const branch_name = _.head(_.at(details, "branch_name"));
    const branch_address_1 = _.head(
      _.at(details, "postal_info.street_address_1")
    );
    const branch_address_2 = _.head(
      _.at(details, "postal_info.street_address_2")
    );
    const branch_city_name = _.head(
      _.at(details, "postal_info.street_city_name")
    );
    const branch_state_name = _.head(
      _.at(details, "postal_info.street_state_name")
    );
    const branch_postal_code = _.head(
      _.at(details, "postal_info.street_postal_code")
    );
    const distance = _.head(_.at(details, "distance"));

    return (
      <table className="table table-bordered table-striped">
        <tbody>
          {account_number && _.isString(account_number) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("account_number", "_"), " "))}
              </th>
              <td>{account_number}</td>
            </tr>
          ) : (
            <></>
          )}
          {account_open_date && _.isString(account_open_date) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("account_open_date", "_"), " "))}
              </th>
              <td>{account_open_date}</td>
            </tr>
          ) : (
            <></>
          )}
          {mailing_address_1 && _.isString(mailing_address_1) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("mailing_address_1", "_"), " "))}
              </th>
              <td>{mailing_address_1}</td>
            </tr>
          ) : (
            <></>
          )}
          {mailing_address_2 && _.isString(mailing_address_2) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("mailing_address_2", "_"), " "))}
              </th>
              <td>{mailing_address_2}</td>
            </tr>
          ) : (
            <></>
          )}
          {mailing_city_name && _.isString(mailing_city_name) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("mailing_city_name", "_"), " "))}
              </th>
              <td>{mailing_city_name}</td>
            </tr>
          ) : (
            <></>
          )}
          {mailing_postal_code && _.isString(mailing_postal_code) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("mailing_postal_code", "_"), " "))}
              </th>
              <td>{mailing_postal_code}</td>
            </tr>
          ) : (
            <></>
          )}
          {branch_name && _.isString(branch_name) ? (
            <tr>
              <th>{_.capitalize(_.join(_.split("branch_name", "_"), " "))}</th>
              <td>{branch_name}</td>
            </tr>
          ) : (
            <></>
          )}
          {branch_address_1 && _.isString(branch_address_1) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("branch_address_1", "_"), " "))}
              </th>
              <td>{branch_address_1}</td>
            </tr>
          ) : (
            <></>
          )}
          {branch_address_2 && _.isString(branch_address_2) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split(branch_address_2, "_"), " "))}
              </th>
              <td>{branch_address_2}</td>
            </tr>
          ) : (
            <></>
          )}
          {branch_city_name && _.isString(branch_city_name) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("branch_city_name", "_"), " "))}
              </th>
              <td>{branch_city_name}</td>
            </tr>
          ) : (
            <></>
          )}
          {branch_state_name && _.isString(branch_state_name) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("branch_state_name", "_"), " "))}
              </th>
              <td>{branch_state_name}</td>
            </tr>
          ) : (
            <></>
          )}
          {branch_postal_code && _.isString(branch_postal_code) ? (
            <tr>
              <th>
                {_.capitalize(_.join(_.split("branch_postal_code", "_"), " "))}
              </th>
              <td>{branch_postal_code}</td>
            </tr>
          ) : (
            <></>
          )}
          {distance && !_.isNaN(distance) ? (
            <tr>
              <th>{_.capitalize(_.join(_.split("distance", "_"), " "))}</th>
              <td>{_.round(_.toNumber(distance), 2)} km</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default BranchTooFarAlert;
