import React from 'react';
import _ from 'lodash';

const RadioField = ({ label, index, options, value, updateInfo, disabled, 
  show_form_error, is_mandotory, custom_e_message, key }) => {

  const get_options_ui = () => {
    let ui_arr = [];

    if (options && _.isArray(options)) {
      options.forEach((item, op_index) => {
        let label = _.get(item, "label");
        let op_value = _.get(item, "key");
        ui_arr.push(
          <div className="me-3">
            <input
              key={op_index}
              type="radio"
              name={key}
              value={op_value}
              disabled={disabled}
              checked={value === op_value}
              onChange={(e) =>
                !disabled && updateInfo && updateInfo(key, e.target.value)
              }
            />
            <label for={label} className="m-0 p-1">
              {label}
            </label>
          </div>
        )
      })
    }
  }
  return (
    <div className="row mt-3" key={index}>
      <label for={key} className="col-md-5 col-12  col-form-label">
        {label}
      </label>
      <div className="col-md-7 col-12">
        {get_options_ui()}
        {show_form_error && !value && is_mandotory && (
          <p className="text-danger m-2">{`Please select the field`}</p>
        )}
        {custom_e_message && (
          <p className="text-danger m-2">{custom_e_message}</p>
        )}
      </div>
    </div>
  )
}

export default RadioField;