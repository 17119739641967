import React from "react";
import Vdobutton from "./Vdobutton";

const get_cdn_location_for_video = () => {
  return `/cdn/assets/videos`;
};

const get_cdn_location_for_image = () => {
  return `/cdn/assets/images`;
};

function PlatformTutorial() {
  const data = [
    {
      name: "Manage Risk",
      thumbnailTitle: "Manage Risk",
      mixPanelEventName: "manage_risk",
      link: `${get_cdn_location_for_video()}/Manage-Risks.mp4`,
      mobile_link: `${get_cdn_location_for_video()}/Manage-Risks-mobile.mp4`,
      class: "btn-block fs-14 fw-500 mb-2",
      thumbnails: `${get_cdn_location_for_image()}/Manage-Risks.png`,
      duration: "0.46",
    },
    {
      name: "Regulatory Filings",
      thumbnailTitle: "Regulatory Filings",
      mixPanelEventName: "regulatory_filings",
      link: `${get_cdn_location_for_video()}/Regulatory-Filing.mp4`,
      mobile_link: `${get_cdn_location_for_video()}/Regulatory-Filing-mobile.mp4`,
      class: "btn-block fs-14 fw-500 mb-2",
      thumbnails: `${get_cdn_location_for_image()}/Regulatory-Filing.png`,
      duration: "0.56",
    },
    {
      name: "Generate Reports",
      thumbnailTitle: "Generate Reports",
      mixPanelEventName: "generate_reports",
      link: `${get_cdn_location_for_video()}/Generate-Report.mp4`,
      mobile_link: `${get_cdn_location_for_video()}/Generate-Report-mobile.mp4`,
      class: "btn-block fs-14 fw-500 ",
      thumbnails: `${get_cdn_location_for_image()}/Generate-Report.png`,
      duration: "0.47",
    },
    {
      name: "Explore Data",
      thumbnailTitle: "Explore Data",
      mixPanelEventName: "explore_data",
      link: `${get_cdn_location_for_video()}/Explore-Data.mp4`,
      mobile_link: `${get_cdn_location_for_video()}/Explore-Data-mobile.mp4`,
      class: "btn-block fs-14 fw-500 mb-2",
      thumbnails: `${get_cdn_location_for_image()}/Explore-Data.png`,
      duration: "0.44",
    },
    {
      name: "Discover Companies",
      thumbnailTitle: "Discover Companies",
      mixPanelEventName: "discover_companies",
      link: `${get_cdn_location_for_video()}/Discover-Companies.mp4`,
      mobile_link: `${get_cdn_location_for_video()}/Discover-Companies-mobile.mp4`,
      class: "btn-block fs-14 fw-500 mb-2",
      thumbnails: `${get_cdn_location_for_image()}/Discover-Companies.png`,
      duration: "0.33",
    },
  ];

  const getVideoUI = () => {
    return data.map((item, index) => (
      <Vdobutton
        {...item}
        key={index}
        className={item.class}
        data={data}
        activeIndex={index}
      />
    ));
  };

  return (
    <>
    {getVideoUI()}
    </>
  )
}

export default PlatformTutorial;
