import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from "reactstrap";
import allPaths from '../../../../../route/allPaths';

function MCAStatusDashboard() {
  const history = useHistory()
  const handlePush = () => {
    history.push(allPaths.MCA_SUB_ROUTE)
  }

  return (
    <div className='h-100'>
      <div className="mb-2">
        <div className="d-flex">
          <div className="icon-22 me-2 ">
            <img
              src={`/cdn/assets/icons/product/verify_business.svg`}
              alt=""
            />
          </div>
          <div className='w-100 d-flex'>
            <div>
              <div className='d-flex justify-content-between'>
                <div className="fs-18 fw-600 fc-blue">MCA Company Status</div>
              </div>
              <div className="fs-12 fw-400 fc-black ">
                Check company status with the RoC
              </div>
            </div>
            <div>
              <Button color="" active className="btn-pill btn-gradient-warning">
                <span>Premium</span>  
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='fs-16 ' style={{ padding: '16px' }} >Quickly Check the status of MCA registered companies.You can verify the status of partners, customers, and vendors before doing business with them. you can find struck-off companies and companies under liquidation</div>
      <button
        type="button"
        className="mt-2 cw-btn cw-btn-secondary"
        onClick={handlePush}
      >
        Check Status
      </button>
    </div>
  )
}

export default MCAStatusDashboard