import React, { Component } from "react";
import moment from "moment";

import AlertTable from "./AlertTable";

class ChargeAlert extends Component {
  getDateFormat = date => {
    let format = "DD-MM-YYYY";
    return date ? moment(date, "YYYY-MM-DD").format(format) : "---";
  };

  convertKeytoFiled = key => {
    if (key && typeof key === "string") {
      return key.split("_").join(" ");
    } else {
      return "";
    }
  };

  getBodyData = () => {
    let bodyData = [];
    const { details } = this.props;
    details.forEach(element => {
      if (element.charge_id) {
        bodyData.push([this.convertKeytoFiled("charge_id"), element.charge_id]);
      }
      if (element.date_of_action) {
        bodyData.push([
          this.convertKeytoFiled("date_of_action"),
          this.getDateFormat(element.date_of_action)
        ]);
      }
      if (element.charge_holder_name) {
        bodyData.push([
          this.convertKeytoFiled("charge_holder_name"),
          element.charge_holder_name
        ]);
      }
      if (element.charge_amount) {
        bodyData.push([
          this.convertKeytoFiled("charge_amount"),
          element.charge_amount
        ]);
      }
    });
    return bodyData;
  };

  getAlertTableUI = () => {
    const { text, summary } = this.props;
    const headerData = [
      {
        name: "Type"
      },
      {
        name: summary ? summary : "---"
      }
    ];
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text
    };
    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <div>{this.getAlertTableUI()}</div>;
  }
}

export default ChargeAlert;
