import React from "react";

const BusinessWidget = ({ item }) => {
  return (
    <div className="p-4">
      <div className="me-0 mb-2">
        <img
          src={item.img}
          alt="Avatar"
          width={item.img_width ? item.img_width : "auto"}
          height={"90px"}
        />
      </div>
      <h6 className="menu-header-title mb-2">{item.title}</h6>
      <div className="pr-2 fs-14 text-justify">{item.summary}</div>
      {item.buttonFunc && (
        <div className="d-inline-block mt-3">
          <button className="mb-2 me-2 btn-transition btn btn-outline-primary" onClick={item.buttonFunc}>
            {item.title}
          </button>
        </div>
      )}
    </div>
  );
};

export default BusinessWidget;
