import React, { Component } from "react";
import moment from "moment";

import AlertTable from "./AlertTable";

class CaseFiledAlert extends Component {
  getDateFormat = date => {
    let format = "DD-MM-YYYY";
    return date ? moment(date, "YYYY-MM-DD").format(format) : "---";
  };

  getBodyData = () => {
    const { details } = this.props;
    let arr = [];
    let courtDetails = details[0];

    if (courtDetails.target_name) {
      arr.push(["Case Against", courtDetails.target_name]);
    }

    if (courtDetails.bench) {
      arr.push(["Bench", courtDetails.bench]);
    }

    if (courtDetails.case_no) {
      arr.push(["Case Number", courtDetails.case_no]);
    }

    if (courtDetails.section) {
      arr.push(["Under Section", courtDetails.section]);
    }

    if (courtDetails.date) {
      arr.push(["Date", courtDetails.date]);
    }

    if (courtDetails.case_status) {
      arr.push([
        "Case Status",
        courtDetails.case_status.case_status
          ? courtDetails.case_status.case_status
          : "---"
      ]);
    }

    if (courtDetails.pet && courtDetails.pet.length > 0) {
      let headerArr = ["Advocate", "Name"];
      let bodyArr = [
        {
          type: "",
          key: "advocate"
        },
        {
          type: "",
          key: "name"
        }
      ];
      arr.push([
        "Petitioner(s)",
        this.getRowInsideTableUI(headerArr, courtDetails.pet, bodyArr)
      ]);
    }

    if (courtDetails.res && courtDetails.res.length > 0) {
      let headerArr = ["Advocate", "Name"];
      let bodyArr = [
        {
          type: "",
          key: "advocate"
        },
        {
          type: "",
          key: "name"
        }
      ];
      arr.push([
        "Respondent(s)",
        this.getRowInsideTableUI(headerArr, courtDetails.res, bodyArr)
      ]);
    }

    if (
      courtDetails.orders_judgements &&
      courtDetails.orders_judgements.length > 0
    ) {
      let headerArr = ["Date", "Type", "Title", "Description", "Link"];
      let bodyArr = [
        {
          type: "date",
          key: "order_date"
        },
        {
          type: "",
          key: "order_type"
        },
        {
          type: "",
          key: "title"
        },
        {
          type: "",
          key: "order_description"
        },
        {
          type: "link",
          key: "order_details_link"
        }
      ];
      arr.push([
        "Orders/Judgements",
        this.getRowInsideTableUI(
          headerArr,
          courtDetails.orders_judgements,
          bodyArr
        )
      ]);
    }
    return arr;
  };

  getRowInsideTableUI = (headerArr, bodyArr, bodyKeyArr) => {
    let headerData = [];
    headerArr.forEach(element => {
      let obj = {
        name: element
      };
      headerData.push(obj);
    });
    let bodyData = [];

    bodyArr.forEach(row => {
      let arr = [];
      bodyKeyArr.forEach(ele => {
        arr.push(this.getCellUI(ele.type, row[ele.key]));
      });
      bodyData.push(arr);
    });

    return (
      <AlertTable headerData={headerData} bodyData={bodyData} footerData={[]} />
    );
  };

  getCellUI = (type, data) => {
    let UI = data ? data : "---";
    switch (type) {
      case "date":
        UI = this.getDateFormat(data);
        break;
      case "link":
        UI = data ? (
          <a href={data} target="_blank">
            Click here
          </a>
        ) : (
          "---"
        );
        break;
      default:
        UI = data ? data : "---";
        break;
    }
    return UI;
  };

  getAlertTableUI = () => {
    const { text } = this.props;
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text
    };
    return (
      <AlertTable
        headerData={[]}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default CaseFiledAlert;
