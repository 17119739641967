import React, { Component } from "react";
import SanitizedHTML from "react-sanitized-html";

import AlertTable from "./AlertTable";
import _ from "lodash";

class SingleKeyTable extends Component {
  convertKeytoFiled = (key) => {
    if (key && typeof key === "string") {
      return _.startCase((key.split("_").join(" ")));
    } else {
      return "";
    }
  };

  getLink = (lable, link) => {
    return <a href={link}>{lable}</a>;
  };

  getBodyData = () => {
    const { details, keyPairs } = this.props;
    let body = [];
    if (details && keyPairs) {
      if (details.length > 1) {
        details.forEach((obj) => {
          let row = [];
          keyPairs.forEach((ele) => {
            let colBody = "";
            if (ele.type && ele.type === "link") {
              colBody = this.getLink(obj[ele.key], obj[ele.key]);
            } else if (ele.type && ele.type === "html") {
              colBody = (
                <div className="text-center">
                  <SanitizedHTML html={obj[ele.key]} />
                </div>
              );
            } else {
              colBody = obj[ele.key];
            }
            row.push(colBody);
          });
          body.push(row);
        });
      } else if (details.length === 1) {
        keyPairs.forEach((ele) => {
          let rowBody = "";
          if (ele.type && ele.type === "link") {
            rowBody = this.getLink(details[0][ele.key], details[0][ele.key]);
          } else if (ele.type && ele.type === "html") {
            const blob = _.get(details, `0.${ele.key}`, "");
            rowBody = (
              <SanitizedHTML
                html={_.isArray(blob) ? _.join(blob, " ") : blob}
              />
            );
          } else {
            rowBody = details[0][ele.key];
          }
          body.push([
            ele.label ? ele.label : this.convertKeytoFiled(ele.key),
            rowBody,
          ]);
        });
      }
    }
    return body;
  };

  getAlertTableUI = () => {
    const { text, details, keyPairs } = this.props;
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text,
    };

    let headerData = [];

    if (details && details.length > 1 && keyPairs) {
      keyPairs.forEach((ele) => {
        let obj = {
          name: ele.label ? ele.label : this.convertKeytoFiled(ele.key),
        };
        headerData.push(obj);
      });
    }
    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <div>{this.getAlertTableUI()}</div>;
  }
}

export default SingleKeyTable;
