import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Input,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import Select from "react-select-new";
import { toast } from "react-toastify";

import FormArr from "../../components/form-arr";
import service from "../../utils/service";
import Loader from "react-loaders";

const CreateCaseModal = (props) => {
  const {
    className,
    closeModalFnc,
    cb,
    alert,
    alert_summary,
    selected_party
  } = props;

  const [activeTab, setActiveTab] = useState("tab1");
  const [is_recurring_cases, setRecurringCases] = useState(false);

  const [case_type_options, setCaseType] = useState({});

  const [dates, setDates] = useState([]);
  const [frequency, setFrequncy] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [caseLoader,setCaseLoader]=useState("")

  const organization_id = _.get(props, "my_org.organization_id");


  useEffect(() => {
    trigger_API();
  }, []);

  const trigger_API = async () => {
    let API_URL = "v1/cases/case_types";
    let params = {
      organization_id: organization_id,
    };
    let res = await service("get", API_URL, params);
    if (res) {
      setCaseType(res)
    }
  };

  const get_case_type_arr = () => {
    let options = [];
    if (case_type_options && _.isArray(case_type_options)) {
      case_type_options.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name,
        })
      })
    }
    return options;
  }

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFrequncy(selectedOption.value);
    } else {
      setFrequncy(null)
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleAddDate = () => {
    setDates([...dates, selectedDate]);
    setSelectedDate("");
  };

  const handleRemoveDate = (indexToRemove) => {
    const newDates = [...dates];
    newDates.splice(indexToRemove, 1);
    setDates(newDates);
  };

  const trigger_postAPI = async (obj) => {
    setCaseLoader("loading")
    let party_name = _.get(obj, "party.name", "");
    let party_id = _.get(obj, "party.party_id", "");
    let loan_account = _.get(obj, "loan_account");
    let case_summary = _.get(obj, "case_summary");
    let case_description = _.get(obj, "case_description");
    let case_type_id = _.get(obj, "case_type.value");
    let attachment = _.get(obj, "attachment")
    let s_party_name = _.get(selected_party, "party_name");
    let s_party_id = _.get(selected_party, "party_id");

    if (s_party_name && s_party_id) {
      party_name = _.get(selected_party, "party_name");
      party_id = _.get(selected_party, "party_id");
    }

    if (party_id && party_name && case_type_id && case_summary && case_description && organization_id) {
      let payload = {
        party: party_id,
        party_name,
        subject: case_summary,
        issue: case_description,
        organization_id: organization_id,
        case_type: case_type_id,
        attachment: _.get(attachment, "attachment"),
        file_name: _.get(attachment, "file_name"),
      }
      if (loan_account) {
        payload.loan_account = loan_account
      }
      if (is_recurring_cases) {
        payload.recurringData = {};
  
        if (frequency) {
          payload.recurringData.frequency = frequency;
        }
  
        if (dates ) {
          payload.recurringData.absoluteDates = dates;
        }
      }

      if (alert) {
        payload.alert = alert
        payload.alert_id = _.get(alert, "_id");
      }

      let CREATE_CASE_API_URL = 'v1/cases';
      let res = await service('post', CREATE_CASE_API_URL, { data: payload });
      if (res.success) {
        toast.success("Case Created Successfully");
        setCaseLoader("done")
        toggle();
        cb && cb();
      } else {
        toast.error('Error occured while creating a case')
        setCaseLoader("error")
      }
    }
  };

  const goBackButtonAction = () => {
    toggle();
  };

  const toggle = () => {
    closeModalFnc && closeModalFnc();
  };

  const get_frequency_ui = () => {
    const FrequencyOptions = [
      { value: "Daily", label: "Daily" },
      { value: "Weekly", label: "Weekly" },
      { value: "Monthly", label: "Monthly" },
      { value: "Quarterly", label: "Quarterly" },
      { value: "Yearly", label: "Yearly" },
    ];

    return (
      <Card>
        <CardBody>
          <Select
            placeholder="Frequency"
            options={FrequencyOptions}
            onChange={handleSelectChange}
            required
          />
        </CardBody>
        {get_selected_dates_ui()}
      </Card>
    )
  }

  const get_custom_dates_ui = () => {
    return (
      <Card>
        <CardHeader>Date Of Recurrence</CardHeader>
        <CardBody>
          <Input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
          <Button
            className="mt-3"
            disabled={!selectedDate}
            onClick={() => selectedDate && handleAddDate()}
          >
            Add
          </Button>
        </CardBody>
        {get_selected_dates_ui()}
      </Card>
    )
  }

  const delete_recurring_func = (key, index) => {
    if (key === 'frequency') {
      handleSelectChange()
    } else if (key === 'absolute-dates' && index >= 0) {
      handleRemoveDate(index)
    }
  }

  const get_bagdge_ui = (label, key, index) => {
    return (
      <button className="mb-2 me-2 btn btn-info" key={index || label}>
        <span className="fs-12">{label}</span>
        <span className="badge text-bg-pill text-bg-light" onClick={() => delete_recurring_func(key, index)}>
          <i className="fa fa-close" key={index}></i>
        </span>
      </button>
    )
  }

  const get_selected_dates_ui = () => {
    return (
      <CardFooter className="flex-column">
        <div className="mb-2">
          Periodicity of Recurrence:
        </div>
        <div>
          {frequency && get_bagdge_ui(frequency, 'frequency')}
          {dates.map((item, index) => (
            get_bagdge_ui(item, 'absolute-dates', index)
          ))}
        </div>
      </CardFooter>
    )
  }

  const getTabs = () => {
    let ui = (
      <div className="my-tabs">
        <Nav tabs className="my-tabs-nav">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "tab1" })}
              onClick={() => {
                toggleTab("tab1");
              }}
            >
              Frequency
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "tab2" })}
              onClick={() => {
                toggleTab("tab2");
              }}
            >
              Dates
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="my-tabs-content">
          <TabPane tabId="tab1">
            {get_frequency_ui()}
          </TabPane>

          <TabPane tabId="tab2">
            {get_custom_dates_ui()}
          </TabPane>
        </TabContent>
      </div>
    );
    return ui;
  };

  const get_recurring_case_ui = () => {
    return (
      <div className="row mt-3" >
        <label className="col-md-5 col-12 col-form-label ">
          Recurring Cases
        </label>
        <div className="col-md-7 col-12 mt-2">
          <div className="">
            <input
              type="checkbox"
              checked={is_recurring_cases}
              onChange={() => setRecurringCases(!is_recurring_cases)}
            />
          </div>
        </div>
      </div>
    )
  }

  const getForm = () => {

    let search_arr = [];
    let defaultValueObj = {};

    if (selected_party && selected_party.party_id) {
      defaultValueObj = {
        ...defaultValueObj,
        party: _.get(selected_party, "party_name"),
      }
      search_arr.push(
        {
          label: "Party Name",
          key: "party",
          mandatory: true,
          disabled: true,
          component: "input",
        },
      )
    } else {
      search_arr.push(
        {
          label: "Party Name",
          key: "party",
          mandatory: true,
          component: "search",
        }
      )
    }
    if (alert) {
      defaultValueObj = {
        ...defaultValueObj,
        case_summary: _.get(alert, "alert_summary")
      }
    }
    const form_arr = [
      {
        label: "Loan Account(optional)",
        key: "loan_account",
        type: "text",
        component: "input",
      },
      {
        label: "Select Case Type",
        key: "case_type",
        component: "dropdown",
        mandatory: true,
        option: get_case_type_arr(),
      },
      {
        label: "Case Summary",
        key: "case_summary",
        mandatory: true,
        component: "input",
        type: "text",
        value: alert_summary,
      },
      {
        label: "Case Description",
        key: "case_description",
        mandatory: true,
        component: "ck_editor",
        type: "text",
      },
      {
        label: "Attachment",
        key: "attachment",
        component: "upload_file",
        type: "file",
      }
    ];
    let action_buttons = [
      {
        label: 'Close',
        class: 'cw-btn cw-btn-secondary mt-2',
        action: goBackButtonAction
      },
      {
        label: 'Create',
        class: 'cw-btn ml-3 mt-2',
        action: trigger_postAPI,
        type: 'submit-form'
      }
    ]

    return (
      <FormArr
        form_fields={[...search_arr, ...form_arr]}
        action_buttons={action_buttons}
        defaultValueObj={defaultValueObj}
      >
        {get_recurring_case_ui()}
        {is_recurring_cases && getTabs()}
      </FormArr>
    )
  }

  return (
    <Modal isOpen={true} toggle={toggle} className={className} size="lg">
      <ModalHeader toggle={toggle}>Create Case</ModalHeader>
      <ModalBody>
        {caseLoader==="loading"?<div className="text-center"><Loader type="line-scale"/></div>:getForm()}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  my_org: _.get(state, "my_profile.my_org"),
});

export default connect(mapStateToProps)(CreateCaseModal);
