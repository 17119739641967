import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import _ from 'lodash';

const AppHelmet = (props) => {
  const { page_config } = props;
  let title = _.get(page_config, "common.title_app", "Default Title");
  let fav_icon =  _.get(page_config, "common.fav_icon_app", "default-favicon.ico");
  let FAV_URL = `/cdn/${fav_icon}`;

  return (
    <Helmet>
      <link rel="shortcut icon" href={FAV_URL} sizes="32x32"/>
      <title>{title}</title>
      <meta 
  httpEquiv="Content-Security-Policy" 
  content="
    default-src *; 
    script-src * 'unsafe-inline' 'unsafe-eval'; 
    style-src * 'unsafe-inline'; 
    img-src * data:; 
    connect-src *; 
    font-src *; 
    object-src *;
  "
/>

    </Helmet>
  );
}

const mapStateToProps = (state) => {
  return {
    page_config: _.get(state, "my_profile.page_config", {})
  }
}

export default connect(mapStateToProps)(AppHelmet);
