import axios from "axios";
import allPaths from "../route/allPaths";
import _ from 'lodash'

import { addRedirectURL, getToken, removeToken } from './getToken'

const BASE_URL = localStorage.getItem('API_URL');
let instance = axios.create({
  baseURL: BASE_URL,
});

/**Parse Body Func for checking the status code */
const parseBody = (res) => {
  if (res && res.status === 200 || res.status === 400 || res.status === 403) {
    return res.data;
  } else {
    return parseError(res);
  }
};

/**Error Func for to make the error response*/
const parseError = (res) => {
  if (res) {
    if (res.status === 401) {
      removeToken();
      addRedirectURL();
      window.location.replace(allPaths.ERROR_ROUTE);
    } 
  }
  return res;
};

/**Parse Config Func for the Common Config to send All API */
const parseConfig = (config) => {
  let token = getToken();
  let configuration = config;
  configuration.headers.Authorization = token;
  if(!token) {
    removeToken();
    addRedirectURL();
    window.location.reload()
  }
  return configuration;
};

/**Request API*/
instance.interceptors.request.use(
  (config) => parseConfig(config)
  // (error) => Promise.reject(error)
);

/**Response API */
instance.interceptors.response.use(
  (response) => {
    return parseBody(response)
  },
  (error) => {
    let response = _.get(error,"response",'')
    if (response.status >= 500) {
    //   let HTMLComp = (<div className="w-100">
    //   <h5>API Error</h5>
    //   <div>Status Code: {status}</div>
    //   <div className="text-break">API URL: {url}</div>
    //   <div className="text-break">
    //     Action: Please reach out to your company’s IT Administrator for more
    //     information/resolution
    //   </div>
    // </div>)

    //    let toast_options = {
    //     className: 'toast-message',
    //     allowHtml: true
    // }
       //toast.error(HTMLComp,toast_options)
     } 
   
    if (error.response) {
      return parseError(error.response);
    } else {
      let custom_error = {
        status: 400,
        message: 'custom_network_error'
      }
      return custom_error
    } 
  }
);

export const http = instance;
