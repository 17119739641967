import React from "react";

const Handle403 = (props) => {
  const { message } = props;
  return (
    <div className="card-body align-self-center text-center">
      <i className="far fa-frown fs-30"></i>
      <div className="text-center p-2">
        {message ? message : "We are Sorry,Access is not allowed Contact admin"}
      </div>
    </div>
  );
};

export default Handle403;
