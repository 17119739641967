import React from "react";

import AlertTable from "./AlertTable";

class WeekZscore extends React.Component {
  getBodyData = () => {
    const { details } = this.props;
    let bodyData = [];
    details.forEach(element => {
      let row = [];
      row.push(element.financial_year);
      row.push(element.z_score);
      bodyData.push(row);
    });
    return bodyData;
  };

  getAlertTableUI = () => {
    const { text } = this.props;
    const headerData = [
      {
        name: "Financial Year"
      },
      {
        name: "Z Score"
      }
    ];
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text
    };
    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default WeekZscore;
