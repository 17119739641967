import React, { useEffect, useState } from "react";
import { FaBriefcase } from "react-icons/fa";
import _ from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { Loader } from "react-loaders";
import { ListGroup, ListGroupItem } from "reactstrap";

import service from "../../../../../utils/service";
import allPaths from "../../../../../route/allPaths";
import ProgressBar from "../../../../../components/progress-bar";
import Handle403 from "../../../../../components/Handle403";

const RecentPortfolios = (props) => {
  const [portfolios, setPortfolios] = useState({
    res: [],
    status: "loading",
    total: "",
  });

  const history = useHistory();

  useEffect(() => {
    getPortfoliosAPIData();
  }, []);

  const getPortfoliosAPIData = async () => {
    let API_URL = "/v1/admin/portfolios";
    let res = await service("get", API_URL);
    if (res) {
      let status =
        _.get(res, "status") === 403
          ? "403"
          : _.isArray(res.data) && res.data.length > 0
          ? "done"
          : "no-data";
      setPortfolios({ res: res.data, status, total: res.total_count });
    }
  };

  const navigatePortfolios = (id) => {
    history.push(`${allPaths.PORTFOLIO_DETAIL_ROOT_ROUTE}/${id}`);
  };

  const navigateAllPortfoliosRoute = () => {
    history.push(`${allPaths.PORTFOLIOS_LIST_ROUTE}`);
  };

  const convert_to_string_percentage = (value, per_percentage) => {
    let output = value;
    if (_.isNumber(value)) {
      output = `${parseInt(value * per_percentage)}`;
    }
    return output;
  };

  const get_progess_data = (item) => {
    let total = _.get(item, "attributes.no_of_parties", 0);
    let risk_details = _.get(item, "attributes.risk_details", {});
    let low = _.get(risk_details, "low_risk", 0);
    let medium = _.get(risk_details, "medium_risk", 0);
    let high = _.get(risk_details, "high_risk", 0);

    let per_percentage = 100 / total;

    let arr = [
      {
        color: "danger",
        value: convert_to_string_percentage(high, per_percentage),
        label: high,
      },
      {
        color: "warning",
        value: convert_to_string_percentage(medium, per_percentage),
        label: medium,
      },
      {
        color: "success",
        value: convert_to_string_percentage(low, per_percentage),
        label: low,
      },
    ];
    return arr;
  };

  let show_portfolio =
    portfolios.status === "done" && portfolios.total > 0 ? true : false;

  const get_portfolio_header = () => {
    return (
      <div className="card-title fc-blue text-truncate">
        Portfolio & Risk Overview
      </div>
    );
  };

  return (
    <div className="card h-100 mb-3">
      <div className="card-body p-2">
        {show_portfolio && get_portfolio_header()}
        {show_portfolio && (
          <ListGroup className="todo-list-wrapper" flush>
            {_.isArray(portfolios.res) &&
              portfolios.res.map(
                (each, idx) =>
                  idx < 3 && (
                    <ListGroupItem className="p-0 pt-1 pb-2 border-0">
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left w-100">
                            <div className="widget-heading">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <a
                                    href="#"
                                    className="cursor-p"
                                    onClick={() =>
                                      navigatePortfolios(_.get(each, "id"))
                                    }
                                  >
                                    {each.attributes.name}
                                  </a>
                                </div>
                                <div className="col-md-6 col-12 d-flex align-items-center">
                                  <div className="w-100">
                                    <ProgressBar
                                      data={get_progess_data(each)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="widget-subheading fs-10"
                              style={{ height: "15px" }}
                            >
                              {`Total parties: ${_.get(
                                each,
                                "attributes.no_of_parties",
                                0
                              )}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  )
              )}
          </ListGroup>
        )}
        {portfolios.status === "loading" && (
          <div className="text-center">
            <Loader type="line-scale" />
          </div>
        )}
        {portfolios.status === "no-data" && (
          <div className="d-flex flex-column align-items-center h-100 p-3">
            <img
              src={`/cdn/assets/images/portfolio_nodata.png`}
              width={80}
            />
            <div className="fs-10 pt-2 align-self-center text-center">
              Looks like you don't have any portfolios.
              <br />
              Start by creating one now.
            </div>
            <button
              className="btn btn-outline-primary fs-14 mt-3"
              onClick={() => navigateAllPortfoliosRoute()}
            >
              Create a Portfolio
            </button>
          </div>
        )}
        {portfolios.status === "403" && (
          <div>
            <div className="card-title px-2 pb-2">Portfolios Overview</div>
            <Handle403/>
          </div>
        )}
      </div>
      {show_portfolio && (
        <div className="card-footer p-1 pr-2">
          <div className="btn-actions-pane-right">
            <button
              className="btn btn-link p-0 fs-10"
              onClick={() => navigateAllPortfoliosRoute()}
            >
              {`View All: ${portfolios.total}`}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(RecentPortfolios);
