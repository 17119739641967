import React from "react";
const FormatTwo = ({ details }) => {
  const generateTable = (shareholders, header) => {
    if (!shareholders || shareholders.length === 0) {
      return null;
    }

    const headers = Object.keys(shareholders[0]);

    const hyper_links = [
      "link",
      "report",
      "data_source",
      "links",
      "rating_rationale",
      "timeline_link",
      "FY_link",
    ];
    return (
      <div>
        <div key={header}>
          <div className="card-title">{header.replace(/_/g, " ")}</div>
          <table className="mb-2 table table-striped responsive">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {shareholders.map((shareholder, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td key={colIndex}>
                      {hyper_links.includes(header) ? (
                        <a href={shareholder[header]}><i className="fas fa-external-link-alt"></i></a>
                      ) : (
                        shareholder[header]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      {details.map((group, groupIndex) => (
        <div key={groupIndex}>
          {Object.entries(group).map(([key, value], index) =>
            generateTable(value, key)
          )}
        </div>
      ))}
    </div>
  );
};

export default FormatTwo;
