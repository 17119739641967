import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import allPaths from "../../route/allPaths";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
const CwFooter = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableMobileMenu(!props.enableMobileMenu));
  };

  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    if (location.pathname.includes("app/party/")) {
      setActivePath("party");
    } else {
      switch (location.pathname) {
        case "/app/alerts":
          setActivePath("alert");
          break;
        case "/app/dashboard":
          setActivePath("dashboard");
          break;
        case "/app/parties":
          setActivePath("parties");
          break;
        case "/app/account/products":
          setActivePath("products");
          break;
        case "/app/cases":
          setActivePath("case");
          break;
        case "/app/bell":
          setActivePath("bell");
          break;
        case location.pathname.includes("app/party/"):
          setActivePath("party");
          break;
        default:
          setActivePath(null);
          break;
      }
    }
  }, [location.pathname]);

  const queryParams = new URLSearchParams(location.search);

  const getOffcanvas = (item) => {
    if (activePath === "alert" || activePath === "case") {
      queryParams.set("offCanvas", `create-${item}`);
      history.push(`${location.pathname}?${queryParams.toString()}`);
    } else if (activePath === "party") {
      queryParams.set("party_offcanvas", true);

      history.push(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  const navigateToAlertPage = () => {
    history.push(`${allPaths.MY_ALERT}`);
  };

  const navigateToCasesPage = () => {
    history.push(`${allPaths.CMM_ROUTE}`);
  };

  return (
    <>
      <div className="footer d-lg-none d-flex justify-content-around align-items-center bg-light fixed-bottom">
        <i
          className={`${
            props.enableMobileMenu ? "footer-close" : "footer-more"
          } px-3 ${activePath === "more" ? "selected" : ""}`}
          style={{
            fontSize: "24px",
            paddingBottom: "12px",
            paddingTop: "12px",
          }}
          onClick={() => toggleEnableClosedSidebar()}
        ></i>
        <i
          className="footer-bell px-3"
          style={{
            fontSize: "24px",
            paddingBottom: "12px",
            paddingTop: "12px",
          }}
        ></i>

        <div className="plus-icon" onClick={() => getOffcanvas(activePath)}>
          <i
            className="fas fa-plus-circle text-primary"
            style={{ fontSize: "48px" }}
          ></i>
        </div>
        <i
          className={`footer-alert px-3 ${
            activePath === "alert" ? "selected" : ""
          }`}
          onClick={navigateToAlertPage}
          style={{
            fontSize: "24px",
            paddingBottom: "12px",
            paddingTop: "12px",
          }}
        ></i>
        <i
          className={`footer-case px-3 ${
            activePath === "case" ? "selected" : ""
          }`}
          onClick={navigateToCasesPage}
          style={{
            fontSize: "24px",
            paddingBottom: "12px",
            paddingTop: "12px",
          }}
        ></i>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    fabItems: state.ThemeOptions.fabItems,
    colorScheme: state.ThemeOptions.colorScheme,
    page_config: _.get(state, "my_profile.page_config", {}),
  };
};

export default connect(mapStateToProps)(withRouter(CwFooter));
