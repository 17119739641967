
export const appsidebar = [
  {
    "icon": "icon-party",
    "label": "Parties",
    "slug": "parties",
    "to": "/app/parties",
    "url": "/admin/parties",
    "sidebar":"ews"
  },
  {
    "icon": "icon-portfolio",
    "label": "Portfolios",
    "slug": "portfolios",
    "to": "/app/portfolios",
    "url": "/admin/portfolios"
  },
  {
    "icon": "icon-cases1",
    "label": "Cases",
    "slug": "cases",
    "to": "/app/cases",
    "url": "/cases"
  },
  {
    "icon": "icon-cases1",
    "label": "Forms",
    "slug": "forms",
    "to": "/app/forms",
    "url": "/cases"
  },
  {
    "icon": "icon-alerts1",
    "label": "Alerts",
    "slug": "alerts",
    "to": "/app/alerts",
    "url": "/admin/alerts"
  },
  {
    "icon": "icon-products",
    "label": "Products",
    "slug": "products",
    "to": "/app/account/products",
    "url": "/admin/products"
  },
];
