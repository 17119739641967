import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import SearchBox from "../../../../../layout/AppHeader/Components/SearchBox";
import { Mixpanel } from "../../../../../utils/mixpanel";

class ModalPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      party_Id: "",
      errMsg: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
    if (!this.state.modal) {
      Mixpanel.track("order_report_from_dashboard", {
        "report_type": this.props.reportname,
      });
    }
  }

  getPartyName = (val) => {
    
    let id = val.party_id;
    const { reports_types, history, reportname } = this.props;
    let slugName =
      reportname === "C-360 Report"
        ? "c-360"
        : reportname === "C-Docs Report"
        ? "c-docs"
        : reportname === "C-Trust Report" && "c-trust";

    let type = _.get(val, "type", "");

    let errorMessage = `${reportname} is not available for the selected entity`
   
    if (reports_types && type && reports_types[type]) {
      let report_info = _.get(reports_types[type], slugName)
    
      if(report_info) {
        history.push(`/party/${id}/checkout?slug=${slugName}`);
        this.setState({
          errMsg: false
        })
      }else {
        this.setState({
          errMsg: errorMessage
        })
      }
    } else {
      this.setState({
        errMsg: errorMessage
      })
    }
  };
  render() {
    const { errMsg } = this.state;
    return (
      <span className="d-inline-block">
        {/* <Button color="primary" onClick={this.toggle}>
          Order Report
        </Button> */}
        <div className="d-inline-block">
          <button className="cw-btn cw-btn-primary" onClick={this.toggle}>
            Order Report
          </button>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          // className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}> Order Report</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">Search and Select</Label>
              <SearchBox showConfirmBox={this.getPartyName} displaySearch={true} />
              {errMsg && <Label for="name" className="m-2">{errMsg}</Label>}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  reports_types: _.get(state.my_profile, "my_org.sku"),
})

export default connect(mapStateToProps)(withRouter(ModalPopup));
