import React, { useState } from "react";
import OrderReportFromDashboard from "../order_report_dashboard";

const ComingSoon = (props) => {
  const [orderModal, setOrderModal] = useState(false);
  return (
    <div className="position-relative">
      <div style={{ opacity: "0.2" }}>{props.children}</div>
      <div className="position-absolute h-100 w-100" style={{ top: 0 }}>
        <div className="h5 d-flex w-100 h-100 font-weight-bold justify-content-center align-items-center text-center flex-column">
          <div>
            {props?.message ? props.message : "Coming Soon"}
            {props.orderReport ?
              <div className="text-center">
                <button className="btn-transition btn btn-outline-primary mt-3" onClick={() => setOrderModal(true)}> Order Now </button>
                <OrderReportFromDashboard show={orderModal} close={() => setOrderModal(false)} />
              </div>
              : null}
          </div>
        </div>
      </div>

    </div>
  );
};

export default ComingSoon;
