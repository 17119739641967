import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const GA =  (props) => {


  let ga_code = props.GOOGLE_ANALYTICS;
  let location = _.get(props, "history.location")

  useEffect(() => {
    if(ga_code) {
      ReactGA.initialize(ga_code);
    }
  }, []);

  useEffect(() => {
    if(location) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location])

  return (
    <></>
  )
}

export default withRouter(GA);
