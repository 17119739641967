import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";
import qs from "querystring";

import { setToken } from "../../utils/getToken";

const Auth = (props) => {
  const [ status, setStatus ] = useState('loading');
  const config = useSelector(state => state.config.config);


  const params = new URLSearchParams(props.location.search);

  useEffect(() => {
    if(params.get("code")&&config) {
      callTokenAPI(params.get("code"))
    }
  }, [config])
  
  const callTokenAPI = async (code) => {
    const url = `${config.AUTH_URL}/oauth2/token`;
    let client_id = config.CLIENT_ID;
    let origin = _.get(window, 'location.origin');
    let redirect_uri = `${origin}/auth`;
    const body = {
      client_id: client_id,
      code: code,
      grant_type: "authorization_code",
      redirect_uri: redirect_uri,
    };
    try {
      let res = await axios.post(url, qs.stringify(body))
      const CW_JWT_TOKEN = _.get(res, "data.access_token");
      const { history } = props
      if(CW_JWT_TOKEN) {
        setToken(`Bearer ${CW_JWT_TOKEN}`);
        history.push('/')
      } else {
        setStatus('error')
      }
    } catch(e) {
      setStatus('error')
    }
    
  }
  
  return (
    <>
     {status === 'loading' && <div>Checking Auth Code</div>}
     {status === 'error' && <div>Auth Code is wrong</div>}
    </>
  )
}

export default withRouter(Auth);