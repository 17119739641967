import React from "react";
import moment from "moment-timezone";
import daysAgoDateFormat from "../../../utils/x_days_ago_date";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

const Activity = (props) => {
  const { name, description, date } = props;

  return (
    // <li className="list-group-item pb-1 pt-1">
    //   <div className="widget-content p-0">
    //     <div className="widget-content-wrapper">
    //       <div className="widget-content-left me-3">
    //         <div className="widget-content-left">
    //           <img
    //             width="26"
    //             className="rounded-circle"
    //             src="https://cdn.crediwatch.com/assets/icons/user-profile.svg"
    //             alt=""
    //           />
    //         </div>
    //       </div>
    //       <div className="widget-content-left flex2">
    //         <div className="widget-subheading opacity-7 fs-10 text-info">
    //           {name} <i className="fa fa-clock"></i>{" "}
    //           {moment.utc(date).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm")}
    //         </div>
    //         <div className="widget-heading">{description}</div>
    //       </div>
    //     </div>
    //   </div>
    // </li>
    // <div className="card mb-3 mt-3">
    //   <div className="card-body">
    //     <div className="d-flex align-items-center mb-2">
    //       <img
    //         className="rounded-circle d-flex justify-content-center align-items-center"
    //         src="https://cdn.crediwatch.com/assets/icons/user-profile.svg"
    //         style={{
    //           width: "40px",
    //           height: "40px",
    //           color: "white",
    //           fontSize: "20px",
    //         }}
    //       ></img>

    //       <h5 className="fs-18 fw-700 ms-2">{name}</h5>
    //       <h6 className="fs-14 fw-400 text-muted ms-2">
    //         {daysAgoDateFormat(moment(date))}
    //       </h6>
    //     </div>
    //     <p className="fs-14 fw-400 ms-5">{description}</p>
    //     {/* <ul className="list-group list-group-horizontal border-top pt-2">
    //       <li className="list-group-item border-0 ps-0 pe-3">
    //         <i className="comments" /> 4 Replies
    //       </li>
    //       <li className="list-group-item border-0 px-3">
    //         <i className="comments" /> 4 Replies
    //       </li>
    //       <li className="list-group-item border-0 px-3">
    //         <i className="comments" /> 4 Replies
    //       </li>
    //     </ul> */}
    //   </div>
    // </div>
 <VerticalTimeline layout="1-column" className="collapse show">
    <VerticalTimelineElement
      className="vertical-timeline-item collapse show"
      icon={
        <i className="badge text-bg-dot text-bg-dot-xl text-bg-primary"> </i>
      }
           
    >

      
            <p>
            <div className="text-primary">
            {daysAgoDateFormat(moment(date))}
              </div>
              <h6>
               {description}
              </h6>
            

                  <div className="d-flex align-items-center mb-2">
           <img
            className="rounded-circle d-flex justify-content-center align-items-center"
            src="https://cdn.crediwatch.com/assets/icons/user-profile.svg"
            style={{
              width: "20px",
              height: "20px",
              color: "white",
              fontSize: "20px",
            }}
          ></img>

          <h5 className="fs-14 fw-700 mt-2 ms-2">{name}</h5>
              </div>
          
            
          
            </p>


    </VerticalTimelineElement>
  </VerticalTimeline>
  );
};

export default Activity;
