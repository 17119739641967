import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";

import {
  getProductApi,
} from "../../../actions/dashboard/action";
import { Mixpanel } from "../../../utils/mixpanel";
import VideoTutorial from "./components/video_tutorial";
import ReportMetrics from "./components/report_metrics";
import SectorInterest from "./components/sector_interest";
import RecommandedPage from "./components/recommended_for_you";
import InterestedIndustries from "./components/interested_industries";
import Watchlist from "./components/watchlist";
import VerifyBusiness from "./components/verify_business";
import RecentOrder from "./components/recent_orders";
import InvitePeople from "./components/invite-people";
import Preferences from "./components/preferences";
import BusinessIntegration from "./components/business_integration";
import ReportTypes from "./components/report_insights";
import downloadByOctetStream from "./components/report_insights/DownloadReportFunction";
import MCAStatusDashboard from "./components/mca-status";
import Eqaro from "./components/eqaro";
import RecentPortfolios from "./components/recent_portfolios/index";
import RecentOrders from "./components/latest_products/index";
import RecentAlerts from "./components/recent_alerts/index";
import SearchBar from "./components/search_bar/index";
import RecentSearches from "./components/recent_searches/index";
import CMM_Statistics from "./components/cmm/statistics";
import Score_Overview from "./components/score-overview";
import RecentCases from "./components/recent-cases";

import {
  HelpMessage,
  CheckBussinessVerified,
  MonitorRisk,
  RunDiligence,
  TodoMessage,
} from "./components/verify_business_comp/index";
import QuickActions from "./components/quick-actions";
import CMM_Overview from "../../../widgets/case-overview";
import {setSideBarItems,setSideBarLoadingDone,setEnableClosedSidebar} from "../../../reducers/ThemeOptions"
import { enablePartySearchBar } from "../../../actions/globalAction/action";

import service from "../../../utils/service";
import { appsidebar } from "../../../utils/appsidebar";
import OffCanvasRenderer from "../../../components/OffcanvasRender";

function Dashboard(props) {
  const [dashboard_JSON, setDashboardConfig] = useState([]);
  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(setSideBarItems(appsidebar));
    dispatch(setSideBarLoadingDone());
  }, [dispatch,dashboard_JSON]);

  const history = useHistory();
  let searchQuery = _.get(history, "location.search", "");
  let dashboard=_.get(history,"location.pathname").includes("/dashboard")?true:false
  const org_type = _.get(props, "my_org.type");

  let params = new URLSearchParams(searchQuery);
  let report_to_be_downloaded = params.get("reports_download");
  const alertId = params.get("offCanvas");

  useEffect(()=>{
      if(org_type==="b2sme")
      dispatch(enablePartySearchBar(true))
    return () => {
      dispatch(enablePartySearchBar(false));
    };
  },[dispatch,dashboard])
  const getWidgetConfigJSON = async () => {
    let API_URL = "/v1/account/myconfig/dashboard";
    let res = await service("get", API_URL);
    if (res && _.isArray(res) && res.length > 0) {
      setDashboardConfig(res);
    } else {
      set_default_search_bar()
    }
  };

  const set_default_search_bar = () => {
    let arr = [
      {
        "class": "col-md-12",
        "children": [
          {
            "class": "col-md-2"
          },
          {
            "class": "col-md-8 col-12",
            "children": [          
              {
                "id": "search-bar",
                "r_tour": "search-bar",
                "class": "col-md-12"
              }
            ]
          },
          {
            "class": "col-md-2"
          }
        ]
      }
    ]
    setDashboardConfig(arr)
  }

  useEffect(() => {
    props.getProductApi();
    getWidgetConfigJSON();
    Mixpanel.track("visit_dashboard", { page_name: "Dashboard Page" });
  }, []);

  useEffect(() => {
    if (report_to_be_downloaded) {
      downloadByOctetStream("Public Profile Page", report_to_be_downloaded);
    }
  }, []);


  const getReportDetails = (item) => {
    return (
      <ReportTypes
        reportname={item.reportname}
        reportId={item.id}
        logo={item.logo}
        subTitle={item.subTitle}
        imgCom={item.imgCom}
        sampleReport={item.sampleReport}
      />
    );
  };

  const getWidget = (item) => {
    let c360_details = {
      id: "c-360",
      reportname: "C-360 Report",
      logo: "https://cdn.crediwatch.com/assets/images/c-360-icon.svg",
      subTitle:
        "Get a birds-eye view of an entity’s financial and non financial health.",
      imgCom: "https://cdn.crediwatch.com/assets/images/c-360_illustration.svg",
      sampleReport:
        "https://f.hubspotusercontent40.net/hubfs/20285543/C-360%20Sample%20Report_Listed-1.pdf",
    };

    let cdocs_details = {
      id: "c-docs",
      reportname: "C-Docs Report",
      logo: "https://cdn.crediwatch.com/assets/images/c-docs-icon.svg",
      subTitle:
        "Access all the regulatory filings made by an entity in a searchable format.",
      imgCom:
        "https://cdn.crediwatch.com/assets/images/c-docs_illustration.svg",
    };

    let ctrust_details = {
      id: "c-trust",
      reportname: "C-Trust Report",
      logo: "https://cdn.crediwatch.com/assets/images/c-trust-icon.svg",
      subTitle:
        "A unique score to quickly assess the creditworthiness of partners, customers and vendors.",
      imgCom:
        "https://cdn.crediwatch.com/assets/images/c-trust_illustration.svg",
      sampleReport: "https://cdn.crediwatch.com/images/C-Trust-Sample.pdf",
    };

    let widget = {
      "video-tutorial": <VideoTutorial />,
      "report-metrics": <ReportMetrics />,
      "sector-interest": <SectorInterest />,
      "recommended-for-you": <RecommandedPage />,
      "interested-industries": <InterestedIndustries />,
      watchlist: <Watchlist />,
      "recent-order": <RecentOrder />,
      "invite-people": <InvitePeople />,
      "verify-business": <VerifyBusiness />,
      preferences: <Preferences />,
      "business-integration": <BusinessIntegration />,
      "c-360-details": getReportDetails(c360_details),
      "c-docs-details": getReportDetails(cdocs_details),
      "c-trust-details": getReportDetails(ctrust_details),
      "mca-status": <MCAStatusDashboard />,
      "eqaro-setup": <Eqaro />,
      "recent-portfolios": <RecentPortfolios />,
      "recent-orders": <RecentOrders />,
      "recent-alerts": <RecentAlerts />,
      "search-bar": <SearchBar />,
      "recent-searches": <RecentSearches />,
      "check-business-verified": <CheckBussinessVerified />,
      "help-message": <HelpMessage />,
      "monitor-risk": <MonitorRisk />,
      "run-diligence": <RunDiligence />,
      "cmm-statistics": <CMM_Statistics />,
      "cmm-overview": <CMM_Overview />,
      "score-overview": <Score_Overview />,
      "recent-cases": <RecentCases />,
      "todo-message": <TodoMessage />,
      "quick-actions": <QuickActions />,
    };
    let widget_id = item.id;

    if (widget[widget_id]) {
      return (
        <>
        {org_type==="b2sme"?<div className="card card-body h-100">{widget[widget_id]}</div>:widget[widget_id]}
        </>
      )
    } else {
      return <>{`${widget_id} widget is not defined`}</>;
    }
  };

  const getAllWidgetUI = (json) => {
    let ui_arr = [];

    if (_.isArray(json)) {
      json.forEach((item) => {
        if (item.id) {
          ui_arr.push(
            <div className={`${item.class} mb-3`}>{getWidget(item)}</div>
          );
        } else if (item.children && _.isArray(item.children)) {
          ui_arr.push(
            <div className={`${item.class}`}>
              {getAllWidgetUI(item.children)}
            </div>
          );
        } else {
          ui_arr.push(<div className={`${item.class}`}></div>);
        }
      });
    }
    return <div className="row">{ui_arr}</div>;
  };

  return <>
   <div className={`container p-2 ${alertId?"blur":""}`}>
    {getAllWidgetUI(dashboard_JSON)}
    </div>
<OffCanvasRenderer/>

  </>
  
}

function mapStateToProps(state) {
  return {
    profileData: _.get(state, "my_profile.profileData"),
    eqaro_step_info: _.get(state, "my_profile.eqaro_step_info"),
    page_config: _.get(state, "my_profile.page_config", {}),
    my_org: _.get(state, "my_profile.my_org"),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProductApi,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
