import { Mixpanel } from "../../../../../utils/mixpanel";
import _ from 'lodash';

const download = (filename, url) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.rel = "noopener";
  
    link.click();
    link.remove();
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

const downloadByOctetStream = async (source, report_type) => {
  let available_reports = ["c-360", "c-trust"];
  
  if (available_reports.includes(report_type)) {
    let report_name = `${_.capitalize(report_type)} Report.pdf`
    let url = report_type === "c-360"
      ? "https://f.hubspotusercontent40.net/hubfs/20285543/C-360%20Sample%20Report_Listed-1.pdf"
      : "https://cdn.crediwatch.com/images/C-Trust-Sample.pdf";
  
    const chunks = [];
    const res = await fetch(url);
    const reader = res.body.getReader();
    const readChunk = ({ done, value: chunk }) => {
      if (done) {
        const blob = new Blob(chunks, {
          type: "application/octet-stream; charset=utf-8",
        });
        download(report_name, URL.createObjectURL(blob));
        return;
      }
      chunks.push(chunk);
      reader.read().then(readChunk);
    };
    reader.read().then(readChunk);
    Mixpanel.track("download_sample_report", { "report_type": report_name, source: source})
  }
  };

  export default downloadByOctetStream;