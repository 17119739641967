import React from 'react'
const getIcons = (props) => {
  const { icon } = props;
  const iconList = [
    "highlights",
    "basic-info",
    "alerts",
    "registrations",
    "ews",
    "notification",
    "relationships",
    "financials",
    "charges",
    "legal",
    "timelines",
    "sanctions",
    "news",
    "contact",
    "documents",
    "ratings",
    "iec",
    "blacklists",
    "trade-check",
    "gstn-info",
    "epf-check",
    "media",
    "epfo",
    "icon-reports",
    "business-details",
    "cases",
  ]

  switch (icon) {
    case 'features':
      return 'pe-7s-star';
    case 'products':
      return 'fa fa-cart-plus';
    case 'macro_indicators':
      return 'fa-solid fa-globe';
    case 'portfolios':
      return 'fa fa-briefcase';
    case 'feature_cases':
      return 'fa-sharp fa-regular fa-file-lines';
    case 'docs':
      return 'fa-sharp fas fa-book';
    case 'business':
      return 'fa-solid fa-store';
    case 'collaborators':
      return 'fa fa-users';
    default:
      return iconList.includes(icon) ? `icon icon-${icon}` : icon;
  }
}

export default getIcons