import _ from 'lodash';
import service from "../../utils/service";
import { userApi } from "../myprofile/action";
import * as ActionType from "./actionTypes";
import obj_to_string from '../../helpers/obj_to_string';

const loadProductData = () => {
  return {
    type: ActionType.LOAD_PRODUCT_DATA,
  };
};
const setProductData = (payload) => {
  return {
    type: ActionType.SET_PRODUCT_DATA,
    payload,
  };
};

export const getProductApi = () => {
  return async (dispatch) => {
    dispatch(loadProductData());
    let params = {
      per_page: 3,
      sort_by: "created_at",
      sort_in: "desc",
    };
    let multi_value_obj = {
      product_definition_type: ['report', 'timeline']
    }
    let strKey = obj_to_string(params, multi_value_obj)
    let res = await service("get", `/v1/admin/products?${strKey}`);

    dispatch(setProductData(res));
  };
};

const loadWatchlistPaties = () => {
  return {
    type: ActionType.LOAD_WATCHLIST_PARTIES,
  };
};
const setWatchlistParties = (payload) => {
  return {
    type: ActionType.SET_WATCHLIST_PARTIES,
    payload,
  };
};

export const getWatchlistParties = (list_id, params) => {
  return (dispatch) => {
    dispatch(loadWatchlistPaties());

    service("get", `/v1/admin/lists/${list_id}`, { params: params }).then(
      (response) => {
        dispatch(setWatchlistParties(response));
      }
    );
  };
};

const loadIsWatchlisted = () => {
  return {
    type: ActionType.LOAD_IS_WATCHLISTED_PARTY,
  };
};
const setIsWatchlisted = (payload) => {
  return {
    type: ActionType.SET_IS_WATCHLISTED_PARTY,
    payload,
  };
};
export const isWatchlisted = (party_id) => {
  return (dispatch) => {
    dispatch(loadIsWatchlisted());
    service("get", `/v1/admin/parties/${party_id}/is_watchlisted`).then(
      (response) => {
        dispatch(setIsWatchlisted(response));
      }
    );
  };
};

export const updateUserWalkthrough = (user_id, wt) => {
  // let mockwt = { dashboard: "start", party: "sidebar",checkout:"" };
  let data = {
    user: {
      wt: wt,
    },
  };
  return service("patch", `/v1/admin/users/${user_id}`, { data });
};

export const resetUserWt = (user_id, wt) => {
  return (dispatch) => {
    updateUserWalkthrough(user_id, wt).then((res) => {
      if(res.success) {
        dispatch(userApi(user_id));
      }
    });
  };
};
