import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ErrorPanel = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();

  const message = useSelector((state) => _.get(state, "my_profile.my_org.message"));

  useEffect(() => {
    if (message) {

      const delayInMilliseconds = 180 * 60 * 1000; // Convert minutes to milliseconds

      const checkAlertVisibility = () => {
        const lastDismissed = localStorage.getItem('alertDismissedAt');
        const now = new Date().getTime();
        if (!lastDismissed || (now - lastDismissed) > delayInMilliseconds) {
          setShowAlert(true);
        }
      };

      checkAlertVisibility();

      const intervalId = setInterval(checkAlertVisibility, 1000);

      return () => clearInterval(intervalId);
    }
  }, [message]);

  const handleDismiss = () => {
    setShowAlert(false);
    localStorage.setItem('alertDismissedAt', new Date().getTime());
  };

  const alertMessage = message && t(message[0].I18_code);
  const alertStyle = message && `alert-${message[0].name}`;

  return (
    showAlert && (
      <div className={`alert ${alertStyle} alert-dismissible fade show`} role="alert">
        {alertMessage}
        <button type="button" className="btn-close" aria-label="Close" onClick={handleDismiss}></button>
      </div>
    )
  );
};

export default ErrorPanel;