import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {Mixpanel} from '../../../utils/mixpanel'
import "./UserBox/userbox.css"
function Support(props) {
  const openHelp = () => {
    window.FreshworksWidget("open", 'ticketForm');
    let profileData = _.get(props, "profileData", {})
    window.FreshworksWidget("identify", "ticketForm", {
      name: _.get(profileData, 'name', ''),
      email: _.get(profileData, "email", ''),
      customFields: {
        cf_url: _.get(window, "location.href", ""),
        cf_account: _.get(profileData, "organization.name", "")
      }
    });

    Mixpanel.track("visits_support_form", {"page_name": "Support Page"})

  }
  return (
    <div className='d-flex flex-row cursor-pointer align-items-center h-100'  onClick={openHelp}>
        <i className='Support-icon mt-1'></i>
      <span className="text-primary p-2">Support</span>
    </div>
  )
}

const mapStateToProps = (state) => ({
  profileData: _.get(state, "my_profile.profileData")
})

export default connect(mapStateToProps)(Support)
