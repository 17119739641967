import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from 'moment';

class HelpButtonUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
   
    return (
      <span className="d-inline-block mb-2 me-2">
        <Button className="mt-2 badge text-bg-pill text-bg-secondary" color="secondary" onClick={this.toggle}>
        ?
        </Button>
       
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
          <div>
            Thresholds
          </div>
          
          <small className="float-left text-muted">
          Last updated on: {moment(this.props.updatedDate).format('DD-MM-YYYY')}
          </small>
            </ModalHeader>
          <ModalBody>
          {this.props.thresholdData.map((item,key)=>

          <div key={key}>
            {item.replace(/([A-Z])/g,' $1').trim()}
            <hr/>
          </div>
          )}
          
          </ModalBody>
          <ModalFooter>
         
            <Button color="link" onClick={this.toggle}>
              Cancel
            </Button>
           
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

export default HelpButtonUI;
