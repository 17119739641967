import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";
import { Mixpanel } from "../../../../../utils/mixpanel";

function Vdobutton({ name, link, mixPanelEventName, className, data, activeIndex, mobile_link }) {
  const [modal, setmodal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const [currentLink, setCurrentLink] = useState({desktopLink: link, mobileLink: mobile_link});

  const toggle = () => {  
    setmodal(!modal);
    if(!modal) {
      Mixpanel.track("view_video_tutorials", {"video_name": mixPanelEventName})
    }
  };

  const handleOnClick = (index, link, mobile_link) => {
    setCurrentIndex(index);
    setCurrentLink({desktopLink: link, mobileLink: mobile_link});
  };

  const getVideoUrl=()=>{
    if(!mobile_link ||  window.innerWidth>990){
      return currentLink.desktopLink;
    }else{
     return currentLink.mobileLink;
    }
   }
  return (
    <div className="d-block">
      {/* <Button outline className={className} onClick={toggle} color="primary">
        {name}
      </Button> */}

      <button className="cw-btn cw-btn-secondary w-100 mb-3" onClick={toggle}>
      <img
        src={`/cdn/assets/icons/product/play_circle.svg`}
        alt=""
        className="float-left"
      />
        <span className="videoIcon-vertical-align-sub text-capitalize">{name}</span>
      </button>

      <Modal size="xl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Video Tutorials</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className=" col-md-3 d-none d-md-block ">
                {data.map((item, activeIndex) => {
                  return (
                    <div>
                      {activeIndex === currentIndex ? (
                        <div
                          className="row p-2 cursor-pointer bg-light"
                          onClick={() => handleOnClick(activeIndex, item.link, item.mobile_link)}
                        >
                          <div className="col-md-6">
                            <img className="w-100" src={item.thumbnails} />
                          </div>
                          <div className="col-md-6">
                            <div style={{ fontSize: "14px" }}>
                              {item.thumbnailTitle}
                            </div>
                            <small>{item.duration}</small>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="row p-2 cursor-pointer"
                          onClick={() => handleOnClick(activeIndex, item.link, item.mobile_link)}
                        >
                          <div className="col-md-6">
                            <img className="w-100" src={item.thumbnails} />
                          </div>
                          <div className="col-md-6">
                            <div style={{ fontSize: "14px" }}>
                              {item.thumbnailTitle}
                            </div>
                            <small>{item.duration}</small>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="col-sm-12 col-md-9 ">
                <ReactPlayer
                  url={getVideoUrl()}
                  playing
                  width="100%"
                  controls={true}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Vdobutton;
