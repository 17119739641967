import _ from "lodash";
import allPaths from "../route/allPaths";

const redirectLoginSSO = (config) => {
  let login_TYPE = config.LOGIN_TYPE;


  let origin = _.get(window, 'location.origin');

  if(login_TYPE === 'amplify') {
    let currentLocation = `${config.AUTH_URL}?app_uri=${config.REDIRECT_BASE_URL}${allPaths.SET_TOKEN_ROUTE}`;
    window.location.href = currentLocation
  } else if(login_TYPE === 'cognito') {
    let base_uri = config.AUTH_URL;
    let client_id = config.CLIENT_ID;
    let redirect_uri = `${config.REDIRECT_BASE_URL}/auth`;
    let response_type = 'code';
    if (base_uri && client_id) {
      window.location.href = `${base_uri}/login?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`;
    } else {
      console.log("Please Check  ENV")
    } 
  }
};

export default redirectLoginSSO;