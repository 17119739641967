import React from 'react'
import { FaRegFileAlt } from 'react-icons/fa'


const latest_searches = [
    {
        name: 'JAI SHREE BALAJI MARKETING PRIVATE LIMITED',
    },
    {
        name: 'INFOSYS PRIVATE LIMITED',
    },
    {
        name: 'CREDIWATCH INFORMATION PRIVATE LIMITED'
    },
    {
        name: 'JAI SHREE ENTERPRISES'
    },
    {
        name: 'INFO TECH PRIVATE LIMITED'
    },
    {
        name: 'JAI SHREE BALAJI MARKETING PRIVATE LIMITED'
    }

]

const RecentSearches = () => {
    return (
        <>
            <h5 className='fs-18 fw-600 mb-3 fc-blue fb'>Recent Searches</h5>
            {latest_searches.map((each, idx) => idx < 3 && <div className='d-flex justify-content-between m-0 p-0 mb-3 '>
                <div className='d-flex text-truncate'>
                    <FaRegFileAlt className='icon-gradient text-truncate bg-night-fade btn-icon-wrapper btn-icon-lg text-primary align-self-center' size={25} />
                    <a href='/' className='text-primary text-truncate ml-2 align-self-center w-50'>
                        {each.name}
                    </a>
                </div>
            </div>)}
            {latest_searches && latest_searches.length > 3 && <a href='' className='text-right d-flex flex-column justify-content-end align-items-end align-items-end' >View More...</a>}
        </>
    )
}

export default RecentSearches
