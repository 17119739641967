import _ from "lodash";
import axios from "axios";

import service from "../../utils/service";
import allPaths from "../../route/allPaths";
import {
  setSideBarItems,
  setSideBarLoadingDone,
  setSideBarLoadingStatus,
} from "../../reducers/ThemeOptions";
import * as partyActionTypes from "./actionTypes";

const get_party_API = (party_id) => {
  let URL = `/v1/parties/${party_id}`;
  return service("get", URL);
};

const get_admin_party = (party_id) => {
  let URL = `/v1/admin/parties/${party_id}`;
  return service("get", URL);
};

// export const get_businesss_activity_API = (party_id) => {
//   let URL = `/v2/parties/${party_id}/information/financial_year`;
//   return service("get", URL);
// };

export const get_last_mca_filing_API = (party_id) => {
  let URL = `v2/parties/${party_id}/documents`;
  return service("get", URL);
};

export const loadPartyInfo = () => {
  return {
    type: partyActionTypes.LOAD_PARTY_INFO,
  };
};

export const loadAdminPartyInfo=()=>{
  return {
    type: partyActionTypes.LOAD_ADMIN_PARTY_INFO,
  };
}

const setPartyInfo = (data) => {
  return {
    type: partyActionTypes.SET_PARTY_INFO,
    payload: data,
  };
};
const setAdminPartyInfo = (data) => {
  return {
    type: partyActionTypes.SET_ADMIN_PARTY_INFO,
    payload: data,
  };
};

const loadBusinessActivity = () => {
  return {
    type: partyActionTypes.LOAD_BUSINESS_ACTIVITY,
  };
};

const setBusinessActivity = (data) => {
  return {
    type: partyActionTypes.SET_BUSINESS_ACTIVITY,
    payload: data,
  };
};

const loadPartyIsClaimed = () => {
  return {
    type: partyActionTypes.LOAD_PARTY_CLAIM_INFO,
  };
};
const loadRegistrations = () => {
  return {
    type: partyActionTypes.LOAD_REGISTRATIONS,
  };
};

const setRegistrations =(data)=>{
  return{
    type : partyActionTypes.SET_REGISTRATIONS,
    payload: data,
  }
}

const setPartyIsClaimed = (data) => {
  return {
    type: partyActionTypes.SET_PARTY_CLAIM_INFO,
    payload: data,
  };
};


const getSideBarJSON = (
  side_bar_json,
  party_details,
  party_id,
  claimed
) => {

  let type = _.get(party_details, "data.model_type", "");
  let sideBarJSON = [];
  let availableJSON = [
    "default",
    "public_company",
    "private_company",
    "person",
  ];
  if (_.isObject(side_bar_json) && (_.get(side_bar_json, `${type}`) || _.get(side_bar_json, `default`))) {
    if (availableJSON.includes(type)) {
      sideBarJSON = _.get(side_bar_json, `${type}`);
    } else {
      sideBarJSON = _.get(side_bar_json, `default`);
    }
  } else {
    const default_party_sidebar = [
      {
        "name": "highlights",
        "text": "Highlights",
        "icon": "highlights",
        "url": `/party/${party_id}`,
        "method": "GET"
      },
      {
        "name": "registrations",
        "text": "Registrations",
        "icon": "registrations",
        "url": `/party/${party_id}/registrations`,
        "method": "GET"
      },
      {
        "name": "relationships",
        "text": "Relationships",
        "icon": "relationships",
        "url": `/party/${party_id}/relationships`,
        "method": "GET"
      },
      {
        "name": "contact",
        "text": "Contact",
        "icon": "contact",
        "url": `/party/${party_id}/contact`,
        "method": "GET"
      }
    ]
    sideBarJSON = default_party_sidebar
  }

  let makeJSON = [];

  if (_.isArray(sideBarJSON)) {
    sideBarJSON.forEach((obj) => {
      let dupObj = {
        slug: obj.name,
        icon: obj.icon,
        label: obj.text,
        to: `${allPaths.PARTY_ROOT_ROUTE}/${party_id}/${obj.name}`,
      };
      if (obj.name !== 'business-details') {
        makeJSON.push(dupObj)
      } else {
        if (claimed) {
          makeJSON.push(dupObj);
        }
      }
    });
  }

  return makeJSON;
};

const get_config_json_api = () => {
  let URL = `/v1/account/myconfig/party-sidebar`;
  return service("get", URL);
}

export const get_party_basic_information = (party_id) => {
  return (dispatch) => {
    dispatch(loadPartyInfo());
    dispatch(setSideBarLoadingStatus());
    dispatch(loadPartyIsClaimed());
    dispatch(loadRegistrations());
    axios
      .all([
        get_config_json_api(),
        get_party_API(party_id),
      ])
      .then(
        axios.spread((side_bar_json, party_details) => {
          let claimed = _.get(party_details, "data.claimed", false);
          if (_.get(party_details, "data")) {
            let makeJSON = getSideBarJSON(
              side_bar_json,
              party_details,
              party_id,
              claimed
            );
            dispatch(setSideBarLoadingDone());
            dispatch(setSideBarItems(makeJSON));
            dispatch(setPartyInfo({ ...party_details }));
          } else {
            dispatch({ type: partyActionTypes.ERROR_PARTY_INFO });
          }
          dispatch(setPartyIsClaimed(party_details));
          dispatch(setRegistrations(party_details))
        })
      );
  };
};

export const get_admin_party_information = (party_id) => {
  return (dispatch) => {
    dispatch(loadAdminPartyInfo());
    axios
      .all([
          get_admin_party(party_id)
      ])
      .then(
        axios.spread(( party_details) => {
          if (_.get(party_details, "data")) {
            dispatch(setAdminPartyInfo({ ...party_details }));
          } else {
            dispatch({ type: partyActionTypes.ERROR_ADMIN_PARTY_INFO });
          }
        })
      );
  };
};




