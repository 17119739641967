import React from "react";
import moment from "moment";

import AlertTable from "./AlertTable";

class FinancialStatementTrend extends React.Component {
  getDateFormat = (date) => {
    let format = "DD-MM-YYYY";
    return date ? moment(date, "YYYY-MM-DD").format(format) : "---";
  };

  getBodyData = () => {
    const { details, keyArr } = this.props;
    let bodyData = [];
    details.forEach((element) => {
      let row = [];
      if (keyArr && keyArr.length === 2) {
        row.push(this.getDateFormat(element[keyArr[0]] || element["fy"]));
        row.push(element[keyArr[1]]);
      } else {
        row.push(this.getDateFormat(element.financial_year || element.fy));
        row.push(element.tnw_value);
      }
      bodyData.push(row);
    });
    return bodyData;
  };

  getAlertTableUI = () => {
    const { text, headerArr } = this.props;
    const headerData = headerArr
      ? headerArr
      : [
          {
            name: "Financial Year",
          },
          {
            name: "Tnw Value",
          },
        ];
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text,
    };
    return (
      <AlertTable
        headerData={headerData}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default FinancialStatementTrend;
