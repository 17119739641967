
import _ from "lodash";
import React, { Component } from "react";

import AlertTable from "./AlertTable";

class KeyPairAlert extends Component {
  convertKeytoFiled = (key) => {
    if (key && typeof key === "string") {
      return key.split("_").join(" ");
    } else {
      return "";
    }
  };

  getBodyData = (details, text) => {
    let headerData = [];
    details &&
      details.forEach &&
      details.forEach((obj) => {
        let dataArr = Object.entries(obj);
        dataArr.forEach((ele) => {
          let arr = [];
          let label = (text === 10228 && ele[0] === "long_term_borrowings") ? "total_debt" : ele[0];
          arr.push(this.convertKeytoFiled(label))

          if(ele.length === 2) {
            let htmlkeys = ['blob', 'disclosure_in_auditors_report_explanatory_text_block', 'whether_auditors_report_has_been_qualified_or_has_any_reservations_or_contains_adverse_remarks']
            if(htmlkeys.includes(ele[0])) {
              arr.push(<span dangerouslySetInnerHTML={{ __html: ele[1] }} />)
            } else if (ele[0].includes('percent')) {
              if (ele[1] <= 1 && ele[1] >= -1) {
                arr.push((ele[1] * 100).toFixed(0));
              } else {
                arr.push(ele[1]);
              }
            } else if(ele[0] === 'source' && _.isArray(ele[1])) {
              arr.push(
                ele[1].map((data, index) => {
                  if (data.startsWith("http") || data.startsWith("www")) {
                    return (
                      <a href={data} target="_blank" key={index}>
                        {data}
                      </a>
                    );
                  }
                })
              );
            } else {
              arr.push(ele[1]);
            }
          }

          headerData.push(arr);
        })

        // dataArr.forEach((element) => {
        //   let arr = [];
        //   if (
        //     element.length === 2 &&
        //     ( element[0] === "blob" ||
        //       element[0] === "disclosure_in_auditors_report_explanatory_text_block" || 
        //       element[0] ==="whether_auditors_report_has_been_qualified_or_has_any_reservations_or_contains_adverse_remarks")
        //   ) {
        //     arr.push(<span dangerouslySetInnerHTML={{ __html: element[1] }} />);
        //   } else {
        //     if (element[0] !== "is_delayed") {
        //       arr.push(
        //         <span style={{ textTransform: "capitalize" }}>
        //           {this.convertKeytoFiled(
        //             text === 10228 && element[0] === "long_term_borrowings"
        //               ? "total_debt"
        //               : element[0]
        //           )}
        //         </span>
        //       );
        //     }
        //     if (element[0].includes("percent")) {
        //       arr.push((element[1] * 100).toFixed(0));
        //     } else if (element[0] === "source") {
        //       arr.push(
        //         element[1].map((data, index) => {
        //           if (data.startsWith("http") || data.startsWith("www")) {
        //             return (
        //               <a href={data} target="_blank" key={index}>
        //                 {data}
        //               </a>
        //             );
        //           }
        //         })
        //       );
        //     } else {
        //       arr.push(element[1]);
        //     }
        //   }
        //   headerData.push(arr);
        // });
      });
    return headerData;
  };

  getAlertTableUI = () => {
    const { text, details } = this.props;
    const bodyData = this.getBodyData(details, text);
    const extraOptions = {
      title: text,
    };
    return (
      <AlertTable
        headerData={[]}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default KeyPairAlert;
