import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Legend } from "chart.js";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";

import ComingSoon from "../coming_soon";
import service from "../../../../../utils/service";

Chart.register(ArcElement, Legend);
function SectorInterest() {
  const [piLables, setPiLabels] = useState([]);
  const [piValues, setPiValues] = useState([]);
  const [hexCodes, setHexCodes] = useState([]);
  const [status_api, setStatusAPI] = useState('not-triggered')

  useEffect(() => {
    getPiData();
  }, []);

  useEffect(() => {
    getRandomHexCode();
  }, [piValues]);

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: "white",
        font: {
          size: 13,
        },
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage;
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "black",
          boxWidth: 25,
        },
      },
    },
  };

  const pieData = {
    labels:
      _.isArray(piLables) && piLables.length > 0
        ? piLables
        : ["Software Services", "Agriculture", "Finance", "Manufacturing"],
    datasets: [
      {
        data:
          _.isArray(piValues) && piValues.length > 0
            ? piValues
            : [42, 18, 24, 36],
        backgroundColor:
          hexCodes.length > 0
            ? hexCodes
            : ["#894695", "#E9AA3B", "#31737F", "#1C9CC8"],
      },
    ],
  };

  // const dummyResponse = {
  //   "data": 20,
  //   "dogvubds": 23,
  //   "sdgvadgdfv": 34
  // }
  const getPiData = async () => {
    setStatusAPI('loading')
    const URL = "/v1/account/sector_interests";
    let res = await service("GET", URL);
    let sector_of_interest = _.get(res, "data.sector_interests");
    let piLabels = sector_of_interest ? Object.keys(sector_of_interest) : [];
    let piValues = sector_of_interest ? Object.values(sector_of_interest) : [];
    setPiLabels(piLabels);
    setPiValues(piValues);
    setStatusAPI('done')
  };

  const getRandomHexCode = () => {
    let codes = [];
    let hexCodesToBeUsed = [
      "#76CAD1",
      "#8986D9",
      "#E7905F",
      "#D46AA4",
      "#79C7F3",
      "#BB9471",
    ];
    for (let i = 0; i < piLables?.length; i++) {
      codes.push(hexCodesToBeUsed[i]);
    }
    setHexCodes(codes);
  };

  const getChart = () => {
    return (
      <div className="mt-2">
        <Doughnut
          data={pieData}
          plugins={[ChartDataLabels]}
          options={options}
          width={200}
          height={228}
        />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex">
        <div className="icon-18 me-2 ">
          <img
            src={`/cdn/assets/icons/dashboard/reports_icon.svg`}
            alt=""
          />
        </div>
        <div>
          <div className="fs-18 fw-600 fc-blue">Sectors of interest</div>
          <div className="fs-12 fw-400 fc-black ">
            Industry sectors that your most ordered reports belong to
          </div>
        </div>
      </div>
      {status_api === 'done' && _.isArray(piValues) && !piValues.length > 0 ? (
        <ComingSoon orderReport={true} message="No report history details are available. Please start ordering reports">
          {getChart()}
        </ComingSoon>
      ) : status_api === 'done' && getChart()}
    </>
  );
}

export default SectorInterest;
