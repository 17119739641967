import { SET_CONFIG } from "../../actions/config/configActions";

const initialState = {
  config: null
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        config: action.payload
      };
    default:
      return state;
  }
};

export default configReducer;
