import React from "react";

const WatchListblur = (props) => {
  return (
    <div className="position-relative">
      <div
        style={{
          opacity: "0.2",
          color: "transparent",
          textShadow: "0 0 10px #1246ff",
        }}
      >
        {props.children}
      </div>
      <div className="position-absolute h-100 w-100" style={{ top: 0 }}>
        <div className="h6 d-flex w-100 h-100 font-weight-bold justify-content-center align-items-center">
          You have not watchlisted any company. Please add companies to your
          watchlist
        </div>
      </div>
    </div>
  );
};

export default WatchListblur;
