import React, { Component } from "react";

import AlertTable from "./AlertTable";

class StockAudit extends Component {
  convertKeytoFiled = key => {
    if (key && typeof key === "string") {
      return key.split("_").join(" ");
    } else {
      return "";
    }
  };

  getBodyData = () => {
    const { details } = this.props;
    let body = [];
    if (details && details[0]) {
      details.forEach(ele => {
        body.push(["Audit Observation", ele.audit_observation]);
        body.push(["Auditor Name", ele.auditor_name]);
        body.push(["Name Of Report", ele.name_of_report]);
        body.push(["Observation Category", ele.observation_category]);
        body.push([
          "Process Category",
          ele.observation_category_process_category
        ]);
        body.push(["Period", ele.period]);
        body.push(["Report Issue Date", ele.report_issue_date]);
      });
    }
    return body;
  };

  getAlertTableUI = () => {
    const { text } = this.props;
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text
    };

    return (
      <AlertTable
        headerData={[]}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <div>{this.getAlertTableUI()}</div>;
  }
}

export default StockAudit;
