import React from "react";
import _ from "lodash";

class AlertTable extends React.Component {
  convertKeytoFiled = (key) => {
    if (key && typeof key === "string") {
      return key.split("_").join(" ");
    } else {
      return "";
    }
  };

  getHeaderUI = () => {
    const { headerData, extraOptions } = this.props;

    return (
      <thead
        className={`${
          extraOptions && extraOptions.headerClasses
            ? extraOptions.headerClasses
            : ""
        }`}
      >
        <tr
          className={`${
            extraOptions && extraOptions.headerRowClasses
              ? extraOptions.headerRowClasses
              : ""
          }`}
          key={0}
        >
          {this.getHeaderRowCell(headerData, 0, "name")}
        </tr>
      </thead>
    );
  };

  getHeaderRowCell = (row, rowIndex, keyName) => {
    let cellArr = [];
    row.forEach((element, cellIndex) => {
      cellArr.push(
        <th
          key={`${rowIndex}-${cellIndex}`}
          className={`${this.getCellClasses(
            cellIndex,
            "header"
          )} ${element.rightAlign && "text-right"}`}
        >
          {/* {keyName ? element[keyName] : element} */}
          {this.convertKeytoFiled(
            _.isString(element)
              ? element
              : _.isObject(element)
              ? element[keyName]
              : "---"
          )}
        </th>
      );
    });

    return cellArr;
  };

  getCellClasses = (index, key) => {
    const { headerData } = this.props;
    let css = "";
    if (
      headerData[index] &&
      headerData[index].addCss &&
      headerData[index].addCss[key]
    ) {
      css = headerData[index].addCss[key];
    }
    return css;
  };

  getNormData = (element) => {
    let data = "";
    if (_.isArray(element)) {
      data = _.map(element, (v, n) => {
        return !_.isObject(v) && <div key={n}> {v}</div>;
      });
    } else if (_.isObject(element)) {
      if(element.html) {
        data = element.html
      } else {
        data = '';
      }
    } else {
      data = element
    }
    return data;
  };

  getRowCell = (row, rowIndex, keyName) => {
    let cellArr = [];

    row.forEach((element, cellIndex) => {
      cellArr.push(
        <td
          key={`${rowIndex}-${cellIndex}`}
          className={`${this.getCellClasses(cellIndex, "body")}`}
        >
          {/* {keyName ? element[keyName] : element === false ? "false" : element} */}
          {/* {keyName ? element[keyName] : element === false ? "false" : 
          typeof element==='object' && element.length>0 && element !== null? (element.map((val, key)=>{
            return <div>{val}</div>
          })) :element  
          } */}

          {keyName
            ? element[keyName]
            : element === false
            ? "false"
            : this.getNormData(element)
          // typeof element !== "string" && element !== null && element
          // ? _.isArray(element)
          //   ? _.map(element, (val) => <div>{val}</div>)
          //   : ""
          // :
          // element
          }
        </td>
      );
    });

    return cellArr;
  };

  getBodyUI = () => {
    const { bodyData, extraOptions } = this.props;

    return (
      <tbody
        className={`${
          extraOptions && extraOptions.bodyClasses
            ? extraOptions.bodyClasses
            : ""
        }`}
        style={{ fontSize: "12px" }}
      >
        {bodyData.map((row, rowIndex) => {
          return (
            <tr
              className={`${
                extraOptions && extraOptions.bodyRowClasses
                  ? extraOptions.bodyRowClasses
                  : ""
              }`}
              key={rowIndex}
            >
              {this.getRowCell(row, rowIndex)}
            </tr>
          );
        })}
      </tbody>
    );
  };

  getFooterUI = () => {
    const { footerData, extraOptions } = this.props;
    return (
      <tfoot
        className={`${
          extraOptions && extraOptions.footerClasses
            ? extraOptions.footerClasses
            : ""
        }`}
      >
        <tr
          className={`${
            extraOptions && extraOptions.footerRowClasses
              ? extraOptions.footerRowClasses
              : ""
          }`}
          key={"footer"}
        >
          {footerData.map((item, footIndex) => (
            <th
              key={`footer-${footIndex}`}
              className={`${this.getCellClasses(footIndex, "footer")}`}
            >
              {item}
            </th>
          ))}
        </tr>
      </tfoot>
    );
  };

  render() {
    const { headerData, bodyData, extraOptions, footerData } = this.props;
    /**Header Data Format 
     const headerData = [
        {
          name: '',
          addCss: {
            body: '',
            header: '',
            footer: ''
          }
        }
      ];
    */

    /** Body Data Format
      const bodyData = [
        ['cell1', 'cell2'],
        ['cell1']
      ]
    */

    /** Footer Data Format
      const bodyData = ['cell1', 'cell2']
    */

    /** ExtraOption Format
      const extraOptions = {
        title: '',
        tableClasses: '',
        headerClasses: '',
        headerRowClasses: '',
        bodyClasses: '',
        bodyRowClasses: ''
      }
     */

    return (
      <>
        <table
          key={`custom-table-${
            extraOptions && extraOptions.title ? extraOptions.title : "default"
          }`}
          className={`table table-bordered table-striped  table-responsive ${
            extraOptions && extraOptions.tableClasses
              ? extraOptions.tableClasses
              : ""
          }`}
          style={{ fontSize: "12px" }}
        >
          {headerData && headerData.length > 0 && this.getHeaderUI()}
          {bodyData && bodyData.length > 0 && this.getBodyUI()}
          {footerData && footerData.length > 0 && this.getFooterUI()}
        </table>
      </>
    );
  }
}

export default AlertTable;
