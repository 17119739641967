import React, { Component } from "react";
import _ from "lodash";

export default class PoliticalExport extends Component {
  render() {
    const detail =
      this.props &&
      this.props.details instanceof Array &&
      this.props.details.length > 0
        ? this.props.details
        : [];
    const alertComponent = (
      <table className="table table-responsive">
        <thead>
          <tr>
            <th className="text-capitalize">
              {_.join(_.split("candidate_name", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("age", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("son_or_daughter_or_wife_of", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("constituency_name", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("party", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("name_enrolled_as_voter_in", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("election", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("contact_number", "_"), " ")}
            </th>
            <th className="text-capitalize">
              {_.join(_.split("address", "_"), " ")}
            </th>
          </tr>
        </thead>
        <tbody>
          {_.map(detail, (data, key) => {
            return (
              <tr key={key}>
                <td>{data.candidate_name ? data.candidate_name : "---"}</td>
                <td>{data.age ? data.age : "---"}</td>
                <td>
                  {data.son_or_daughter_or_wife_of
                    ? data.son_or_daughter_or_wife_of
                    : "---"}
                </td>
                <td>
                  {data.constituency_name ? data.constituency_name : "---"}
                </td>
                <td>{data.party ? data.party : "---"}</td>
                <td>
                  {data.name_enrolled_as_voter_in
                    ? data.name_enrolled_as_voter_in
                    : "---"}
                </td>
                <td>{data.election ? data.election : "---"}</td>
                <td>{data.contact_number ? data.contact_number : "---"}</td>
                <td>{data.address ? data.address : "---"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    return alertComponent;
  }
}
