import React, { Component } from "react";
import moment from "moment";

import AlertTable from "./AlertTable";

class OtherFinesAlert extends Component {
  getDateFormat = date => {
    let format = "DD-MM-YYYY";
    return date ? moment(date).format(format) : "---";
  };

  getBodyData = otherFineData => {
    let bodyData = [];
    if (otherFineData.competent_authority) {
      bodyData.push(["Competent Authority", otherFineData.competent_authority]);
    }
    if (otherFineData.regulatory_actions_date_of_order) {
      let obj = otherFineData.regulatory_actions_date_of_order;
      if (obj.date) {
        bodyData.push(["Date", this.getDateFormat(obj.date)]);
      }
      if (obj.label) {
        bodyData.push(["Action", obj.label]);
      }
    }
    if (otherFineData.regulatory_charges) {
      bodyData.push(["Charges", otherFineData.regulatory_charges]);
    }
    return bodyData;
  };

  getAlertTableUI = () => {
    const { text, details } = this.props;
    let otherFineData = details && details[0] ? details[0] : [];
    const bodyData = this.getBodyData(otherFineData);
    const extraOptions = {
      title: text
    };

    return (
      <AlertTable
        headerData={[]}
        bodyData={bodyData}
        extraOptions={extraOptions}
        footerData={[]}
      />
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default OtherFinesAlert;
