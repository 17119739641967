import React, { useEffect, useState } from "react";
import _ from "lodash";
import { withRouter, useHistory } from "react-router-dom";
import { Loader } from "react-loaders";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { connect } from "react-redux";
import service from "../../../../../utils/service";
import allPaths from "../../../../../route/allPaths";
import OrderReportFromDashboard from "../order_report_dashboard";
import Handle403 from "../../../../../components/Handle403";

const RecentOrders = (props) => {
  const [produts, setProducts] = useState({
    status: "loading",
    res: [],
    total: "",
  });
  const [modal, setModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getProductApiData()
  }, []);

  const reportClassName = (type) => {
    let className = "";
    if (type === "c-360") {
      className = "c-360";
    } else if (type === "c-docs") {
      className = "c-docs";
    } else if (type === "c-trust") {
      className = "c-trust";
    } else {
      className = "default-report";
    }
    return className;
  };


  const getProductApiData = async () => {
  
    let API_URL = "v1/admin/products";
    let params = {
      per_page: 3,
    };
    let res = await service("get", API_URL, params);
    if (res) {
      let status =
        _.get(res, "status") === 403
          ? "403"
          : _.isArray(res.data) && res.data.length > 0
          ? "done"
          : "no-data";
      setProducts({ res: res.data, status, total: res.total_entries });
    }
  };

  const handleClick = (report, report_type) => {
    if (report_type === "c-docs" || report_type === "advanced-timeline") {
      let party_id = _.get(report, "attributes.party_id", "");
      let url = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}/timelines`;
      history.push(url);
    } else {
      trigger_report_download(report);
    }
  };
  const trigger_report_download = async (report) => {
    let DOCUMENTS_API_URL = `v1/report/products/${report.id}/download`;
    let res = await service("get", DOCUMENTS_API_URL);

    if (res) {
      let type = _.get(report, "attributes.report_type", "");
      let party_id = _.get(report, "attributes.party_id", "");
      let url = "";
      if (_.get(res, "meta.storage_type", "") === "EFS") {
        if (type) {
          if (type === "c-docs" || type === "advanced-timeline") {
            url = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}/timelines`;
          } else {
            url = `${allPaths.PDF_ROUTE}?slug=${report.id}`;
          }
        }
      } else if (_.get(res, "meta.storage_type", "") === "S3") {
        window.open(_.get(res, "data", ""));
      }
      if (url) {
        history.push(url);
      }
    }
  };

  const navigateProducts = (id) => {
    history.push(`${allPaths.PARTY_ROOT_ROUTE}/${id}`);
  };

  const navigateToAllProducts = () => {
    history.push(`${allPaths.PRODUCTS_ROUTE}`);
  };

  let show_products =
    produts.status === "done" && produts.total > 0 ? true : false;

  const get_header = () => {
    return (
      <div className="card-title fc-blue text-truncate">Recently Ordered</div>
    );
  };

  const get_order_icon_ui = (each) => {
    let status = _.get(each, "attributes.status");

    if (status === "done") {
      return (
        <i
          onClick={() =>
            handleClick(each, _.get(each, "attributes.report_type"))
          }
          className="fa fa-download  text-primary cursor-p"
        ></i>
      );
    } else if (status === "ordered") {
      return (
        <div className="h6 text-primary">
          <FontAwesomeIcon icon={faSpinner} spin fixedWidth={false} size="xs" />
        </div>
      );
    }
  };

  return (
    <div className="card h-100 mb-3">
      <div className="card-body p-2">
        {show_products && get_header()}
        {show_products && (
          <>
            {_.isArray(produts.res) &&
              produts.res.map(
                (each, idx) =>
                  idx < 3 && (
                    <div className="d-flex align-items-center gap-3 pb-3">
                      <div className="">
                        <img
                          src={`/cdn/assets/icons/product/${reportClassName(
                            each.attributes.report_type
                          )}.svg`}
                          width={24}
                          className="rounded"
                          alt=""
                        />
                      </div>
                      <div className=" text-truncate">
                        <div
                          className="widget-heading text-truncate me-2"
                          onClick={() =>
                            navigateProducts(_.get(each, "attributes.party_id"))
                          }
                        >
                          <a href="#" className="cursor-p fs-12">
                            {_.get(each, "attributes.party_name")}
                          </a>
                        </div>
                        <div className="widget-subheading fs-10">
                          {`Ordered ${
                            each.attributes.created_at
                              ? moment(each.attributes.created_at).fromNow()
                              : "---"
                          }`}
                        </div>
                      </div>
                      <div className="ms-auto">{get_order_icon_ui(each)}</div>
                    </div>
                  )
              )}
          </>
        )}
        {produts.status === "loading" && (
          <div className="text-center">
            <Loader type="line-scale" />
          </div>
        )}
        {produts.status === "no-data" && (
          <div className="d-flex flex-column align-items-center h-100 p-3">
            <img src={`/cdn/assets/images/products_nodata.png`} width={80} />
            <div className="fs-10 pt-2 align-self-center text-center">
              Looks like you haven't ordered any product
            </div>
            <button
              className="btn btn-outline-primary fs-14 mt-3"
              onClick={() => setModal(true)}
            >
              Place an order
            </button>
          </div>
        )}
        {produts.status === "403" && (
          <div>
            <div className="card-title px-2 pb-2">Products Overview</div>
            <Handle403 />
          </div>
        )}
      </div>
      <OrderReportFromDashboard show={modal} close={() => setModal(false)} />
      {show_products && (
        <div className="card-footer p-1 pr-2">
          <div className="btn-actions-pane-right">
            <button
              className="btn btn-link p-0 fs-10"
              onClick={() => navigateToAllProducts()}
            >
              {`View All: ${produts.total}`}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: _.get(state, "my_profile.profileData.policy")
})

export default connect(mapStateToProps)(withRouter(RecentOrders));

