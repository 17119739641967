import React from "react";
import moment from "moment";

import AlertTable from "./AlertTable";

class EpfoAlert extends React.Component {
  getDateFormat = (date) => {
    let format = "MMM YYYY";
    return date ? moment(date, "YYYY-MM-DD").format(format) : "---";
  };

  getBodyData = () => {
    const { details, keyArr } = this.props;
    let bodyData = [];
    details.forEach((element) => {
      let row = [];
      if (keyArr && keyArr.length === 3) {
        if (element[keyArr[0]] === "overall_info") {
        } else {
          row.push(this.getDateFormat(element[keyArr[0]]));
          row.push(element[keyArr[1]]);
          row.push(element[keyArr[2]]);
        }
      } else {
        row.push(this.getDateFormat(element.financial_year));
        row.push(element.tnw_value);
      }
      bodyData.push(row);
    });
    return bodyData;
  };

  getAlertTableUI = () => {
    const { text, headerArr } = this.props;
    const headerData = headerArr
      ? headerArr
      : [
          {
            name: "Wage Month",
          },
          {
            name: "Employees Count",
          },
          {
            name: "Amount Paid",
          },
        ];
    const bodyData = this.getBodyData();
    const extraOptions = {
      title: text,
    };

    return (
      <>
        <table className="table table-bordered mb-3">
          <thead>
            <tr>
              {/* <th></th> */}
              <th>EPFO#</th>
              <th>% drop in employees</th>
            </tr>
          </thead>
          <tbody>
            {this.props.details.map((element, elementKey) => {
              return (
                element["_ref"] === "overall_info" && (
                  <tr key={elementKey}>
                    {/* <td>{element['_ref']}</td> */}
                    <td>{element["reg"]}</td>
                    <td>
                      {element["ratio"] && (element["ratio"] * 100).toFixed(2)}
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
        <AlertTable
          headerData={headerData}
          bodyData={bodyData}
          extraOptions={extraOptions}
          footerData={[]}
        />
      </>
    );
  };

  render() {
    return <>{this.getAlertTableUI()}</>;
  }
}

export default EpfoAlert;
