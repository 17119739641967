import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CkEditor = ({ required, onFunc, key_value, initData, readOnly }) => {
  const handleChange = (event, editor) => {
    const data = editor.getData();
    if (key_value) {
      onFunc(key_value, data);
    } else {
      onFunc(data);
    }
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      onChange={handleChange}
      data={initData}
      config={{
        toolbar: [
          "heading",
          "|",
          "insertTable",
          "bold",
          "italic",
          "|",
          "undo",
          "redo",
        ],
      }}
      required={required}
      disabled={readOnly}
    />
  );
};

export default CkEditor;
