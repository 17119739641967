import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import allPaths from "../../../../../route/allPaths";
import { Mixpanel } from "../../../../../utils/mixpanel";

const ReportMetrics = (props) => {

  const total_records = _.get(props, "meta.total_records") || 0;
  const c360 = _.get(props, "meta.facets.report_types.c-360") || 0;
  const cdocs = _.get(props, "meta.facets.report_types.c-docs") || 0;
  const ctrust = _.get(props, "meta.facets.report_types.c-trust") || 0;
  const history = props.history;

  const handleOnClick = () => {
    Mixpanel.track("click_order_history", { "page_name": "Dashboard", "source": "reports" })
    history.push(allPaths.PRODUCTS_ROUTE)
  }

  let arr = [
    {
      label: 'C-360',
      count: c360,
      color: 'fc-green'
    },
    {
      label: 'C-Docs',
      count: cdocs,
      color: 'fc-pink'
    },
    {
      label: 'C-Trust',
      count: ctrust,
      color: 'fc-green1'
    }
  ]

  return (
    <>
      <div className="d-flex mb-3">
        <div className="icon-22 me-2 ">
          <img src={`/cdn/assets/icons/dashboard/reports_icon.svg`} alt="" />
        </div>
        <div>
          <div className="fs-18 fw-600 fc-blue">Report Orders</div>
          <div className="fs-12 fw-400 fc-black ">
            Your most ordered reports.
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="border-bottom pt-2 pb-2 d-flex align-items-center">
          <span className="fs-32 fw-800 fc-blue me-3">
            {total_records}
          </span>
          <span className="fs-18 fw-400 fc-grey ">
            Total reports ordered
          </span>

        </div>
        <div className="p-3">
          {arr.map((a, index) => (
            <div key={index}>
              <span className={`fs-28 fw-800 me-3 ${a.color}`}>{a.count}</span>
              <span className="fs-14 fw-500 fc-grey">{a.label}</span>
            </div>
          ))}
        </div>
        {total_records > 0 &&
          <div className="d-flex justify-content-end">
            <div>
              <button className="cw-btn cw-btn-link" onClick={handleOnClick}>
                Order History
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: _.get(state, "dashboard.product.result.data"),
    meta: _.get(state, "dashboard.product.result.meta"),
    loader: _.get(state, "dashboard.product.status"),
  };
}

export default connect(mapStateToProps)(withRouter(ReportMetrics));
