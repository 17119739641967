import React from "react";

const FormatSix = ({ details }) => {
  if (!details || !Array.isArray(details) || details.length === 0) {
    return <p>No details to display</p>;
  }

  const getTable = (arr) => {
    if (!Array.isArray(arr)) {
      return null;
    }

    const tableHeaders = Object.keys(arr[0]);

    return (
      <table className="mb-2 table table-striped responsive">
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header.replace(/_/g, " ")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {arr.map((rating, rowIndex) => (
            <tr key={rowIndex}>
              {tableHeaders.map((header, colIndex) => (
                <td key={colIndex}>
                  {typeof rating[header] === "object" ? (
                    getTable([rating[header]])
                  ) : header.toLowerCase() === "rating_rationale" ? (
                    <a href={rating[header]}>{rating[header]}</a>
                  ) : (
                    <>{rating[header]}</>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {details.map((mainHeader, mainIndex) => (
        <div key={mainIndex}>
          {Object.keys(mainHeader).map((mainHeaderKey, mainHeaderIndex) => (
            <div key={mainHeaderIndex}>
              {mainHeader[mainHeaderKey].map((subHeader, subIndex) => (
                <tr key={subIndex}>
                  {Object.keys(subHeader).map((subKey, subKeyIndex) => (
                    <tr key={subKeyIndex}>
                      {Array.isArray(subHeader[subKey]) ? (
                        getTable(subHeader[subKey])
                      ) : (
                        <h4>{subHeader[subKey]}</h4>
                      )}
                    </tr>
                  ))}
                </tr>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FormatSix;
