import React from "react";
import DynamicTable from './dynamicTable';

const FormatThree = ({ details }) => {
  if (!details || details.length === 0) {
    return null;
  }
  const firstObjectKey = Object.keys(details[0])[0];
  return (
    <div>
      {typeof details[0][firstObjectKey] === 'object' && !Array.isArray(details[0][firstObjectKey]) ? (
        <div>
          {Object.entries(details[0][firstObjectKey]).map(([key, data]) => (
            <DynamicTable key={key} data={data} tableName={key} />
          ))}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <h6>Not A Valid Alert!</h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormatThree;
