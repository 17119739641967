import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import allPaths from '../../../../../route/allPaths';
import InEligible from '../../../../Eqaro-setup/components/Ineligible';

const Eqaro = (props) => {
  const [eligible_status, setUpdateTheStatus] = useState('not-triggered');

  let step = _.get(props, "eqaro_step_info.result");

  useEffect(() => {
    if (step === 'eligible') {
      setUpdateTheStatus('eligible')
    } else if(step === 'ineligible') {
      setUpdateTheStatus('ineligible')
    } else {
      setUpdateTheStatus('complete-profile')
    }
  }, [step])

  // const trigger_API_call = async () => {
  //   let elegibility_API_URL = '/v1/eligibility_check'
  //   const res = await service('get', elegibility_API_URL);
  //   let eligible = _.get(res, "data.status");
  //   if (eligible === 'eligible') {
  //     setUpdateTheStatus('eligible')
  //   } else {
  //     setUpdateTheStatus('not-eligible')
  //   }
  // }

  const history = useHistory();

  const navivateURL = () => {
    let url = allPaths.RUBEE_BOSS_SETUP_ROUTE;
    // if (eligible_status === 'eligible') {
    //   url = allPaths.CONSENT_ROUTE
    // }
    history.push(url)
  }

  const getInfoUI = () => {
    let text = '';
    if (eligible_status === 'eligible') {
      text = 'You have completed the profile and are eligibile to get a gaurantee.'
    } else if(eligible_status === 'ineligible'){
      text = <InEligible is_dashboard_page={1}/>
    } else {
      text = 'Complete your profile to get a gaurantee'
    }
    return text
  }

  const getButtonUI = () => {
    let text = '';
    if(eligible_status === 'eligible') {
      text = 'Complete Consent'
    } else {
      text = 'Complete Profile'
    }
    return  (
      <button
        type="button"
        className=" mt-2 cw-btn cw-btn-primary"
        onClick={navivateURL}
      >
        {text}
      </button>
    )
  }

  const getHeaderText = () => {
    let text = 'Let us know you better!';
    // if(eligible_status === 'eligible') {
    //   text = 'Consent'
    // }
    return text;
  }

  return (
    <div className='h-100'>
      <div className="mb-2">
        <div className="d-flex">
          <div className='w-100 d-flex'>
            <div>
              <div className='d-flex justify-content-between'>
                <div className="fs-24 fw-600 fc-blue">
                  {getHeaderText()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='fs-16 ' style={{ padding: '16px' }} >
        {getInfoUI()}
      </div>
      { eligible_status !== 'ineligible' && getButtonUI()}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    eqaro_step_info: _.get(state, "my_profile.eqaro_step_info")
  }
}

export default connect(mapStateToProps)(Eqaro)