import React from 'react';
import { Progress } from "reactstrap";
import _ from 'lodash';

const ProgressBar = ({ data }) => {
  return (
    <Progress className="progress-bar-sm" multi>
      {_.isArray(data) && data.map((item, index) => (
        <Progress bar color={item.color} value={item.value} key={index}>
          {/* {item.label} */}
        </Progress>
      ))}
    </Progress>
  )
}

export default ProgressBar