import moment from 'moment';
export default function daysAgoDateFormat(lastLoginTime) {

  if (lastLoginTime.isValid()) {
    const currentTime = moment();
    const daysAgo = currentTime.diff(lastLoginTime, 'days');
    const hoursAgo = currentTime.diff(lastLoginTime, 'hours');
    const minutesAgo = currentTime.diff(lastLoginTime, 'minutes');
    let lastLoginMessage;
    if (minutesAgo < 60) { 
      if (minutesAgo < 1) {
        lastLoginMessage = 'Just now';
      } else {
        lastLoginMessage = `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
      }
    } else if (hoursAgo < 24) { 
      if (hoursAgo >= 1) {
        lastLoginMessage = `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
      } else {
        lastLoginMessage = 'Today';
      }
    } else if (daysAgo === 1) { 
      lastLoginMessage = 'Yesterday';
    } else { 
      lastLoginMessage = `${daysAgo} days ago`;
    }
    return (lastLoginMessage);
  }
  return "";
}
