import _ from "lodash";
import React, { Component } from "react";

class DefaultAlertDetails extends Component {

  getAlertDetailsUI = () => {
    const { details } = this.props;
    const detailsString = JSON.stringify(details, null, 2);
   
    return (
      <div>
        <pre>
          {detailsString}
        </pre>
      </div>
    );
  };

  render() {
    return <>{this.getAlertDetailsUI()}</>;
  }
}

export default DefaultAlertDetails;
