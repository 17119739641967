import React, { useState, useEffect } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

import brand_colors from "../../../../../utils/colors";
import service from "../../../../../utils/service";
import Handle403 from "../../../../../components/Handle403";

const ReactHighcharts = require("react-highcharts");

const Score_Overview = ({ series }) => {
  const [portfolioData, setPortfolioData] = useState({
    status: "loading",
    res: {},
  });

  useEffect(() => {
    getPortfolioAPI();
  }, []);

  const getPortfolioAPI = async () => {
    let API_URL = "v1/cases/case_count/risk_type";
    let res = await service("get", API_URL);
    if (res.status === 403) {
      setPortfolioData({
        status: "403",
        res: data,
      });
    }
    let data = _.get(res, "data");
    let meta = _.get(res, "meta");
    if (data && meta) {
      setPortfolioData({
        status: "done",
        res: data,
      });
    }
  };

  const getChartColor = (key) => {
    let color = "";
    switch (key) {
      case "high_risk":
        return (color = brand_colors.red);
      case "low_risk":
        return (color = brand_colors.green);
      case "medium_risk":
        return (color = brand_colors.amber);
      default:
        return (color = brand_colors.suceess);
    }
  };

  const convertConfigData = () => {
    let convertedData = [];
    _.isObject(portfolioData.res) &&
      Object.keys(portfolioData.res).map((key, value) => {
        let obj = {
          name: _.capitalize(key).split("_")[0],
          y: portfolioData.res[key],
          color: getChartColor(key),
        };
        convertedData.push(obj);
        return null;
      });
    return convertedData;
  };

  let data = convertConfigData();

  const config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 200,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<div><b>{point.name}</b>: <br/> <div>{point.y}</div></div>",
        },
      },
    },
    series: [
      {
        name: "Cases",
        colorByPoint: true,
        data: data,
      },
    ],
  };

  const displayRiskMessage = () => {
    let portfolioObj = portfolioData.res;
    if (
      portfolioObj.low_risk === 0 &&
      portfolioObj.medium_risk === 0 &&
      portfolioObj.high_risk === 0
    ) {
      return (
        <div className="d-flex justify-content-center align-items-center h-50 text-center mt-3 opacity-3">
          Looks like you don't have any risk score
        </div>
      );
    } else {
      return <ReactHighcharts config={config} />;
    }
  };
  const ScoreOverview = () => {
    switch (portfolioData.status) {
      case "done":
        return displayRiskMessage();
      case "403":
        return <Handle403/>
      default:
        return <Skeleton count={5} />;
    }
  };
  return (
    <div className="card h-100 mb-3 p-0">
      <div className="card-body p-1 m-0">
        <div className="card-title p-2 m-0">Portfolios Score Overview</div>
        {ScoreOverview()}
      </div>
    </div>
  );
};

export default Score_Overview;
