import React, { useState, useEffect } from "react";
import _ from "lodash";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import Select from "react-select-new";

import CKEditor from "../../../src/components/CkEditor";
import RadioField from "./radio-field";
import InputField from "./input-field";
import PartySearch from "../PartySearch";
const FormArr = ({
  defaultValueObj,
  form_fields,
  custom_error_message,
  children,
  action_buttons,
}) => {
  const [info, setInfo] = useState({});
  const [show_form_error, setFormError] = useState(false);
  const [inputType,setInputType]=useState("text")

  useEffect(() => {
    makeDefaultInfoOBJ();
  }, []);

  const makeDefaultInfoOBJ = async () => {
    let obj = {};
    if (_.isArray(form_fields)) {
      form_fields.forEach((each) => {
        let key = each.key;
        if (key && !obj[key]) {
          obj[key] = "";
        }
        if (defaultValueObj && defaultValueObj[key]) {
          obj[key] = defaultValueObj[key];
        }
      });
    }
    setInfo(obj);
  };

  const is_invalid_form = () => {
    let is_disabled = false;
    let index = 0;
    while (index < form_fields.length) {
      let item = form_fields[index];
      let key = item.key;
      let value = info[key] || "";
      let regex = item.regex;
      let is_mandotory = item.mandatory;
      if (!fieldFalseCheck(regex, value, key, is_mandotory)) {
        is_disabled = true;
        break;
      }
      index++;
    }
    return is_disabled;
  };

  const fieldFalseCheck = (regex, value, key, is_mandotory = false) => {
    let boolCheck = false;
    if (value) {
      if (regex) {
        boolCheck = is_regex_verified(regex, value, key);
      } else {
        boolCheck = true;
      }
    } else if (!is_mandotory) {
      boolCheck = true;
    }
    return boolCheck;
  };

  const is_regex_verified = (regex, value, key) => {
    let regexExp = new RegExp(regex);
    let boolCheck = false;
    let testVal = "";
    let uppercase_arr = ["PAN", "GSTIN", "CIN"];
    if (uppercase_arr.includes(key)) {
      testVal = value.toUpperCase();
    } else {
      testVal = value;
    }
    if (testVal && regexExp.test(testVal)) {
      boolCheck = true;
    }
    return boolCheck;
  };

  const updateInfo = (key, value) => {
    setInfo((prev_info) => {
      return { ...prev_info, [key]: value };
    });
    setFormError(false);
  };

  const selectOption = (p_id, s_obj) => {
    if (s_obj) {
      setInfo((prev_info) => {
        return { ...prev_info, ["party"]: s_obj };
      });
    } else {
      setInfo((prev_info) => {
        return { ...prev_info, ["party"]: "" };
      });
    }
  };

  const getComponentUI = (item, index) => {
    let key = item.key;
    let value = info[key] || "";
    let ui = "";
    let label = item.label;
    let data = item.value;
    let type = item.type || "text";
    let is_mandotory = item.mandatory;
    let component = item.component;
    let options = item.option;
    let min = item.min;
    let max = item.max;
    let abr = item.abr;
    let disabled = item.disabled;
    let custom_e_message = _.get(custom_error_message, key, "");

    const handleEditorChange = (key, event) => {
      if (key) {
        updateInfo(key, event);
      }
    };

    switch (component) {
      case "radio":
        ui = (
          <RadioField
            label={label}
            index={index}
            options={options}
            value={value}
            updateInfo={updateInfo}
            disabled={disabled}
            show_form_error={show_form_error}
            is_mandotory={is_mandotory}
            custom_e_message={custom_e_message}
          />
        );
        break;

      case "dropdown":
        ui = (
          <div className="row my-3" key={index}>
            <div className="col-12">
              <div>
                <Select
                  placeholder={`${label} ${is_mandotory ? "*" : ""}`}
                  options={options}
                  onChange={(s_v) => updateInfo(key, s_v)}
                />
                {!value && is_mandotory && show_form_error && (
                  <p className="text-danger m-2">{`Please fill the field`}</p>
                )}
                {custom_e_message && (
                  <p className="text-danger m-2">{custom_e_message}</p>
                )}
              </div>
            </div>
          </div>
        );
        break;

      case "search":
        ui = (
          <div className="row mb-3" key={index}>
            {/* <label className="col-md-5 col-12 col-form-label">{label}</label> */}
            <div className="col-12">
              <div>
                <PartySearch placeholder={label} selectOption={selectOption} />
                {!value && is_mandotory && show_form_error && (
                  <p className="text-danger m-2">{`Please Choose the Party`}</p>
                )}
                {custom_e_message && (
                  <p className="text-danger m-2">{custom_e_message}</p>
                )}
              </div>
            </div>
          </div>
        );
        break;

      case "checkbox":
        const isChecked = value && value !== "" ? true : false;
        ui = (
          <div className="row mt-3" key={index}>
            <label className="col-md-5 col-12 col-form-label ">{label}</label>
            <div className="col-md-7 col-12 mt-2">
              <div className="">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => updateInfo(key, !isChecked)}
                />
                {is_mandotory && !isChecked && show_form_error && (
                  <p className="text-danger m-2">{`Please Click the checkbox`}</p>
                )}
                {custom_e_message && (
                  <p className="text-danger m-2">{custom_e_message}</p>
                )}
              </div>
            </div>
          </div>
        );
        break;

      case "upload_file":
        ui = (
          <InputField
            label={label}
            index={index}
            options={options}
            value={value}
            updateInfo={updateInfo}
            abr={abr}
            key_value={key}
            type={type}
            disabled={disabled}
            show_form_error={show_form_error}
            is_mandotory={is_mandotory}
            custom_e_message={custom_e_message}
          />
        );
        break;

      case "ck_editor":
        ui = (
          <>
            <label className=" col-md-5 col-12 col-form-label">
              {`${label} ${is_mandotory ? "*" : ""}`}
            </label>
            <CKEditor
              id="editor"
              key_value={key}
              required={is_mandotory}
              onFunc={handleEditorChange}
            />
            {show_form_error && !value && is_mandotory && (
              <p className="text-danger m-2">{`Please fill the field`}</p>
            )}
            {custom_e_message && (
              <p className="text-danger m-2">{custom_e_message}</p>
            )}
          </>
        );
        break;
      case "date":
        ui = (
          <div className="row">
            <div className="col-12">
              <div className="input-float-group">
                <input
                type={inputType}
                  name={label}
                  value={value}
                  initialvalue={value}
                  min={min}
                  max={max}
                  onChange={(e) => !disabled && updateInfo(key, e.target.value)}
                  className="form-input-float-control"
                  required
                  onFocus={() => setInputType("date")}
                  onBlur={() => setInputType("text")}
                />
                <label className="form-input-control-placeholder" for="name">
                  {label} {is_mandotory ? "*" : ""}
                </label>
              </div>

              {show_form_error && !value && is_mandotory && (
                <p className="text-danger m-2">{`Please fill the field`}</p>
              )}
              {custom_e_message && (
                <p className="text-danger m-2">{custom_e_message}</p>
              )}
            </div>
          </div>
        );
        break;

      default:
        ui = (
          <div key={index} className="row">
            {/* <label className=" col-md-5 col-12 col-form-label">
              {`${label} ${is_mandotory ? "*" : ""}`}
            </label> */}
            {/* <div className="col-md-7 col-12">
              <InputGroup>
                <InputGroupAddon addonType="prepend">{abr}</InputGroupAddon>

                <Input
                  type={type}
                  className="form-control"
                  name={label}
                  value={value}
                  initialvalue={value}
                  onChange={(e) => !disabled && updateInfo(key, e.target.value)}
                  placeholder=""
                  autoComplete="off"
                  min={min}
                  max={max}
                  required={true}
                  disabled={disabled}
                />
              </InputGroup>

              {show_form_error && !value && is_mandotory && (
                <p className="text-danger m-2">{`Please fill the field`}</p>
              )}
              {custom_e_message && (
                <p className="text-danger m-2">{custom_e_message}</p>
              )}
            </div> */}
            <div className="col-12">
              <div className="input-float-group">
                <input
                  type={type}
                  name={label}
                  value={value}
                  initialvalue={value}
                  min={min}
                  max={max}
                  onChange={(e) => !disabled && updateInfo(key, e.target.value)}
                  className="form-input-float-control"
                  required
                />
                <label className="form-input-control-placeholder" for="name">
                  {label} {is_mandotory ? "*" : ""}
                </label>
              </div>

              {show_form_error && !value && is_mandotory && (
                <p className="text-danger m-2">{`Please fill the field`}</p>
              )}
              {custom_e_message && (
                <p className="text-danger m-2">{custom_e_message}</p>
              )}
            </div>
          </div>
        );
        break;
    }
    return ui;
  };

  const getFormUI = () => {
    let ui = [];
    if (form_fields && _.isArray(form_fields)) {
      form_fields.forEach((item, index) => {
        ui.push(getComponentUI(item, index));
      });
    }
    return ui;
  };

  const saveButtonAction = (action) => {
    if (is_invalid_form()) {
      setFormError(true);
    } else {
      action && action(info);
    }
  };

  const control_button_action = (type, action) => {
    if (type === "submit-form") {
      saveButtonAction(action);
    } else {
      action && action(info);
    }
  };

  const get_action_button_ui = () => {
    let ui_arr = [];
    if (action_buttons && _.isArray(action_buttons)) {
      action_buttons.forEach((item, index) => {
        let className = _.get(item, "class") || _.get(item, "className");
        let label = _.get(item, "label");
        let type = _.get(item, "type");
        let action = _.get(item, "action");
        let disabled = type === "submit-form" && is_invalid_form();
        ui_arr.push(
          <button
            className= {!disabled?'btn btn-primary btn-sm ml-3 mt-2':className}
            onClick={() => control_button_action(type, action)}
            key={index}
            disabled={disabled}
          >
            {label}
          </button>
        );
      });
    }
    return <div className="d-flex justify-content-end w-100">{ui_arr}</div>;
  };

  return (
    <div>
      {getFormUI()}
      {children && children}
      {get_action_button_ui()}
    </div>
  );
};

export default FormArr;
