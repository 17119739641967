import React, { Fragment, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { connect,useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer } from "react-toastify";
import { setEnableSearchBarOnHeader } from "../../reducers/ThemeOptions";
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import AppFooter from "../AppFooter";
import Routes from "../../route/Routes";
import IdleTimer from "react-idle-timer";
import ErrorPanel from "../../components/ErrorPanel";
import { withRouter } from "react-router-dom";
import StaySignInModal from "../../components/StaySignInModal";
import logout from "../../utils/logout";
const StepComponent = ({ title, description, htmlDescription }) => {
  return (
    <div className="p-0 m-0">
      <div className="h5 text-primary">{title}</div>
      <div className="text-dark">{ReactHtmlParser(description)}</div>
      {htmlDescription && <div>{htmlDescription}</div>}
    </div>
  );
};

function AppMain(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const config = useSelector(state => state.config.config);
  const idleTimerRef = useRef(null);
  const timeout = parseInt(config&&config.INACTIVITY_TIMEOUT, 10) || 600;
  const [idleTimeout, setIdleTimeout] = useState(timeout);
  const [idleTimerCounter, setIdleTimerCounter] = useState(30);


  const openInputbox = () => {
    let openInput = _.get(props, "showSearchBar");
    const { setEnableSearchBarOnHeader } = props;
    setEnableSearchBarOnHeader(!openInput);
  };

  const { sideBarItems, profile_status } = props;
  const [status, setStatus] = useState("");
  let openInput = _.get(props, "showSearchBar");
  let enabledSideBar =
    sideBarItems && _.isArray(sideBarItems) && sideBarItems.length > 0
      ? true
      : false;

  let is_logged_in = profile_status === "done" ? true : false;



  const get_header_ui = () => {
    if (profile_status === "done") {
      return (
        <div>
          <AppHeader openInput={openInput} />
          {/* <ErrorPanel /> */}

        </div>
      );
    } 
  };

  const get_footer_ui = () => {
    if (is_logged_in) {
      return (
        <div
          className={
            enabledSideBar ? "app-footer__inner" : "app-footer__inner_full"
          }
        >
          <AppFooter openInputbox={openInputbox} openInput={openInput} />
        </div>
      );
    } 
  };

  const formatTime = (seconds) => {
    if (seconds >= 3600) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours} hours, ${minutes} minutes, ${remainingSeconds} seconds`;
    } else if (seconds >= 60) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} minutes, ${remainingSeconds} seconds`;
    } else {
      return `${seconds} seconds`;
    }
  };

  const handleIdle = () => {
    toggleModal();
  };

  const handleStaySignIn = () => {
    setIdleTimerCounter(30);
    toggleModal();
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    let timeout;
    let prevCounterBeforeReset = idleTimerCounter;
    const decrementCounter = () => {
      setIdleTimerCounter((prevCounter) => {
        if (prevCounter === 1) {
          logout();
          return prevCounterBeforeReset;
        } else {
          return prevCounter - 1;
        }
      });
      timeout = setTimeout(decrementCounter, 1000);
    };
    if (modalOpen) {
      decrementCounter();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [modalOpen]);

  return (
    <Fragment>
      <IdleTimer
        ref={idleTimerRef}
        onIdle={handleIdle}
        timeout={idleTimeout * 1000}
      />
      <div>
        {get_header_ui()}
        <div className={`app-main`}>
          <div>
            {profile_status === "done" && enabledSideBar && <AppSidebar />}
          </div>
          <div
            className={
              enabledSideBar
                ? `app-main__outer`
                : "app-main__outer app-main__outer_full"
            }
          >
            <div
              className={`app-main__Inner`}
            >
              <Routes />
            </div>
          </div>
        </div>
        <div className="app-footer d-lg-none">{get_footer_ui()}</div>

        <ToastContainer />
      </div>
      {!(props.location.pathname === "/home") && (
        <StaySignInModal
          isOpen={modalOpen}
          toogleModal={toggleModal}
          handleStaySignIn={handleStaySignIn}
          formatTime={formatTime}
          idleTimeout={idleTimeout}
          idleTimerCounter={idleTimerCounter}
        />
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    profileData: _.get(state, "my_profile.profileData"),
    showSearchBar: state.ThemeOptions.showSearchBar,
    sideBarItems: state.ThemeOptions.sideBarItems,
    profile_status: _.get(state, "my_profile.profile_status"),
    loader: _.get(state, "party.information.status"),
    partyWt: _.get(state, "my_profile.profileData.wt.party"),
    page_config: _.get(state, "my_profile.page_config", {}),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setEnableSearchBarOnHeader: (value) =>
      dispatch(setEnableSearchBarOnHeader(value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppMain));
