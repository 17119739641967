import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import _ from 'lodash';
import { useHistory } from "react-router-dom";

import BusinessWidget from "../../../../../components/BusinessWidget";
import allPaths from '../../../../../route/allPaths';
import HubSpot from "../../../../../components/Hubspot";

function BusinessInsights() {
  const [zohoBookModal, setzohoBookModal] = useState(false)
  const [tallyModal, setTallyModal] = useState(false)
  const [MicrosoftModal, setMicrosoftModal] = useState(false);

  const history = useHistory()

  const toggleZohoBook = () => {
    setzohoBookModal(!zohoBookModal)
  }

  const toggleTally = () => {
    setTallyModal(!tallyModal)
  }

  const toggleMicrosoft = () => {
    setMicrosoftModal(!MicrosoftModal)
  }

  const bsFunc = () => {
    let URL = `${allPaths.CONSENT_ROUTE}/bank-statement`
    history.push(URL)
  }

  const itrFunc = () => {
    let URL = `${allPaths.CONSENT_ROUTE}/itr-details`
    history.push(URL)
  }

  const fsFunc = () => {
    let URL = `${allPaths.CONSENT_ROUTE}/financial-statement`
    history.push(URL)
  }

  const gstFunc = () => {
    let URL = `${allPaths.CONSENT_ROUTE}/gstn-details`
    history.push(URL)
  }

  let business_info = [
    {
      img: `/cdn/assets/icons/dashboard/bsi_bs.png`,
      title: 'Integrate Bank Statements',
      summary: "Share your banking information with the platform to avail additional benefits - such as access to Credit and Guarantees.Consent data is safe and secure and in your control at all times",
      buttonFunc: bsFunc
    },
    {
      img: `/cdn/assets/icons/dashboard/bsi_itr.png`,
      title: 'Integrate IT Returns',
      summary: "Seamless fetch your IT returns data from the IncomeTax portal and allow for additional data to power your creditworthiness.",
      buttonFunc: itrFunc
    },
    {
      img: `/cdn/assets/icons/dashboard/bs-_fs.png`,
      img_width: '180px',
      title: 'Upload Financial Statements',
      summary: "Easily upload your audited financial statements to help lenders better assess your business' financial health Your financial data is secure and only accessible by those you select.",
      buttonFunc: fsFunc
    },
    {
      img: `/cdn/assets/icons/dashboard/bsi_gst.png`,
      img_width: '180px',
      title: 'Integrate GST returns',
      summary: "Plug in your GST data to enrich your credit assessment data points. Integrates directly with the GSTN portal. Your business data is safe and secure.",
      buttonFunc: gstFunc
    },
    // {
    //   img: 'https://cdn.siasat.com/wp-content/uploads/2020/04/Tally.jpg',
    //   img_width: '130px',
    //   title: 'Integrate Tally',
    //   summary: "If you run your business on Tally, integrate it now to leverage the power of Crediwatch Insights for your business.",
    //   buttonFunc: toggleTally
    // },
    // {
    //   img: `${process.env.REACT_APP_CDN_URL}/assets/icons/dashboard/bs-_fs.png`,
    //   img_width: '180px',
    //   title: 'Upload Financial Statements',
    //   summary: "Easily upload your audited financial statements to help lenders better assess your business' financial health Your financial data is secure and only accessible by those you select.",
    //   buttonFunc: fsFunc
    // },
    // {
    //   img: `${process.env.REACT_APP_CDN_URL}/assets/icons/dashboard/bsi_gst.png`,
    //   img_width: '180px',
    //   title: 'Integrate GST returns',
    //   summary: "Plug in your GST data to enrich your credit assessment data points. Integrates directly with the GSTN portal. Your business data is safe and secure.",
    //   buttonFunc: gstFunc
    // },
    {
      img: 'https://cdn.siasat.com/wp-content/uploads/2020/04/Tally.jpg',
      img_width: '130px',
      title: 'Integrate Tally',
      summary: "If you run your business on Tally, integrate it now to leverage the power of Crediwatch Insights for your business.",
      buttonFunc: toggleTally
    },
    {
      img: `/cdn/assets/icons/dashboard/zoho-books-logo.png`,
      img_width: '250px',
      title: 'Integrate Zoho Books',
      summary: "Grow your business by securely integrating your business data from Zoho Books and get rich insights on your business.",
      buttonFunc: toggleZohoBook
    },
    {
      img: `/cdn/assets/icons/dashboard/md.svg`,
      img_width: '180px',
      title: 'Integrate MS Dynamics',
      summary: "Integrate your MS Dynamics data to get rich insights on your business. Grow your business with the power of Crediwatch insights.",
      buttonFunc: toggleMicrosoft
    },
   
  ]

  const getBusinessWidgetInfoUI = () => {
    let ui_arr = [];
    if (_.isArray(business_info)) {
      business_info.forEach((item, index) => {
        let is_last_one = (index === business_info.length - 1) ? true : false;
        ui_arr.push(
          <div className={`col-md-6 ${!is_last_one && 'border-right'}`}>
            <BusinessWidget item={item} />
          </div>
        )
      })
    }
    return ui_arr
  }

  return (
    <>
      <Modal isOpen={zohoBookModal} toggle={toggleZohoBook} size={"lg"}>
        <ModalHeader toggle={toggleZohoBook}>Integrate Zoho Books</ModalHeader>
        <ModalBody>
          <HubSpot
            region="na1"
            portalId="20285543"
            formId='81f2c8ca-1c66-413b-aa6c-b5f212d511f2'
          />
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggleZohoBook}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={tallyModal} toggle={toggleTally} size={"lg"}>
        <ModalHeader toggle={toggleTally}>Integrate Tally</ModalHeader>
        <ModalBody>
          <HubSpot
            region="na1"
            portalId="20285543"
            formId='d67a35bf-f570-4e84-ac93-89ca15d253d9'
          />
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggleTally}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={MicrosoftModal} toggle={toggleMicrosoft} size={"lg"}>
        <ModalHeader toggle={toggleMicrosoft}>Integrate Microsoft</ModalHeader>
        <ModalBody>
          <HubSpot
            region="na1"
            portalId="20285543"
            formId='1a7f51a7-003f-410e-976b-28d5a40e015d'
          />
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggleMicrosoft}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <div className="p-3"
      // data-tut="dashboard-business-integration"
      >
        <div className="d-flex mb-3">
          <div className="icon-22 me-2 ">
            <img src={`/cdn/assets/icons/dashboard/integrations_icon.svg`} alt="" />
          </div>
          <div>
            <div>
              <div className="fs-18 fw-600 fc-blue">Business Integration</div>
            </div>
            <div className="fs-12 fw-400 fc-black ">
              Providing data through Business Integrations usually enables Higher credibility &  Guarantee Limits.
            </div>
          </div>
        </div>
        <div>
          <div className="profile-block">
            <div className="row">
              {getBusinessWidgetInfoUI()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessInsights;